/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import city from '../assets/img/brokerlogoclient/HYCM logo.png'
import { TiTick } from "react-icons/ti";
import { LuExternalLink } from "react-icons/lu";

const Hycm = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
          },[])
    const minRating = 0.1;
    const maxRating = 5.0;
    return (
        <>
            <Header />
            <div className="container py-4 px-2 " style={{overflowX:"hidden"}}>
                <div className='col-lg-10 col-md-9 offset-md-1'>
                    <h3 className='pt-4 pb-4  broker-name'>HYCM Review</h3>
                    <div className="row  pt-5 pb-5 text-center">
                        <div

                            className="col-lg-6 col-md-8 col-sm-12 offset-md-3  pricing-block style-two animated fadeInUp "
                            data-wow-delay="200ms"
                            data-wow-duration="1200ms"
                        >
                            <div
                                className="inner-box1 py-2 px-4 "
                                
                            >

                                <h4  className='mt-2'>
                                    <a href='' target="_blank">
                                        <img
                                            src={city}
                                            width={"200"}
                                            height={"200"}
                                            alt="Picture not Found"
                                            srcset=""
                                            className="mb-2 rounded-2"
                                            style={{width:"80%"}}
                                        />
                                    </a>
                                </h4>
                                <div className="d-flex py-1  justify-content-center  align-items-center  flex-reverse ">

                                    <div className=" d-flex justify-content-center">
                                        <div class="ratings">
                                            <div class="empty-stars"></div>
                                            <div
                                                className="full-stars"
                                                style={{
                                                    width: `${((5 - minRating) /
                                                        (maxRating - minRating)) *
                                                        100
                                                        }%`,
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div
                                        className={`py-2`}>
                                        <div className="grid grid-cols-12 text-sm">
                                            <div className="text-left lh-lg">
                                                <p>
                                                    <TiTick />
                                                    <strong> Min. Deposit: </strong> $100
                                                </p>

                                                {/* <p className='fs-6'> <TiTick /><strong>Max. Leverage: </strong>30:1</p>
                                                <p className='fs-6'> <TiTick /><strong>Headquarter: </strong> London, UK</p> */}
                                                <p className='fs-6'> <TiTick /><strong>Products (CFD):</strong>    Forex, CFD, Crypto
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="py-1  my-4 btn-style-two1">
                                    <a href='' target="_blank" className=" w-100 text-align-center text-light">

                                        <span className="">VISIT HYCM {' '}</span>
                                        <LuExternalLink color='#fff' size='25' style={{ paddingBottom: '5px' }} />
                                    </a>
                                </div>

                                <div className="hint">

                                    <p className="tradepara1 py-2">
                                    72% of retail CFD accounts lose money
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="row lg-pt-5 lg-px-5">

                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>HYCM is a global forex and CFD broker regulated by many financial authorities globally, including the top-tier FCA. HYCM has low forex and CFD trading fees. It offers a wide selection of quality educational tools. The account opening is easy and fully digital. On the flip side, HYCM’s product portfolio is limited to forex and CFDs. Popular asset classes, such as real stocks, are missing. The broker uses MetaTrader trading platforms that have a slightly outdated design. Additionally, HYCM charges a $10 inactivity fee per month after 90 days of inactivity.


                          </p>
                        </div>


                        <div className="col-lg-12 py-5">
                            <div className='row'>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <h5 className='pb-1 font-bold'>Pros</h5>
                                    <ul className='lh-lg' style={{ listStyle: "none" }}>
                                        <li>✅ Low forex and CFD fees


                                        </li>
                                        <li>✅ Fast and smooth account opening





                                        </li>
                                        <li>✅ Great educational content


                                        </li>
                                    </ul>


                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <h5 className='pb-1 font-bold'>Cons</h5>
                                    <ul className='lh-lg' style={{ listStyle: "none" }}>
                                        <li>❌Product portfolio limited to forex and CFDs





                                        </li>
                                        <li>❌Inactivity fee


                                        </li>
                                        {/* <li>❌ Non-user-friendly desktop platform


                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12 row'>
                          <h2>HYCM main highlights
</h2>
                        <div className='col-lg-6 col-sm-12'>
                       <p className="mt-3"> Country of regulation	</p>
                       <p className="mt-3">Trading fees class	</p>
                       <p className="mt-3">Inactivity fee charged	</p>
                       <p className="mt-3">Withdrawal fee amount	</p>
                       <p className="mt-3">Minimum deposit	</p>
                       <p className="mt-3">Time to open an account	</p>
                       <p className="mt-3">Deposit with bank card	</p>
                       <p className="mt-3">With electronic wallet	</p>
                       <p className="mt-3">Number of base currencies	</p>
                       <p className="mt-3">Demo account provided	</p>
                       <p className="mt-3">Products offered	</p>
                        </div>
                        <div className='col-lg-6 col-sm-12'>
                        <p className="mt-3">UK, Cyprus, Dubai, Cayman Islands	</p>
                       <p className="mt-3">Low	</p>
                       <p className="mt-3">Yes	</p>
                       <p className="mt-3">$0	</p>
                       <p className="mt-3">$100	</p>
                       <p className="mt-3">1 day	</p>
                       <p className="mt-3">Available
	</p>
                       <p className="mt-3">Available
	</p>
                       <p className="mt-3">6	</p>
                       <p className="mt-3">Yes	</p>
                       <p className="mt-3">Forex, CFD, Crypto
	</p>
                        </div>
                        </div>
                        <h4 className="wp-block-heading mb-5 mt-5">
              HYCM Fees, Commissions, Margin Rates and Account Minimums
            </h4>
            <p>
              HYCM’s primary trading products are forex currencies, gold and
              oil, which means that commission works a bit differently than most
              brokers that trade primarily in stocks. Instead of charging a
              flat-rate fee, forex brokers typically charge a spread between the
              bid price and the ask price of a currency.
            </p>
            <p>
              When you invest in the forex market, you’ll typically see 2 prices
              listed: a bid price and an “ask” price. The bid price is the
              highest price that a buyer will pay for any unit of currency,
              while the ask price is the lowest price a currency holder will
              accept for a unit of the currency being bought. The difference
              between these prices is the spread, which goes directly to your
              broker in exchange for servicing your trade.
            </p>
            <p>
              HYCM offers 3 levels of spreads. The right account for you will
              vary depending on when you trade, your base currency and whether
              or not you use an electronic advisor (EA) to assist your trades.
            </p>
            <ul>
              <li>
                <strong>Fixed spreads:</strong>&nbsp;Fix spread accounts have a
                set spread range and no commissions. Fixed spreads are set by
                the broker and are unlikely to change as market conditions
                fluctuate. Spreads on fixed accounts start from 1.5 pips.
              </li>
              <li>
                <strong>Variable spreads:</strong>&nbsp;Variable spreads have a
                varying spread range and no commissions. Spreads on variable
                accounts begin at 1.2 pips but may change more frequently.
              </li>
              <li>
                <strong>Raw spreads:&nbsp;</strong>Raw spread accounts pay the
                pure difference between the bid and the ask price plus $4 per
                round. Spreads on raw accounts start from 0.1 pips but may
                change very quickly throughout the day.
              </li>
            </ul>
            <p>
              HYCM also allows you to use leverage to trade. Leverage is a type
              of margin trading that allows you to compound your buying power in
              order to take more profits from your trades. Traders can access up
              to 500:1 leverage (not available for the EU or UK) through HYCM
              depending on where you live and which currency pair you’re
              trading.
            </p>
            <p>
              Account minimums with HYCM may vary depending on your base
              currency and the type of account you open. You should have at
              least $100 to $200 ready before you open an account.&nbsp;&nbsp;
            </p>
            <div
              style={{ height: 10 }}
              aria-hidden="true"
              className="wp-block-spacer"
            />
            <h4 className="wp-block-heading">HYCM Offerings</h4>
            <p>
              HYCM offers 5 products: stocks, indices, forex, commodities and
              cryptocurrencies. Note that your access to each product may vary
              depending on where you live.&nbsp;
            </p>
            <h5
              className="wp-block-heading"
              id="h-y-c-m-stocks-and-index-fundsnbsp"
            >
              HYCM Stocks and Index Funds&nbsp;
            </h5>
            <p>
              HYCM currently allows traders to buy and sell individual shares of
              60 of the world’s leading companies, including Coca Cola, Tesla,
              Amazon.com, Alibaba and more. You can also invest in 28 indexes.
              Leverage trading of up to 200:1 is available for index trading.
              Margin trading for stock purchases is available with rates
              beginning at 5%.&nbsp;
            </p>
            <h5 className="wp-block-heading" id="h-y-c-m-forex">
              HYCM Forex
            </h5>
            <p>
              HYCM’s primary product is forex currency trading. Choose from 69
              individual currency pairs and take advantage of up to 200:1
              leverage on many pairs. Margin rates begin at 0.50%, and you can
              choose to trade in 1 of 5 base currencies, no matter where you
              live and which currency your&nbsp;bank account&nbsp;uses. HYCM
              offers a variety of account types that allow you to customize your
              fee schedule to fit your unique trading style. Special swap-free
              accounts are available for Islamic traders who need an account
              that doesn’t accrue interest.&nbsp;&nbsp;
            </p>
            <h5 className="wp-block-heading" id="h-y-c-m-cryptocurrencies">
              HYCM Cryptocurrencies
            </h5>
            <p>
              HYCM offers traders access to 5 of the world’s most popular
              cryptocurrencies, and you can convert cryptocurrencies directly to
              fiat currency through HYCM’s platform. HYCM also offers leverage
              of up to 20:1 when trading cryptocurrencies.&nbsp;Please note that
              access to cryptocurrencies may vary depending on your location.
            </p>
            <h5 className="wp-block-heading" id="h-y-c-m-commodities">
              HYCM Commodities
            </h5>
            <p>
              Invest in precious metals and soft commodities like sugar and
              cotton using HYCM’s contract for difference (CFD) system. This
              system allows you to invest in these commodities and take
              advantage of price movements without actually owning the
              underlying commodity.&nbsp;&nbsp;
            </p>
            <p>
              <strong>Disclaimer:</strong>&nbsp;(CFDs are complex instruments
              and come with a high risk of losing money rapidly due to
              leverage.&nbsp;
              <strong>
                73.9% of retail investor accounts lose money when trading CFDs
                with this provider.
              </strong>
              &nbsp;You should consider whether you understand how CFDs work and
              whether you can afford to take the high risk of losing your money)
            </p>
            <h4 className="wp-block-heading">Education</h4>
            <p>
              HYCM offers a number of unique educational tools and calculators
              you can use to enhance your trading. Let’s take a look at a few of
              our favorite features below.
            </p>
            <ul>
              <li>
                <strong>Economic calendar:</strong>&nbsp;If you’re a forex
                trader who primarily trades using a fundamental analysis-based
                strategy, you know having a finger on the pulse of international
                events is crucial. HYCM offers 1 of the most comprehensive
                international calendars we’ve seen, allowing you to sort by
                daily, weekly or monthly upcoming events. Each event also
                includes an estimated level of volatility for the base currency
                connected to the event, which allows you to plan your trades
                ahead of time. You can also filter news by country, date, the
                authority releasing the report and more.
              </li>
              <li>
                <strong>Forex education center:</strong>&nbsp;If you’re a new
                trader, you’ll love HYCM’s forex education center, which
                includes a variety of free ebooks, videos and workshops you can
                use to enhance your trading. Some popular video lessons include
                “Trading Psychology,” “Types of Orders,” “Basic Technical
                Analysis” and more. There’s even a full course of videos
                explaining how to get started with the MetaTrader platform of
                your choice and how to use MetaTrader’s most popular features.
                All video lessons and tutorials are completely free, and you
                don’t need to already have an HYCM account to use them.
              </li>
              <li>
                <strong>Webinars and online workshops:&nbsp;</strong>If you
                prefer to do your learning in a live environment, consider
                signing up for 1 of HYCM’s online webinars. Webinars are hosted
                on a weekly basis and include topics like the week ahead,
                planning your forex strategy in advance, live analyses of what
                is moving the value of the world’s currencies and more. Past
                webinars are also available on HYCM’s website, so you can watch
                them later if you aren’t able to make the session while it’s
                airing.&nbsp;
              </li>
            </ul>




                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Hycm