/* eslint-disable */
import React, { useEffect, useState } from "react";
import { LuExternalLink } from "react-icons/lu";
import plus500 from "./../assets/img/Plus500.svg";
import IG from "./../assets/img/IG.png";
import Pepperstone from "./../assets/img/brokerlogoclient/Pepperstone logo.png";
import City from "./../assets/img/City Index.png";
import { Link } from "react-router-dom";
import Instance from "../utils/Interceptor";

function Centerone() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchBrokerData = async () => {
    // setLoading(true);
    try {
      setLoading(true);
      const response = await Instance.get("api/australia-broker-point");
      // const Fee = response.data.data.slice(0, 41);
      setData(response?.data?.data);
      if (response.data.status === true) {
        setLoading(false);
        window.scrollTo(0, 0);
      } else {
        setLoading(true);
      }
    } catch (error) {
      console.error("Error fetching broker data:", error);
      setLoading(true);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchBrokerData();
    // scrollTo(0,0)
  }, []);
  return (
    <>
      {loading ? (
        <div className="Loader"></div>
      ) : (
        <div className="">
          <h1 className=" fw-bold pt-2 py-3 text-center">
            Best Forex Brokers Australia
          </h1>
          <div className="container" style={{paddingLeft:"100px"}}>
          <p className="text-justify container">
            To find the best forex brokers in Australia, we created a list of
            all ASIC authorised brokers, then ranked brokers by their Overall
            ranking. Here is our list of the top Australian forex brokers.
          </p>
          </div>
          {data?.map((e, i) => {
            return (
              <div className="d-flex flex-col justify-content-center container">
                <div className="d-flex align-items-center justify-content-between calculate-broker  my-3 row col-lg-12 ">
                  <div className="col-lg-2 col-md-12 col-sm-12">
                    <div className="broker-profile d-flex align-items-start justify-content-start p-5 resFee">
                      <img
                        className=""
                        src={e?.image}
                        alt=""
                        width="77"
                        height="77"
                        style={{ borderRadius: "10px", cursor: "pointer",boxShadow:"5px 5px 10px rgba(0,0,0,.23)" }}
                      />
                      <div className="px-3 m">
                        <h6 className="broker-profile-name-1 center fw-bold fs-6">
                          {""}
                        </h6>
                        <h6 className="broker-profile-name-2 center fw-bold fs-6 para-color type">
                          {""}
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-4 col-sm-12 ">
                    <div className="broker-spread text-start new">
                      <p className="para-color p-0 m-2 bottum">General Info</p>
                      {e?.levels.slice(0, 4)?.map((e, i) => {
                        return <li>{e?.point}</li>;
                      })}
                      {/* <li>Min Deposit: $100</li>
              <li>Max Leverage: 30:1</li>
              <li>Platforms: Plus500 app, Webtrader</li>
              <li>Inactivity Fee: Yes</li> */}
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-4 col-sm-12">
                    <div className="broker-comission text-start new1">
                      <p className="para-color p-0 m-2 bottum1">
                        Average Spread
                      </p>
                      {e?.levels.slice(4, 8)?.map((e, i) => {
                        return <li>{e?.point}</li>;
                      })}
                      {/* <li>EURUSD: 1.3</li>
              <li>USDJPY: 1.7</li>
              <li>GBPUSD: 2.0</li>
              <li>GOLD: 0.3</li> */}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-center">
                    <div className="broker-percentage text-center p-2">
                      <div className="link-btn pb-3">
                        <div className="theme-btn btn-style-two">
                          <span
                            className="btn-title"
                            onClick={() => window.open(e?.url, "_blank")}
                            style={{
                              padding: "3px 7px",
                              cursor: "pointer",
                            }}
                          >
                            VISIT BROKER{" "}
                            <LuExternalLink
                              size="22"
                              style={{ paddingBottom: "5px" }}
                            />{" "}
                          </span>
                        </div>
                      </div>
                      <div className="hint ">
                        <Link to={e?.path} className="forhover ">
                          {" "}
                          <span style={{ borderBottom: "1px solid #0a2cd4" }}>
                            Read review
                          </span>{" "}
                        </Link>
                        {/* <p className="tradepara py-1 mt-2 mb-0">
                  {"79"}% of retail CFD accounts lose money
                </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
                   <div className="container" style={{paddingLeft:"100px"}}>

            <h1 className=" fw-bold pt-2 py-3 text-center">
              What protection does ASIC provide forex traders?
            </h1>
            <p className="text-justify container">
              In addition to the many protections afforded by a trusted
              regulatory jurisdiction such as efficient compliance and
              enforcement of regulations, forex traders in Australia may also
              benefit from the required Professional Indemnity (PI) insurance
              policy that ASIC-regulated brokers are required to hold.
            </p>
            <p className="text-justify container">
              A forex broker that holds an{" "}
              <a href="https://asic.gov.au/for-finance-professionals/afs-licensees/">
                {" "}
                Australian Financial Services (AFS) license{" "}
              </a>{" "}
              with ASIC must hold adequate insurance. Adequacy levels are
              contingent on the size of the broker (i.e. larger brokers must
              have larger policies, whereas smaller firms will hold coverage
              sufficient for their client base size).
            </p>
            <p className="text-justify container">
              <b>Important note:</b> Indemnity insurance isn’t designed to
              protect you directly – it protects your broker in the
              extraordinary case of an insolvency event. It does not guarantee
              that you will be paid should your broker become insolvent.
              Nonetheless, this insurance policy is a{" "}
              <a href="https://download.asic.gov.au/media/niddj53n/rg126-published-06-july-2022.pdf">
                {" "}
                requirement under s912B of the Corporations Act of 2001,{" "}
              </a>{" "}
              which mandates that AFS holders have arrangements in place to
              compensate clients in the event of Chapter 7 of the corporations
              act (and under{" "}
              <a href="https://treasury.gov.au/review/review-of-compensation-for-loss-in-the-financial-services-sector/chapter-6-financial-services-licensees">
                {" "}
                Chapter 6 applicable to AFS holders{" "}
              </a>{" "}
              as per the Treasury Department). This can help reduce the damage
              caused in cases where a broker goes out of business.
            </p>
            <p className="text-justify container">
              In the event of the insolvency of a forex broker: ASIC will step
              in to ensure that external administrators comply with the law,
              supervise any registered liquidators that may be assigned from
              secured creditors, and implement insolvency reforms and publish
              updates about the case on its website. Because forex traders must
              rely on the broker’s creditworthiness to reduce counterparty risk,
              it is essential to only deal with highly-trusted forex brokers.
            </p>
            <h1 className=" fw-bold pt-2 py-3 text-center">
              Is forex trading legal in Australia?
            </h1>
            <p className="text-justify container">
              Yes, forex trading is legal in Australia and is regulated by the{" "}
              <a href="https://connectonline.asic.gov.au/RegistrySearch/faces/landing/SearchRegisters.jspx?_adf.ctrl-state=ibnz0pm5z_4">
                {" "}
                Australian Securities and Investment Commission (ASIC),{" "}
              </a>{" "}
              which supervises and regulates financial markets and ensures that
              brokers are complying with the <a> Corporations Act of 2001. </a>
            </p>
            <h1 className=" fw-bold pt-2 py-3 text-center">
              Australia’s financial markets
            </h1>
            <p className="text-justify container">
              Forex traders will recognize the Australian dollar (AUD) as
              Australia’s national currency (known in the forex world as “the
              Aussie”), with the AUD/USD being one of the more popular currency
              pairs in the global forex market
            </p>
            <p className="text-justify container">
              Forex markets and Australian forex brokers are affected by the
              monetary policy as regulated by the{" "}
              <a href="https://www.rba.gov.au/">
                {" "}
                Reserve Bank of Australia (RBA),
              </a>{" "}
              which issues and manages the Australian dollar.
            </p>
            <p className="text-justify container">
              Aside from ensuring the stability of Australia’s national
              currency, the RBA oversees risk in the financial system and aims
              to promote Australia’s overall economic prosperity.
            </p>
            <p className="text-justify container">
              The primary stock market in Australia is the{" "}
              <a href="https://www2.asx.com.au/about">
                {" "}
                Australian Securities Exchange (ASX),
              </a>{" "}
              located in Sydney. The ASX’s markets offer a wide range of asset
              classes that include equities, futures, derivatives,
              exchange-traded funds (ETFs), exchange-traded products (ETPs), and
              commodities.
            </p>
            <h1 className=" fw-bold pt-2 py-3 text-center">
              Do I have to pay taxes on forex trading in Australia?
            </h1>
            <p className="text-justify container">
              Yes, Australian citizens and residents must report their income
              from trading in Australia – including any profits earned from
              forex trading. Likewise, any losses from trading forex can be
              filed depending on how your tax professional prepares your tax
              return filings, and whether you are trading as an individual or
              under a business name.
            </p>
            <h1 className=" fw-bold pt-2 py-3 text-center">
              Can I trade forex with $100?
            </h1>
            <p className="text-justify container">
              Yes, you can trade forex with just $100. However, your ability to
              do so will depend on two factors: minimum deposit requirements and
              contract size availability. To start trading forex with just $100,
              you’ll need to find a broker that allows you to open a live
              account with a $100 minimum deposit, and it’ll also need to let
              you trade micro lot contracts (just 1,000 units of currency).
            </p>
            <h1 className=" fw-bold pt-2 py-3 text-center">
              How do you get started trading forex in Australia?
            </h1>
            <p className="text-justify container">
              If you want to start trading forex in Australia, it’s essential to
              start your forex journey by choosing a forex broker that is
              well-regulated and properly licensed in Australia by the{" "}
              <a href="https://connectonline.asic.gov.au/RegistrySearch/faces/landing/SearchRegisters.jspx?_adf.ctrl-state=ibnz0pm5z_4">
                {" "}
                Australian Securities and Investment Commission (ASIC).
              </a>{" "}
              We’ve created this step-by-step guide to help you get started with
              forex trading in Australia:
            </p>
            <div className="container">
              <ol>
                <li>
                  {" "}
                  <b>Choose a reputable broker </b>that is properly licensed and
                  regulated by ASIC by verifying their credentials on the ASIC
                  website.
                </li>
                <li>
                  {" "}
                  <b>
                    Familiarize yourself with your broker’s trading software
                  </b>{" "}
                  Check out all available mobile and web apps and explore all
                  your broker’s trading platforms (I always recommend starting
                  out with your broker’s free demo account).
                </li>
                <li>
                  {" "}
                  <b>Fund your trading account </b>using the available deposit
                  methods that are most suitable for you. PayPal has become a
                  popular way to fund forex trading accounts, due to its
                  extensive international presence and wide range of supported
                  currencies.
                </li>
                <li>
                  {" "}
                  <b>Put together a trading plan. </b>Even the best traders can
                  lose money, but the key to long-term success lies in sticking
                  to a trading plan that keeps your average losses low (relative
                  to your average profits).
                </li>
                <li>
                  {" "}
                  <b>Enter the forex market. </b> Choose your desired trade
                  size, and open a long position by clicking buy on a given
                  currency, or open a short position by clicking sell. You are
                  now a forex trader!
                </li>
              </ol>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Centerone;
