/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
// import plus500logo from '../assets/img/Plus500logo-review.svg'
// import { TiTick } from "react-icons/ti";
// import { LuExternalLink } from "react-icons/lu";

const Whatispip = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    // const minRating = 0.1;
    // const maxRating = 5.0;
    return (
        <>
            <Header />
            <div className="container py-4 px-5">
                <div className='col-lg-10 col-md-9 offset-md-1'>
                    Forex traders need to be able to monitor tiny movements in the exchange rates of international currencies. When I say tiny, I mean movements as small as hundredths (or even thousandths) of a cent. You may have read or heard about pips in the forex market; pips are just a way for forex traders to talk about (and measure) those incredibly small price shifts in the forex market.
                    <br />
                    <br />
                    It’s essential that forex traders understand what pips are and how they work. Even a single pip can convey tremendous value, depending on the size of a given trade. Let’s dive into the ins and outs of pips in the forex market.

                    <h3 className='pt-4 pb-4  '>What is a pip?
                    </h3>




                    <div className="row lg-pt-5 lg-px-5">
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>A pip is an important unit of measurement used for tracking minuscule changes in the price of a currency. Pips are small enough to measure fractions of a cent; they provide forex market participants and forex traders with an incredible degree of price precision when quoting exchange rates or valuing currencies. One pip is the equivalent of one one-hundredth of a percent (1/100th of 1%).</p>
                            <h3 className="pt-4 pb-4">
                                How are pips used?
                            </h3>
                            <p className='lh-base fs-6'>The global forex market operates 24 hours a day and currency exchange rates are in a continual state of flux. Pips enable forex traders to make currency trades based on price movements as small as 1/10,000th of a currency unit (that’s 1/100th of one cent in U.S. dollars). That level of granularity in pricing data creates an almost limitless number of trading opportunities for forex traders; because forex prices are quoted in pips, prices can change hundreds of times per minute.</p>
                        </div>
                        <h3 className='pt-4 pb-4  '>How to read pips

                        </h3>
                        <div className="col-lg-12">

                            <p className='lh-base fs-6'>Typically, a pip represents 1/10,000th of a currency unit. This is the case for currencies that are denominated in pennies or cents, such as the dollar or the euro. In some cases, currencies are already denominated in their smallest unit. The Japanese yen, for example, does not have smaller denominations than “one yen”; these are also known as zero-decimal currencies. For the Japanese yen, a pip would be one 100th of a unit of currency.
                            </p>
                        </div>
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>If you want to learn how to read pips, it’s helpful to remember that – for most currencies – they are represented in the fourth decimal place of most quoted exchange rates (1/10,000th = 0.0001).

                                .</p>

                        </div>
                        <h3 className='pt-4 pb-4  '>How to read pips within a currency exchange rate


                        </h3>
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>Say you’re using your broker’s forex trading platform and you see that the exchange rate for the EUR/USD is 1.0794. At that exchange rate, you’ll pay $1.0794 U.S. dollars for each euro. Later in the day, you see that the exchange rate went from 1.0794 to 1.0795.
                                .</p>

                            <p className='lh-base fs-6'>This is an exchange rate increase of one pip.</p>
                            <p className='lh-base fs-6'>If the exchange rate went from 1.0794 to 1.0799, that would be an increase of five pips.</p>
                            <p className='lh-base fs-6'>A forex trader reading the above exchange rate for the EUR/USD would see that the price for each euro in U.S. dollars would be $1.12345.

                            </p>
                            <p className='lh-base fs-6'>I mentioned above that this works differently for zero-decimal currencies like the Japanese yen. For a currency pair such as the USD/JPY, the exchange rate would look more like this: 123.45.</p>
                            <p className='lh-base fs-6'><b>Remember:</b> There are fewer decimal places for the USD/JPY pair because the yen is already denominated in its smallest unit. One pip for the Japanese yen is 1/100th of a yen, so if the exchange rate is 123.45, the “5” represents five pips.</p>

                        </div>
                        <h3 className='pt-4 pb-4  '>How to calculate the value of a pip



                        </h3>

                        <div className="col-lg-12">
                            Let’s explore the formula used to calculate pip values.
                            <br />
                            <br />
                            It’s important to remember that the value of a pip scales with the size of each forex trade. The larger the trade value and trade size, the larger the value of each pip (and vice versa).
                            <br />
                            <br />
                            When you place an order to buy or sell a forex pair, the value of your trade size will dictate the value of each pip (your margin is also calculated based on the value of your trade size).
                            <br /><br />
                            For example, if you sell 100,000 EUR/USD at an exchange rate of 1.1234, that trade is worth $112,340.00 and each pip will be worth roughly $11.23.
                            <br />
                            <br />
                            <b>

                                Formula:
                            </b>
                            To determine the value of a pip for a given currency pair, take the pip format for the pair in question (0.0001, in the example above) and multiply it by the current exchange rate (our example exchange rate above was 1.1234). Then, multiply that figure by the trade size. This will give you the value of one pip for that specific trade.
                            <br />
                            <br />
                            Let’s break that down:
                            <br />
                            <br />
                            The pip format is 0.0001, and our example exchange rate is 1.1234.
                            <br />
                            <br />
                            0.0001 x 1.1234 = 0.00011234 pips.
                            <br />
                            <br />
                            Our trade size will be 100,000 units.
                            <br />
                            <br />
                            0.00011234 x 100,000 = 11.234.
                            <br />
                            <br />
                            For this trade, one pip will be worth roughly $11.23.
                            <br />
                            <br />
                            <b>  A general rule of thumb:</b> As some currency pairs result in a pip value that is less than $1, many traders will simply approximate that a single pip is roughly equivalent to about $1 per 10,000 units of currency. This scales linearly as follows:
                            <br />
                            <ul>
                                <li><b>Micro trade size of 1,000 units</b>: pip value roughly equivalent to 10 cents.</li>
                                <br />
                                <li><b>Mini trade size of 10,000 units</b>: pip value roughly equivalent to 1 dollar.</li>
                                <br />
                                <li><b>Standard trade size of 100,000 units</b>: pip value roughly equivalent to 10 dollars.</li>
                            </ul>

                        </div>
                        <h3 className='pt-4 pb-4  '>How do pips relate to the spread?



                        </h3>
                        <div className="col-lg-12">
                        The spread is a measurement in pips of the difference (or distance) between the bid price and the ask price.
                            <br />
                            <br />
                            When looking at a currency pair in your broker’s trading platform, you’ll see exchange rates for both the bid price and the ask price. The bid price represents the exchange rate for selling, and the ask price is the exchange rate for buying.
                            <br />
                            <br />
                            Calculating the spread is really simple. All you need to do is subtract the bid price from the ask price.
                            <br />
                            <br />
                            Let’s look at a hypothetical example.
                            <br />
                            <br />
                          <b>  Bid price exchange rate for the EUR/USD:</b> 1.0877
                          <br /><br />
                          <b>  Ask price exchange rate for the EUR/USD:</b> 1.0879
                          <br />
                          <br />
                          <b>  Calculating the spread:</b> 1.0879 – 1.0877 = 0.0002 (2 pips).


                        </div>
                     

                    


                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Whatispip