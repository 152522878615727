/* eslint-disable no-sparse-arrays */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
// import BrokerFeeSection from "../components/BrokerFeeSection";
// import { Oval } from "react-loader-spinner";
// import { LoaderContext } from "../Context/Loader";
import Select from "react-select";
// import securites from "./../assets/img/brokerlogoclient/ACY Securities logo.jpg";
// import axi from "./../assets/img/brokerlogoclient/Axi logo.png";
// import avatrade from "./../assets/img/brokerlogoclient/AvaTrade logo.jpg";
// import cmc from './../assets/img/CMC Markets Logo.png'
// import axi from "./../assets/img/brokerlogoclient/Axi logo.png";
// // import captial from "./../assets/img/brokerlogoclient/Capital.com logo2.png";
// import cityindex from "./../assets/img/brokerlogoclient/City Index logo.png";
// import avatrade from "./../assets/img/brokerlogoclient/AvaTrade logo.jpg";
// import captial from "./../assets/img/brokerlogoclient/Capital.com logo.png";
// import admiralmarket from "./../assets/img/brokerlogoclient/Admirals Markets logo.png";
// import blackbull from "./../assets/img/brokerlogoclient/Blackbull logo.png";
// import Eightcap from "./../assets/img/brokerlogoclient/EightCap logo.png";
// import etoro from "./../assets/img/brokerlogoclient/eToro logo.png";
// import forexcom from "./../assets/img/brokerlogoclient/forex.com logo.png";
// import fpmarket from "./../assets/img/brokerlogoclient/FP Markets logo.png";
// import fusionmarket from "./../assets/img/brokerlogoclient/Fusion Markets logo.jpg";
// import fxcmlogo from "./../assets/img/brokerlogoclient/FXCM logo.png";
// import fxpro from "./../assets/img/brokerlogoclient/Fxpro logo.svg";
// import fxtm from "./../assets/img/brokerlogoclient/FXTM logo2.png";
// import globalprime from "./../assets/img/brokerlogoclient/Global Prime Logo.png";
// import gomarket from "./../assets/img/brokerlogoclient/Go Markets logo.jpeg";
// import HFmarket from "./../assets/img/brokerlogoclient/HF Markets.png";
// import Hycmlogo from "./../assets/img/brokerlogoclient/HYCM logo.png";
// import ICmarket from "./../assets/img/brokerlogoclient/IC Markets logo2.png";
// import IG from "./../assets/img/brokerlogoclient/IG Logo.jpg";
// import marketcom from "./../assets/img/brokerlogoclient/Markets.com logo.jpeg";
// import monetamarket from "./../assets/img/brokerlogoclient/Moneta Markets logo2.png";
// import multibank from "./../assets/img/brokerlogoclient/MultiBank logo.svg";
// import oandalogo from "./../assets/img/brokerlogoclient/Oanda Logo.jpg";
// import peperstone from "./../assets/img/brokerlogoclient/Pepperstone logo.png";
// import swisslogo from "./../assets/img/brokerlogoclient/Swissquote logo.png";
// import tickmil from "./../assets/img/brokerlogoclient/Tickmill logo.png";
// import tmgm from "./../assets/img/brokerlogoclient/TMGM logo.jpeg";
// import xtm from "./../assets/img/brokerlogoclient/XM logo.jpg";
// import activetrade from "./../assets/img/main-icon.jpg";
// import XTB from "./../assets/img/Xtb-logo.png";
// import tradenation from "./../assets/img/brokerlogoclient/Trade Nation logo.png";
// import vintage from "./../assets/img/brokerlogoclient/Vantage logo.png";
// import plus500 from "./../assets/img/brokerlogoclient/Plus500 logo2.png";
// import BrokerFee from "../components/BrokerFee";
import { LuExternalLink } from "react-icons/lu";
import Instance from "../utils/Interceptor";
// import BrokerFee from "../components/BrokerFee";

const FeeCalculator = () => {
  const countries = [
    { value: "AllCountry", label: "All Country" },
    { value: "Afghanistan", label: "Afghanistan" },
    { value: "Albania", label: "Albania" },
    { value: "Algeria", label: "Algeria" },
    { value: "American Samoa", label: "American Samoa" },
    { value: "Andorra", label: "Andorra" },
    { value: "Angola", label: "Angola" },
    { value: "Anguilla", label: "Anguilla" },
    { value: "Antigua & Barbuda", label: "Antigua & Barbuda" },
    { value: "Argentina", label: "Argentina" },
    { value: "Armenia", label: "Armenia" },
    { value: "Aruba", label: "Aruba" },
    { value: "Australia", label: "Australia" },
    { value: "Austria", label: "Austria" },
    { value: "Bahrain", label: "Bahrain" },
    { value: "Bangladesh", label: "Bangladesh" },
    { value: "Belgium", label: "Belgium" },
    { value: "Brazil", label: "Brazil" },
    { value: "China", label: "China" },
    { value: "Cyprus", label: "Cyprus" },
    { value: "Denmark", label: "Denmark" },
    { value: "Egypt", label: "Egypt" },
    { value: "Finland", label: "Finland" },
    { value: "France", label: "France" },
    { value: "Germany", label: "Germany" },
    { value: "Hong Kong", label: "Hong Kong" },
    { value: "Ireland", label: "Ireland" },
    { value: "Israel", label: "Israel" },
    { value: "Italy", label: "Italy" },
    { value: "Japan", label: "Japan" },
    { value: "Kuwait", label: "Kuwait" },
    { value: "Luxembourg", label: "Luxembourg" },
    { value: "Netherlands", label: "Netherlands" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "Oman", label: "Oman" },
    { value: "Poland", label: "Poland" },
    { value: "Qatar", label: "Qatar" },
    { value: "Saudi Arabia", label: "Saudi Arabia" },
    { value: "Singapore", label: "Singapore" },
    { value: "South Africa", label: "South Africa" },
    { value: "Spain", label: "Spain" },
    { value: "Sweden", label: "Sweden" },
    { value: "Switzerland", label: "Switzerland" },
    { value: "United Arab Emirates", label: "United Arab Emirates" },
    { value: "United Kingdom", label: "United Kingdom" },
  ];

  const [show, setShow] = useState(false);
  const [feeData, setfeeData] = useState([]);

  // const {data,setData} = useContext(LoaderContext)
  // console.log("data--->", data);

  const [SelectCountry, setSelectedCountry] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [SelectCountry]);
  // const [, setCalculate] = useState(false);
  const [selectedBroker, setSelectedBroker] = useState([]);
  const [newfilterdata, setnewfilterdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCurrencyPair, setSelectedCurrencyPair] = useState("EURUSD");
  // const [Loader, setLoader] = useState(false);

  const countryCodes = {
    AF: "Afghanistan",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    BH: "Bahrain",
    BD: "Bangladesh",
    BE: "Belgium",
    BR: "Brazil",
    CN: "China",
    CY: "Cyprus",
    DK: "Denmark",
    EG: "Egypt",
    FI: "Finland",
    FR: "France",
    DE: "Germany",
    HK: "Hong Kong",
    IE: "Ireland",
    IL: "Israel",
    IT: "Italy",
    JP: "Japan",
    KW: "Kuwait",
    LU: "Luxembourg",
    NL: "Netherlands",
    NZ: "New Zealand",
    OM: "Oman",
    PL: "Poland",
    QA: "Qatar",
    SA: "Saudi Arabia",
    SG: "Singapore",
    ZA: "South Africa",
    ES: "Spain",
    SE: "Sweden",
    CH: "Switzerland",
    AE: "United Arab Emirates",
    UAE: "United Arab Emirates",
    GB: "United Kingdom",
    PK: "AllCountry",
    US: "AllCountry",
    RU: "AllCountry",
    TR: "AllCountry",
    IN: "AllCountry",
    CA: "AllCountry",
    MX: "AllCountry",
    NG: "AllCountry",
  };

  // useEffect(() => {
  //   const condition = userData && Object.values(countryCodes).includes(userData) ? userData : "AllCountry";
  //   setSelectedCountry(condition);
  //   // setSelectedCountry({ label: condition, value: condition });

  // }, [userData]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      // setLoading(true);
      try {
        const response = await Instance.get("api/country-code");
        const country = response.data.country;
        const fullCountry = countryCodes[country];
        const condition =
          fullCountry && Object.values(countryCodes).includes(fullCountry)
            ? fullCountry
            : "AllCountry";
        setSelectedCountry(condition);
        // setLoading(true);
      } catch (error) {
        // setLoading(true);

        console.error("Error fetching country code:", error);
      }
      // setLoading(true);
    };

    const fetchBrokerData = async () => {
      // setLoading(true);
      try {
        // setLoading(true);
        const response = await Instance.get("api/fee");
        const Fee = response.data.data.slice(0, 41);
        setfeeData(Fee);
        if (response.data.status === true) {
          setLoading(false);
        } else {
          setLoading(true);
        }
      } catch (error) {
        console.error("Error fetching broker data:", error);
        setLoading(true);
      }
      setLoading(false);
    };

    fetchData();
    fetchBrokerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log("SelectCountry---->", SelectCountry);

  const newdata = feeData.filter((broker) =>
    broker.country?.includes(SelectCountry)
  );
  // const CalculateBroker = () => {
  //   // setLoader(true);
  //   setTimeout(() => {
  //     setCalculate(true);
  //     // setLoader(false);
  //   }, 500);
  // };

  const options = useMemo(
    () =>
      feeData
        ?.filter((broker) => broker.country?.includes(SelectCountry))
        .map((e) => {
          return { label: `${e?.broker}`, value: e?.broker };
        }),
    [SelectCountry, feeData]
  );

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      width: "435px", // Set the default width to 400 pixels
      "@media (max-width: 600px)": {
        width: "300px",
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 999,
      width: "435px",
      "@media (max-width: 600px)": {
        width: "300px",
      },
    }),
  };
  const handleChange = (selectedOptions) => {
    setSelectedBroker(selectedOptions);
    let brokers = selectedOptions.map((e) => e.value);
    // setSelectedBrokers(selectedBrokers.filter((item)=>brokers.includes(item.broker)))
    let data = feeData.filter((item) => brokers.includes(item.broker));
    setnewfilterdata([...data]);
    console.log("brokers", brokers);
  };

  console.log(options);
  const handleCurrencyPairChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCurrencyPair(selectedValue);
    console.log(selectedValue);
  };
  const handleCountry = (event) => {
    setSelectedCountry(event.value);
  };

  return (
    <>
      <Header />
      {loading ? (
        <div className="Loader"></div>
      ) : (
        <div className="overflow-x-hidden">
          <section className="feeCalculator">
            <div className="container py-5 ">
              <div className="col-lg-10 col-md-9 offset-md-1">
                <div className="top-heading d-flex ">
                  {/* <div className="d-flex align-items-center">
                      <p className="feeCalculator-new px-2 mx-2 fw-bold">NEW</p>
                    </div> */}
                  <h1 className="feeCalculator-heading fw-bold pt-2 py-3">
                    Compare Broker Spreads
                  </h1>
                </div>

                {/* <div class="text-white lh-base">
                    <p className="p-0 m-0">Calculate your FX trading fees.</p>
                    <ul className="feeCalculator-list lh-lg">
                      <li>
                        Enter your currency pair, trade size, and select your country.
                      </li>
                      <li>
                        Get the brokers with the lowest fees for FX trading starting
                        with the cheapest.
                      </li>
                      <li>
                        The fee calculation will show both spreads and FX commissions.
                      </li>
                    </ul>
                  </div> */}
                <div className="formarea ">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 mt-3">
                      <p className="input-label fw-bold">Currency pair</p>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={selectedCurrencyPair || ""}
                        onChange={handleCurrencyPairChange}
                      >
                        <option value="EURUSD">EURUSD</option>
                        <option value="USDJPY">USDJPY</option>
                        <option value="GBPUSD">GBPUSD</option>
                        <option value="USDCAD">USDCAD</option>
                        <option value="AUDUSD">AUDUSD</option>
                        <option value="NZDUSD">NZDUSD</option>
                        <option value="EURJPY">EURJPY</option>
                        <option value="GBPJPY">GBPJPY</option>
                        <option value="USDCHF">USDCHF</option>
                        <option value="EURGBP">EURGBP</option>
                        <option value="NZDJPY">NZDJPY</option>
                        <option value="AUDJPY">AUDJPY</option>
                        <option value="GOLD">GOLD</option>
                      </select>{" "}
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-12 mt-3 ">
                      <div className="row whats">
                        <div className="col-lg-6 col-md-6 ">
                          <p className="input-label fw-bold">Select Country</p>
                          <Select
                            options={countries}
                            defaultValue={{
                              label: SelectCountry,
                              value: SelectCountry,
                            }}
                            styles={selectStyles}
                            onChange={handleCountry}
                          />{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 mt-3">
                      <p className="input-label fw-bold">Select Broker</p>
                      <Select
                        onChange={handleChange}
                        isMulti={true}
                        isSearchable={true}
                        value={selectedBroker}
                        styles={selectStyles}
                        options={options}
                      />{" "}
                    </div>
                  </div>
                  {/* <div className="row ">
                    <div className="col-md-6 mt-3">
                      <button
                        type="button"
                        class="btn btn-primary w-100 align-items-center justify-content-center text-uppercase"
                        onClick={() => {
                          CalculateBroker();
                        }}
                      >
                        {Loader ? (
                          <Oval
                            height={18}
                            width={18}
                            color="#fff"
                            text
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor="#fff"
                            strokeWidth={6}
                            strokeWidthSecondary={6}
                          />
                        ) : (
                          "Compare"
                        )}
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </section>

          <section className="BrokerFeeSection">
            <div className="container py-5 feecont">
              <div className="col-lg-10 col-md-9 offset-md-1">
                <div className="top-heading text-center">
                  <h3 className="fw-bold pt-2 py-3">
                    Average Spread For {selectedCurrencyPair}
                  </h3>

                  {loading ? (
                    <p className="fw-bold BrokerFeeSection-para">
                      (All fees are in USD)
                    </p>
                  ) : (
                    <p className="fw-bold BrokerFeeSection-para"></p>
                  )}
                </div>

                {newfilterdata?.length === 0
                  ? newdata?.map((e) => {
                      return (
                        <div className="d-flex align-items-center justify-content-between calculate-broker  my-3 row">
                          <div className="col-lg-3 col-md-12 col-sm-12">
                            <div className="broker-profile d-flex align-items-center justify-content-start p-2 resFee">
                              <img
                                className="shadow1"
                                src={e?.img}
                                // src={e?.image === 'securites' ? '' : e?.image === 'admiralmarket' ? admiralmarket : e?.image === 'avatrade' ? avatrade : e?.image === 'axi' ? axi : e?.image === 'blackbull' ? blackbull : e?.image === 'captial' ? captial : e?.image === 'cityindex' ? cityindex : e?.image === 'Eightcap' ? Eightcap : e?.image === 'etoro' ? etoro : e?.image === 'forexcom' ? forexcom : e?.image === 'fpmarket' ? fpmarket : e?.image === 'fusionmarket' ? fusionmarket : e?.image === 'fxcmlogo' ? fxcmlogo : e?.image === "fxpro" ? fxpro : e?.image === 'fxtm' ? fxtm : e?.image === 'globalprime' ? globalprime : e?.image === 'gomarket' ? gomarket : e?.image === 'HFmarket' ? HFmarket : e?.image === 'Hycmlogo' ? Hycmlogo : e?.image === 'ICmarket' ? ICmarket : e?.image === 'IG' ? IG : e?.image === 'marketcom' ? marketcom : e?.image === 'monetamarket' ? monetamarket : e?.image === 'multibank' ? multibank : e?.image === 'oandalogo' ? oandalogo : e?.image === "peperstone" ? peperstone : e?.image === 'swisslogo' ? swisslogo : e?.image === 'tickmil' ? tickmil : e?.image === 'tmgm' ? tmgm : e?.image === 'tradenation' ? tradenation : e?.image === 'vintage' ? vintage : e?.image === 'xtm' ? xtm : e?.image === "plus500" ? plus500 : e?.image === "activetrade" ? activetrade : e?.image === 'XTB' ? XTB : e?.image === 'CMCmarketlogo' ? '' : ''}
                                alt=""
                                onClick={() => window.open(e?.link, "_blank")}
                                width="60"
                                height="60"
                                style={{
                                  borderRadius: "10px",
                                  cursor: "pointer",
                                }}
                              />
                              <div className="px-3 m">
                                <h6 className="broker-profile-name-1 center fw-bold fs-6">
                                  {e?.broker}
                                </h6>
                                <h6 className="broker-profile-name-2 center fw-bold fs-6 para-color type">
                                  {e?.type}
                                </h6>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-2 col-md-4 col-sm-4">
                            <div className="broker-spread text-center new">
                              <p className="para-color p-0 m-2">Spread</p>
                              <h5 className="broker-profile-name-2 fw-bold  fs-4 cur">
                                {
                                  e?.[selectedCurrencyPair.toLowerCase()]?.[
                                    selectedCurrencyPair
                                  ]
                                }
                              </h5>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-4 col-sm-4 ">
                            <div className="broker-comission text-center new1">
                              <p className="para-color p-0 m-2">Commission</p>
                              <h5 className="broker-profile-name-2 fw-bold  fs-4 cur1">
                                $
                                {
                                  e?.[selectedCurrencyPair.toLowerCase()]
                                    ?.comission
                                }
                              </h5>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-12 col-sm-12">
                            <div className="broker-percentage text-center p-2">
                              <div className="link-btn pb-3">
                                <div className="theme-btn btn-style-two">
                                  <span
                                    className="btn-title"
                                    onClick={() =>
                                      window.open(e?.link, "_blank")
                                    }
                                    style={{
                                      padding: "3px 7px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    VISIT BROKER{" "}
                                    <LuExternalLink
                                      size="22"
                                      style={{ paddingBottom: "5px" }}
                                    />{" "}
                                  </span>
                                </div>
                              </div>
                              <p className="percentage-para p-0 m-2">
                                {e?.lose}% of retail CFD accounts lose money
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}
                {newfilterdata?.map((e) => {
                  return (
                    <div className="d-flex align-items-center justify-content-between calculate-broker  my-3 row">
                      <div className="col-lg-3 col-md-12 col-sm-12">
                        <div className="broker-profile d-flex align-items-center justify-content-start p-2 resFee">
                          <img
                            className="shadow1"
                            src={e?.img}
                            
                            alt=""
                            width="60"
                            height="60"
                            style={{ borderRadius: "10px", cursor: "pointer",boxShadow:"5px 5px 10px rgba(0,0,0,.23)" }}
                          />
                          <div className="px-3 m">
                            <h6 className="broker-profile-name-1 center fw-bold fs-6">
                              {e?.broker}
                            </h6>
                            <h6 className="broker-profile-name-2 center fw-bold fs-6 para-color type">
                              {e?.type}
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-2 col-md-4 col-sm-4">
                        <div className="broker-spread text-center new">
                          <p className="para-color p-0 m-2">Spread</p>
                          <h5 className="broker-profile-name-2 fw-bold  fs-4 cur">
                            {
                              e?.[selectedCurrencyPair.toLowerCase()]?.[
                                selectedCurrencyPair
                              ]
                            }
                          </h5>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4">
                        <div className="broker-comission text-center new1">
                          <p className="para-color p-0 m-2">Commission</p>
                          <h5 className="broker-profile-name-2 fw-bold  fs-4 cur1">
                            $
                            {e?.[selectedCurrencyPair.toLowerCase()]?.comission}
                          </h5>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12">
                        <div className="broker-percentage text-center p-2">
                          <div className="link-btn pb-3">
                            <div className="theme-btn btn-style-two">
                              <span
                                className="btn-title"
                                onClick={() => window.open(e?.link, "_blank")}
                                style={{
                                  padding: "3px 7px",
                                  cursor: "pointer",
                                }}
                              >
                                VISIT BROKER{" "}
                                <LuExternalLink
                                  size="22"
                                  style={{ paddingBottom: "5px" }}
                                />{" "}
                              </span>
                            </div>
                          </div>
                          <p className="percentage-para p-0 m-2">
                            74-89% of retail CFD accounts lose money
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div
                  className="link-btn pb-3 text-center"
                  onClick={() => {
                    setShow(!show);
                  }}
                >
                  <div className="theme-btn btn-style-two"></div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      <Footer />
    </>
  );
};

export default FeeCalculator;
