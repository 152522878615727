/* eslint-disable */
import React, { useEffect, useState } from "react";
import { LuExternalLink } from "react-icons/lu";
import plus500 from "./../assets/img/Plus500.svg";
import IG from "./../assets/img/IG.png";
import Pepperstone from "./../assets/img/Pepperstone.png";
import City from "./../assets/img/City Index.png";
import { Link } from "react-router-dom";
import Instance from "../utils/Interceptor";

function CenteroneSignapore() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchBrokerData = async () => {
    // setLoading(true);
    try {
      setLoading(true);
      const response = await Instance.get("api/singapore-broker-point");
      // const Fee = response.data.data.slice(0, 41);
      setData(response?.data?.data);
      if (response.data.status === true) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    } catch (error) {
      console.error("Error fetching broker data:", error);
      setLoading(true);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchBrokerData();
    // scrollTo(0,0)
  }, []);
  return (
    <div className="">
      <h1 className=" fw-bold pt-2 py-3 text-center">
        Best Forex Brokers in Singapore
      </h1>
      <div className="container" style={{paddingLeft:"110px"}}>
      <p className="text-justify container">
        To find the{" "}
        <a href="https://www.forexbrokers.com/guides/forex-trading">
          Best forex brokers
        </a>{" "}
        in Singapore, we created a list of all MAS authorized brokers, then
        ranked brokers by both trustworthiness, as well as their Overall
        ranking.
      </p>
      </div>
      <div className="container" style={{paddingLeft:"110px"}}>
      <p className="text-justify container">
        Here is our list of the top forex brokers in Singapore:
      </p>
      </div>
      {data?.map((e, i) => {
        return (
          <div className="d-flex flex-col justify-content-center container">
            <div className="d-flex align-items-center justify-content-between calculate-broker  my-3 row col-lg-12 ">
              <div className="col-lg-2 col-md-12 col-sm-12">
                <div className="broker-profile d-flex align-items-start justify-content-start p-5 resFee">
                  <img
                    className=""
                    src={e?.image}
                    alt=""
                    width="77"
                    height="77"
                    style={{ borderRadius: "10px", cursor: "pointer",boxShadow:"5px 5px 10px rgba(0,0,0,.23)" }}
                  />
                  <div className="px-3 m">
                    <h6 className="broker-profile-name-1 center fw-bold fs-6">
                      {""}
                    </h6>
                    <h6 className="broker-profile-name-2 center fw-bold fs-6 para-color type">
                      {""}
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-12 ">
                <div className="broker-spread text-start new">
                  <p className="para-color p-0 m-2 bottum">General Info</p>
                  {e?.levels.slice(0, 4)?.map((e, i) => {
                    return <li>{e?.point}</li>;
                  })}
                  {/* <li>Min Deposit: $100</li>
                  <li>Max Leverage: 30:1</li>
                  <li>Platforms: Plus500 app, Webtrader</li>
                  <li>Inactivity Fee: Yes</li> */}
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-12">
                <div className="broker-comission text-start new1">
                  <p className="para-color p-0 m-2 bottum1">Average Spread</p>
                  {e?.levels.slice(4, 8)?.map((e, i) => {
                    return <li>{e?.point}</li>;
                  })}
                  {/* <li>EURUSD: 1.3</li>
                  <li>USDJPY: 1.7</li>
                  <li>GBPUSD: 2.0</li>
                  <li>GOLD: 0.3</li> */}
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-center">
                <div className="broker-percentage text-center p-2">
                  <div className="link-btn pb-3">
                    <div className="theme-btn btn-style-two">
                      <span
                        className="btn-title"
                        onClick={() => window.open(e?.url, "_blank")}
                        style={{
                          padding: "3px 7px",
                          cursor: "pointer",
                        }}
                      >
                        VISIT BROKER{" "}
                        <LuExternalLink
                          size="22"
                          style={{ paddingBottom: "5px" }}
                        />{" "}
                      </span>
                    </div>
                  </div>
                  <div className="hint ">
                    <Link to={e?.path} className="forhover ">
                      {" "}
                      <span style={{ borderBottom: "1px solid #0a2cd4" }}>
                        Read review
                      </span>{" "}
                    </Link>
                    {/* <p className="tradepara py-1 mt-2 mb-0">
                      {"79"}% of retail CFD accounts lose money
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}

<div className="container" style={{paddingLeft:"110px"}}>
            <h1 className=" fw-bold pt-2 py-3 text-center">
            How to Verify MAS Authorisation

            </h1>
            <p className="text-justify container">
            To identify if a forex broker is regulated by MAS, the first step is to identify the register number from the disclosure text at the bottom of the broker’s Singapore homepage. For example, here’s the key disclosure text from Plus500’s website:
            </p>
            <p className="text-justify container">
           <i>
             Plus500SG Pte Ltd (UEN 201422211Z) holds a capital markets services license from the Monetary Authority of Singapore for dealing in capital markets products (License No. CMS100648). Office Address: Plus500SG Pte Ltd | 1 Temasek Avenue, Millenia Tower #37-03 | Singapore 039192.
             </i>
            </p>
            <p className="text-justify container">
            Next, look up the firm on the MAS website to validate the register number is, in fact, legitimate. Here is the official MAS page for <a href="https://eservices.mas.gov.sg/fid/institution?sector=Capital%20Markets&category=Capital%20Markets%20Services%20Licensee&activity=Leveraged%20Foreign%20Exchange%20Trading"> CMC Markets Singapore Pte. Ltd.. </a>
            </p>
            
            <h1 className=" fw-bold pt-2 py-3 text-center">
            About the Monetary Authority of Singapore (MAS)

            </h1>
            <p className="text-justify container">
            Established in 1971 to coordinate the country’s financial sector, the Monetary Authority of Singapore (MAS) has a dual role; it operates both as a central bank and a financial regulator. As a central bank, MAS adopts non-inflationary monetary policies while managing Singapore’s exchange rate and liquidity in the banking sector. As a financial regulator, MAS offers prudential oversight for all financial institutions in Singapore and works with the industry to promote the country as a dynamic financial hub.
            </p>
            <h1 className=" fw-bold pt-2 py-3 text-center">
            Is trading forex legal in Singapore?

            </h1>
            <p className="text-justify container">
            Over five million residents can freely and legally trade forex in Singapore, so long as they do so with brokers that are regulated by the Monetary Authority of Singapore. MAS has long placed an emphasis on strong Anti-Money Laundering (AML) and Combating the Financing of Terrorism (CFT) guidelines as they pertain to brokers in Singapore, having been a founding member of the inter-governmental organization Asia Pacific Group on Money Laundering (APML).
            </p>
            <p className="text-justify container">
            To identify if your preferred forex broker in Singapore is regulated by MAS, we recommend validating the broker’s registration number through the regulator’s <a href="https://eservices.mas.gov.sg/fid/institution?sector=Capital%20Markets&category=Capital%20Markets%20Services%20Licensee&activity=Leveraged%20Foreign%20Exchange%20Trading"> official page. </a> You can typically find the broker’s registration number within the disclosure text at the bottom of their homepage.
            </p>
          
            <h1 className=" fw-bold pt-2 py-3 text-center">
            Is forex taxed in Singapore?

            </h1>
            <p className="text-justify container">
            In a break from tradition, forex trading in Singapore is not subject to taxation unless it is your main source of income, wherein your trading would be classified as a business activity. The Inland Revenue Authority of Singapore (IRAS) categorizes profits derived from forex trading as <a href="https://www.iras.gov.sg/taxes/individual-income-tax/basics-of-individual-income-tax/what-is-taxable-what-is-not/gains-from-sale-of-property-shares-and-financial-instruments"> personal investments </a> and thus those who trade as a hobby are exempt from paying Income or Capital Gains Tax.
            </p>
            
            <p className="text-justify container">
            While residents of Singapore are responsible for reporting taxable events to the government, with professional forex traders who trade for a living potentially paying an<a href="https://www.iras.gov.sg/taxes/individual-income-tax/basics-of-individual-income-tax/tax-residency-and-tax-rates/individual-income-tax-rates"> Income Tax rate of up to 22%, </a>we recommend consulting with your local accountant to best determine your specific tax obligations.
            </p>
            {/* <h1 className=" fw-bold pt-2 py-3 text-center">
              How do you get started trading forex in Australia?
            </h1>
            <p className="text-justify container">
              If you want to start trading forex in Australia, it’s essential to
              start your forex journey by choosing a forex broker that is
              well-regulated and properly licensed in Australia by the{" "}
              <a href="https://connectonline.asic.gov.au/RegistrySearch/faces/landing/SearchRegisters.jspx?_adf.ctrl-state=ibnz0pm5z_4">
                {" "}
                Australian Securities and Investment Commission (ASIC).
              </a>{" "}
              We’ve created this step-by-step guide to help you get started with
              forex trading in Australia:
            </p> */}
            {/* <div className="container">
              <ol>
                <li>
                  {" "}
                  <b>Choose a reputable broker </b>that is properly licensed and
                  regulated by ASIC by verifying their credentials on the ASIC
                  website.
                </li>
                <li>
                  {" "}
                  <b>
                    Familiarize yourself with your broker’s trading software
                  </b>{" "}
                  Check out all available mobile and web apps and explore all
                  your broker’s trading platforms (I always recommend starting
                  out with your broker’s free demo account).
                </li>
                <li>
                  {" "}
                  <b>Fund your trading account </b>using the available deposit
                  methods that are most suitable for you. PayPal has become a
                  popular way to fund forex trading accounts, due to its
                  extensive international presence and wide range of supported
                  currencies.
                </li>
                <li>
                  {" "}
                  <b>Put together a trading plan. </b>Even the best traders can
                  lose money, but the key to long-term success lies in sticking
                  to a trading plan that keeps your average losses low (relative
                  to your average profits).
                </li>
                <li>
                  {" "}
                  <b>Enter the forex market. </b> Choose your desired trade
                  size, and open a long position by clicking buy on a given
                  currency, or open a short position by clicking sell. You are
                  now a forex trader!
                </li>
              </ol>
            </div> */}
          </div>
    </div>
  );
}

export default CenteroneSignapore;
