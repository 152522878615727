
import React from "react";
import Header from "../components/Header";
// import Center from "../components/Center";
// import Centerone from "../components/Center_one";
import Footer from "../components/Footer";
// import {CenterGermnay} from "../components/CenterGermnay";
// import CenteroneGermany from "../components/CenteroneGermany";
// import CenteroneUae from "../components/CenteroneUae";
// import CenterUae from "../components/CenterUae.js";
import CenteroneUk from "../components/CenteroneUk.js";
import CenterUk from "../components/CenterUk.js";

function Uk() {
  return (
    <>
      <Header />
      <CenterUk />
      <CenteroneUk
       

      />
      <Footer />
    </>
  );
}

export default Uk;
