/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router-dom";
// import animatedbanner from "./../assets/img/ezgif.com-gif-maker.webp"
// import { LuExternalLink } from "react-icons/lu";
const Banner = () => {
  const navigate = useNavigate()
  return (
    <div className="bg-img">
      <div className="p-relative w-100">
        <div className="overlaybanner">
          <div className="container">
            <div
              className="banner-head"
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "11px",
                marginTop: "6rem",
                positiona: "absolute",
              }}
            >
              <h2
                style={{
                  zIndex: "1",
                  position: "relative",
                  textAlign: "center",
              
                  fontSize: "44px",
                  color: "white",
                  width:"80%",
                  margin:"0 auto",
                  fontWeight: "600",
                  letterSpacing: "-1px",
                }}
                className="mb-h"
              >
          Our Mission
              </h2>
            
              <p
                className="mb-p"
                style={{
                  zIndex: "1",
                  position: "relative",
                  textAlign: "center",
                  fontSize: "17px",
                  color: "white",
                  maxWidth: "70%",
                  margin:"0 auto"
                }}
              >
                Help Traders Select Best Forex Broker Through
                Financial Expert Reviews. Our Forex Broker List Is Constantly
                Updated Providing The Latest Information. Fund Security Is
                Always First In Forex Trading, Which Is Why  <span style={{fontWeight:"500"}}> We Always Recommend
                Open Account With Well-Regulated Brokers And Avoid Unregulated
                Brokers At All Cost.</span>
              </p>
              <div
                className="link-btn"
                style={{
                  display: "flex",
                  justifyContent:"center"
                }}
              >
                <span href="#" className="theme-btn faiz py-4" onClick={()=>navigate('/allreviews')}>
                  <span className="faiz1">
                    GET YOUR BROKER {" "} <i className="fa-solid fa-arrow-right"></i>
                  
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
