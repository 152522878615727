import React, { useEffect, useState } from "react";
// import logo from "./../assets/img/Get forex-01 (2).png";
import review1 from "./../assets/img/broker_logos/plus500-review.png";
import review2 from "./../assets/img/brokerlogoclient/main-icon.jpg";
import review3 from "./../assets/img/broker_logos/xtb-review.png";
import review4 from "./../assets/img/broker_logos/ig-review.png";
import review5 from "./../assets/img/brokerlogoclient/forex.com logo.png";
import review6 from "./../assets/img/brokerlogoclient/Pepperstone logo.png";
import review7 from "./../assets/img/broker_logos/fxpro-review.png";
import review8 from "./../assets/img/City Index logo.png";
import review9 from "./../assets/img/brokerlogoclient/CMC Markets Logo.png";
import review10 from "./../assets/img/brokerlogoclient/Tickmill logo.png";
import searchicon1 from "./../assets/img/icons/search-icon.svg";
import searchicon2 from "./../assets/img/icons/compare_icon_box.svg";

import searchicon4 from "./../assets/img/icons/fx_fee_calculator_icon.svg";

import searchicon7 from "./../assets/img/icons/scam_shield_icon.svg";
import { BiArrowBack } from "react-icons/bi";
import { AiOutlineArrowRight } from "react-icons/ai";
// import Swissquote_logo from "./../assets/img/brokerlogoclient/Swissquote logo.png";
// import Capital_com_logo from "./../assets/img/brokerlogoclient/Capital.com logo.png";
// import Admirals_Markets_logo from "./../assets/img/brokerlogoclient/Admirals Markets logo.png";
// import eToro_logo from "./../assets/img/brokerlogoclient/eToro logo.png";
// import Oanda_Logo from "./../assets/img/brokerlogoclient/Oanda Logo.jpg";

import arrow from "../assets/img/arrow1.png";
import { Link, useNavigate } from "react-router-dom";

const ResponsiveNavbar = ({ MenuOpen }) => {
  const navigate = useNavigate();
  const [bestbroker, setbestbroker] = useState(false);

  const [brokerreview, setbrokerreview] = useState(false);
  const [Tools, setTools] = useState(false);
  const [for_begginer, setfor_begginer] = useState(false);
  const [Aboutus, setAboutus] = useState(false);

  useEffect(() => {
    if (!MenuOpen) {
      setbestbroker(false);

      setbrokerreview(false);
      setTools(false);
    }
  }, [MenuOpen]);

  const bestbrokerOpen = () => {
    setbestbroker(!bestbroker);
  };
  const rokerreviewOpen = () => {
    setbrokerreview(!brokerreview);
  };
  const ToolsOpen = () => {
    setTools(!Tools);
  };
  const beginneropen = () => {
    setfor_begginer(!for_begginer);
  };
  const aboutOpen = () => {
    setAboutus(!Aboutus);
  };

  return (
    <div
      className={`menu-main mobilemenu clearfix sidebar ${
        MenuOpen ? "open" : ""
      }`}
    >
      <nav className="menu new-nav">
        <ul className="MyMenu">
          <li>
            <span className="toggle noatag-need" onClick={bestbrokerOpen}>
              Best Broker{" "}
              <AiOutlineArrowRight
                style={{ marginRight: "20px", float: "right" }}
              />
            </span>
            {MenuOpen && bestbroker && (
              <div
                className={`services_menu inner_menu sm ${
                  bestbroker ? "open" : ""
                }`}
              >
                <span
                  className="back_menu  "
                  onClick={() => {
                    setbestbroker(false);
                  }}
                >
                  {" "}
                  <BiArrowBack style={{ color: "black" }} />
                  <span className="text-black ms-2 mt-4">Back</span>
                </span>
                <ul className="">
                  <h5
                    className="text-left nested-head "
                    style={{
                      fontSize: "13px",
                      color: "#000",
                      fontWeight: "600",
                      textAlign: "left",
                      padding: "0 15px",

                      opacity: "1",
                    }}
                  >
                    Best broker by category
                  </h5>
                  <hr style={{ color: "gray", margin: "0" }} />

                  <li className=" text-slate-800">
                    {" "}
                    Best brokers for beginners
                  </li>
                  <li className=" text-slate-800"> Best stock brokers</li>
                  <li className=" text-slate-800"> Best forex brokers</li>
                  <li className=" text-slate-800">Best CFD brokers</li>
                  <li className=" text-slate-800">Best futures brokers</li>
                  <li className=" text-slate-800">
                    {" "}
                    Best low spread forex brokers
                  </li>
                  <li className=" text-slate-800"> Best crypto brokers</li>
                  <li className=" text-slate-800"> Best stock trading apps</li>
                  <li className=" text-slate-800"> Best discount brokers</li>
                  <li className=" text-slate-800"> Best brokers for bonds</li>
                </ul>
              </div>
            )}
          </li>
          <li>
            <span className="toggle noatag-need" onClick={rokerreviewOpen}>
              Broker Reviews{" "}
              <img
                src={arrow}
                alt="img"
                style={{ width: "20px", float: "right", marginRight: "20px" }}
              />
            </span>
            {MenuOpen && brokerreview && (
              <div
                className={`services_menu inner_menu sm ${
                  brokerreview ? "open" : ""
                }`}
              >
                <>
                  <span
                    className="back_menu"
                    onClick={() => {
                      setbrokerreview(false);
                    }}
                  >
                    <BiArrowBack style={{ color: "black" }} />
                    <span className="text-black ms-2 mt-4">Back</span>
                  </span>
                </>
                <ul className="pl-0" style={{ paddingLeft: "0px" }}>
                  <h5
                    className="text-left nested-head text-dark"
                    style={{
                      fontSize: "13px",
                      color: "#fff !important",
                      fontWeight: "600",
                      textAlign: "center",
                      opacity: "1",
                    }}
                  >
                    Recommended Broker
                  </h5>
                  <hr style={{ color: "gray", margin: "0" }} />
                  <Link to="/plus500-review" style={{ margin: "0", padding: "0" }}>
                    <li className="cursor-pointer d-flex">
                      <img
                        class="h-6 w-6 "
                        alt="amreica"
                        style={{ borderRadius: "4px" }}
                        width={"25px"}
                        height={"25px"}
                        src={review1}
                      />
                      <p className="ms-2">Plus500</p>
                    </li>
                  </Link>
                  <Link
                    to="/activtraders-review"
                    className=""
                    style={{ margin: "0", marginTop: "-20px", padding: "0" }}
                  >
                    <li className="cursor-pointer">
                      <img
                        width={"25px"}
                        height={"25px"}
                        style={{ borderRadius: "4px" }}
                        class="h-6 w-6 "
                        alt="amreica"
                        src={review2}
                      />{" "}
                      ActiveTrades
                    </li>
                  </Link>
                  <Link
                    to="/xtb-review"
                    className=""
                    style={{ margin: "0", marginTop: "-10px", padding: "0" }}
                  >
                    <li className="cursor-pointer">
                      <img
                        width={"25px"}
                        height={"25px"}
                        style={{ borderRadius: "4px" }}
                        class="h-6 w-6 "
                        alt="amreica"
                        src={review3}
                      />{" "}
                      XTB
                    </li>
                  </Link>
                  <Link
                    to="/ig-review"
                    className=""
                    style={{ margin: "0", marginTop: "-10px", padding: "0" }}
                  >
                    <li className="cursor-pointer">
                      <img
                        width={"25px"}
                        height={"25px"}
                        style={{ borderRadius: "4px" }}
                        class="h-6 w-6 "
                        alt="amreica"
                        src={review4}
                      />{" "}
                      IG
                    </li>
                  </Link>
                  <Link
                    to="/forex-com-review"
                    className=""
                    style={{ margin: "0", marginTop: "-10px", padding: "0" }}
                  >
                    <li className="cursor-pointer">
                      <img
                        width={"25px"}
                        height={"25px"}
                        style={{ borderRadius: "4px" }}
                        class="h-6 w-6 "
                        alt="amreica"
                        src={review5}
                      />{" "}
                      FOREX.com
                    </li>
                  </Link>
                  <Link
                    to="/pepperstone-review"
                    className=""
                    style={{ margin: "0", marginTop: "-10px", padding: "0" }}
                  >
                    <li className="cursor-pointer">
                      <img
                        width={"25px"}
                        height={"25px"}
                        class="h-6 w-6 "
                        alt="amreica"
                        src={review6}
                      />{" "}
                      Pepperstone
                    </li>
                  </Link>
                  <Link
                    to="/fxpro-review"
                    className=""
                    style={{ margin: "0", marginTop: "-10px", padding: "0" }}
                  >
                    <li className="cursor-pointer">
                      <img
                        width={"25px"}
                        height={"25px"}
                        class="h-6 w-6 "
                        alt="amreica"
                        src={review7}
                      />{" "}
                      FxPro
                    </li>
                  </Link>
                  <Link
                    to="/city-index-review"
                    className=""
                    style={{ margin: "0", marginTop: "-10px", padding: "0" }}
                  >
                    <li className="cursor-pointer">
                      <img
                        width={"25px"}
                        height={"25px"}
                        style={{ borderRadius: "4px" }}
                        class="h-6 w-6 "
                        alt="amreica"
                        src={review8}
                      />{" "}
                      City Index
                    </li>
                  </Link>
                  <Link
                    to="/cmc-markets-review"
                    className=""
                    style={{ margin: "0", marginTop: "-10px", padding: "0" }}
                  >
                    <li className="cursor-pointer">
                      <img
                        width={"25px"}
                        height={"25px"}
                        style={{ borderRadius: "4px" }}
                        class="h-6 w-6 "
                        alt="amreica"
                        src={review9}
                      />{" "}
                      CMC Markets{" "}
                    </li>
                  </Link>
                  <Link
                    to="/tickmill-review"
                    className=""
                    style={{ margin: "0", marginTop: "-10px", padding: "0" }}
                  >
                    <li className="cursor-pointer">
                      <img
                        width={"25px"}
                        height={"25px"}
                        style={{ borderRadius: "4px" }}
                        class="h-6 w-6"
                        alt="amreica"
                        src={review10}
                      />{" "}
                      Tickmill
                    </li>
                  </Link>
{/* 

                  <Link
                    to="/etoro-review"
                    className=""
                    style={{ margin: "0", marginTop: "-10px", padding: "0" }}
                  >
                    <li className="cursor-pointer">
                      <img
                        width={"25px"}
                        height={"25px"}
                        style={{ borderRadius: "4px" }}
                        class="h-6 w-6"
                        alt="amreica"
                        src={eToro_logo}
                      />{" "}
                      eToro
                    </li>
                  </Link>
                  <Link
                    to="/oanda-review"
                    className=""
                    style={{ margin: "0", marginTop: "-10px", padding: "0" }}
                  >
                    <li className="cursor-pointer">
                      <img
                        width={"25px"}
                        height={"25px"}
                        style={{ borderRadius: "4px" }}
                        class="h-6 w-6"
                        alt="amreica"
                        src={Oanda_Logo}
                      />{" "}
                      Oanda
                    </li>
                  </Link>
                  <Link
                    to="/swissquote-review"
                    className=""
                    style={{ margin: "0", marginTop: "-10px", padding: "0" }}
                  >
                    <li className="cursor-pointer">
                      <img
                        width={"25px"}
                        height={"25px"}
                        style={{ borderRadius: "4px" }}
                        class="h-6 w-6"
                        alt="amreica"
                        src={Swissquote_logo}
                      />{" "}
                      Swissquote
                    </li>
                  </Link>
                  <Link
                    to="/admiral-review"
                    className=""
                    style={{ margin: "0", marginTop: "-10px", padding: "0" }}
                  >
                    <li className="cursor-pointer">
                      <img
                        width={"25px"}
                        height={"25px"}
                        style={{ borderRadius: "4px" }}
                        class="h-6 w-6"
                        alt="amreica"
                        src={Admirals_Markets_logo}
                      />{" "}
                      Admirals
                    </li>
                  </Link>
                  <Link
                    to="/capital-com-review"
                    className=""
                    style={{ margin: "0", marginTop: "-10px", padding: "0" }}
                  >
                    <li className="cursor-pointer">
                      <img
                        width={"25px"}
                        height={"25px"}
                        style={{ borderRadius: "4px" }}
                        class="h-6 w-6"
                        alt="amreica"
                        src={Capital_com_logo}
                      />{" "}
                      Capital.com
                    </li>
                  </Link> */}
                  <li className="cursor-pointer d-flex justify-content-center">
                    <button className="btn btn-primary rounded-2 w-100 px-4 ">
                      <Link to="/allreviews">All review</Link>
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </li>

          <li>
            <span className="toggle noatag-need" onClick={ToolsOpen}>
              Tools{" "}
              <img
                src={arrow}
                alt="img"
                style={{ width: "20px", float: "right", marginRight: "20px" }}
              />
            </span>
            {MenuOpen && Tools && (
              <div
                className={`services_menu inner_menu sm ${Tools ? "open" : ""}`}
                //   style={{ left: servicesOpen ? '0px' : '-100%',  transition: servicesOpen ? 'left 0.5s steps(10, end)' : 'none'

                //             }}
              >
                <span
                  className="back_menu"
                  onClick={() => {
                    setTools(false);
                  }}
                >
                  {" "}
                  <BiArrowBack style={{ color: "black" }} />
                  <span className="text-black ms-2 mt-4">Back</span>
                </span>
                <ul
                  className="pl-0"
                  style={{
                    paddingLeft: "0px",
                  }}
                >
                  <li className="cursor-pointer">
                    <div className="d-flex gap-2">
                      <img
                        class="h-6 w-6 pb-1"
                        alt="amreica"
                        width={"50px"}
                        height={"50px"}
                        style={{
                          background: "#0050DB",
                          borderRadius: "10px",
                          padding: "5px 15px",
                        }}
                        src={searchicon1}
                      />
                      <div className="d-bloc">
                        <Link>
                          <h5 className="smallh" style={{ color: "black" }}>
                            {" "}
                            Find My Broker
                          </h5>
                        </Link>
                        <p className="smallp" style={{ color: "black" }}>
                          {" "}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="cursor-pointer">
                    <div className="d-flex gap-2">
                      {" "}
                      <img
                        width={"50px"}
                        height={"50px"}
                        class="h-6 w-6 pb-1"
                        alt="amreica"
                        src={searchicon2}
                      />{" "}
                      <div className="d-bloc">
                        <Link to={"/comparebroker"}>
                          <h5 className="smallh" style={{ color: "black" }}>
                            {" "}
                            Compare Broker
                          </h5>
                        </Link>
                        <p className="smallp" style={{ color: "black" }}>
                          {" "}
                        </p>
                      </div>
                    </div>
                  </li>

                  <li className="cursor-pointer">
                    <div className="d-flex gap-2">
                      {" "}
                      <img
                        width={"50px"}
                        height={"50px"}
                        class="h-6 w-6 pb-1"
                        alt="amreica"
                        src={searchicon4}
                      />{" "}
                      <div className="d-block">
                        <Link to={"/compare-spread"}>
                          <h5 className="smallh" style={{ color: "black" }}>
                            {" "}
                            FX Fee Calculator
                          </h5>
                        </Link>
                        <p className="smallp" style={{ color: "black" }}>
                          {" "}
                        </p>
                      </div>
                    </div>
                  </li>

                  <li className="cursor-pointer" >
                    <div className="d-flex gap-2"onClick={()=>navigate('/scam-broker-shield')}>
                      <img
                        width={"50px"}
                        height={"50px"}
                        class="h-6 w-6 pb-1"
                        alt="amreica"
                        src={searchicon7}
                      />{" "}
                      <div className="d-block">
                        <Link>
                          <h5 className="smallh" style={{ color: "black" }} >
                            {" "}
                            Scam Broker Shield
                          </h5>
                        </Link>
                        <p className="smallp" style={{ color: "black" }}>
                          {" "}
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </li>
          <li>
            <span className="toggle noatag-need" onClick={aboutOpen}>
              For Begginer{" "}
              <img
                src={arrow}
                alt="img"
                style={{ width: "20px", float: "right", marginRight: "20px" }}
              />
            </span>
            {MenuOpen && Aboutus && (
              <div
                className={`services_menu inner_menu sm ${
                  Aboutus ? "open" : ""
                }`}
              >
                <span
                  className="back_menu"
                  onClick={() => {
                    setAboutus(false);
                  }}
                >
                  {" "}
                  <BiArrowBack style={{ color: "black" }} />
                  <span className="text-black ms-2 mt-4">Back</span>
                </span>
                <ul className="">
                  <hr style={{ color: "gray", margin: "0" }} />

                  <li className="text-slate-800 cursor-pointer">
                    Best Online Broker
                  </li>
                  <li className="text-slate-800 cursor-pointer" onClick={()=>navigate('/scam-broker-shield')}> 
                    Scam broker check
                  </li>

                  <li className="text-slate-800 cursor-pointer">
                    Forex trading{" "}
                  </li>
                  <li className="text-slate-800 cursor-pointer">
                    CFD trading{" "}
                  </li>
                </ul>
              </div>
            )}
          </li>
          <li>
            <span className="toggle noatag-need" onClick={beginneropen}>
              About us{" "}
              <img
                src={arrow}
                alt="img"
                style={{ width: "20px", float: "right", marginRight: "20px" }}
              />
            </span>
            {MenuOpen && for_begginer && (
              <div
                className={`services_menu inner_menu sm ${
                  for_begginer ? "open" : ""
                }`}
              >
                <span
                  className="back_menu"
                  onClick={() => {
                    setfor_begginer(false);
                  }}
                >
                  {" "}
                  <BiArrowBack style={{ color: "black" }} />
                  <span className="text-black ms-2 mt-4">Back</span>
                </span>
                <ul className="">
                  <li className="text-slate-800 cursor-pointer">Our Team</li>

                  <li className="text-slate-800 cursor-pointer">
                    For partner{" "}
                  </li>
                  <li className="text-slate-800 cursor-pointer">
                    Our methodology{" "}
                  </li>
                </ul>
              </div>
            )}
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default ResponsiveNavbar;
