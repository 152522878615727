import React from "react";

export const CenterGermnay = () => {
  return (
    <>
      <section className="">
        <div className="container py-5 ">
          <div className="col-lg-10 col-md-9 offset-md-1">
            {/* <div className="top-heading d-flex "> */}
            {/* <div className="d-flex align-items-center">
                      <p className="feeCalculator-new px-2 mx-2 fw-bold">NEW</p>
                    </div> */}

            {/* <h1
                className="feeCalculator-heading fw-bold pt-2 py-3"
                style={{ color: "black" }}
              >
                Best Forex Brokers in Australia for 2024
              </h1> */}
            {/* </div> */}

            <div class=" lh-base">
              {/* <p className="p-0 m-0">Calculate your FX trading fees.</p> */}
              <ul className="feeCalculator-list lh-lg">
                <li className="mt-3">
                  Trading forex (currencies) in Germany is popular among
                  residents. While recommended, forex brokers are not required
                  to become authorised by the Federal Financial Supervisory
                  Authority (BaFin) to accept residents of Germany as customers.
                </li>
                <li className="mt-3">
                  The Federal Financial Supervisory Authority is the financial
                  regulatory body in Germany. Website: <a href="https://www.bafin.de.">https://www.bafin.de.</a>
                </li>
                <li className="mt-3">
                  BaFin was established in 2002 when three supervisory agencies
                  merged to create one agency to oversee banks, credit
                  institutions, insurance companies, financial service
                  companies, brokers and stock exchanges. For a historical
                  breakdown, here’s a link to the Federal Financial Supervisory
                  Authority webpage on  <a href="https://en.wikipedia.org/wiki/Federal_Financial_Supervisory_Authority">
                    Wikipedia.
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
