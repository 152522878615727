/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
// import plus500logo from '../assets/img/Plus500logo-review.svg'
// import { TiTick } from "react-icons/ti";
// import { LuExternalLink } from "react-icons/lu";

const Whatiscurency = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    // const minRating = 0.1;
    // const maxRating = 5.0;
    return (
        <>
            <Header />
            <div className="container py-4 px-5">
                <div className='col-lg-10 col-md-9 offset-md-1'>
                    Is forex trading the same as currency trading? How does currency trading work? What are currency pairs, and how do I make sense of them? If you are starting on your journey as a forex trader and asking these questions, you’re on the right track (and you’ve come to the right place).


                    <h3 className='pt-4 pb-4  '>What is currency trading?
                    </h3>




                    <div className="row lg-pt-5 lg-px-5">
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>The terms “currency trading” and “foreign exchange” (or, forex) are essentially interchangeable. Foreign exchange refers to the exchange of international currencies according to prevailing exchange rates. Participants in the global forex market range from retail traders and investors to financial institutions and central banks.</p>
                            <p className='lh-base fs-6'>When you exchange money at the airport before boarding an international flight, you are engaging in currency trading (commonly known as forex trading). Currency trading has become a popular form of financial speculation; forex traders buy and sell currencies in an effort to profit from movements in international exchange rates.</p>
                        </div>
                        <h3 className='pt-4 pb-4  '>How does currency trading work?

                        </h3>
                        <div className="col-lg-12">

                            <p className='lh-base fs-6'>Currency trading takes place across a decentralized electronic network of exchanges, brokers, banks, and other financial institutions. This network, known collectively as the currency trading market (or more commonly as the forex market) operates globally 24 hours a day, 5 days a week.
                            </p>
                        </div>
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>Imagine that you are traveling from Germany to the U.S. for a vacation. You arrive at the currency exchange kiosk in the airport, ready to exchange your euros for U.S. dollars.

                                .</p>
                            <p className='lh-base fs-6'>The concept is largely the same for forex traders speculating on price movements in the currency trading market.
                                .</p>
                            <p className='lh-base fs-6'>For example, a forex trader might believe that the euro is going to go up in value against the U.S. dollar. In other words, they believe the exchange rate will shift in such a way that it will take more U.S. dollars to buy the same number of euros.

                                .</p>
                            <p className='lh-base fs-6'>The forex trader may decide to buy euros and pay for them in U.S. dollars, hoping to make a profit if/when the euro strengthens against the dollar. This is also known as <b> buying the EUR/USD currency pair. </b> If the euro does strengthen against the dollar, those euros become worth more dollars than initially paid for. The forex trader can then turn around and <b> sell the EUR/USD </b> for a profit.
                                .</p>
                        </div>
                        <h3 className='pt-4 pb-4  '>The five most common forms of forex trading


                        </h3>
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>Spot transactions and forex options are the most popular forms of forex trading among retail traders and investors. Let’s take a look at the five primary ways that investors and traders can interact with the forex market:
                                .</p>
                            <ul>
                                <li><b> Spot transactions.</b> Trades are executed on the current spot rate (at the present moment) between two parties. 28% of all forex trades are spot transactions.*</li>
                                <li><b> Foreign exchange swaps. </b> These are agreements between parties to swap currency (one party lends, and one borrows the other currency) at specific future dates and prices. 51% of all forex trades are forex swaps (mostly between institutions).*</li>
                                <li><b>Currency swaps:</b> Currency swaps are similar to foreign exchange swaps, but these may also involve interest rate swaps. Only 2% of all forex transactions are currency swaps.*</li>
                                <li><b>Outright forex forwards:</b> These are agreements to trade forex at a specific price on a future date. 15% of all forex transactions are outright forwards.*</li>
                                <li><b>Forex options:</b> Forex options are contracts that convey the right to buy or sell at specified prices anytime before expiration (but expire worthless if not exercised). Options and other products represent 5% of all forex transactions.* Check out my full guide to trading forex options if you’d like to learn more.</li>
                            </ul>
                            <p className='lh-base fs-6'>*According to data from the <a href='https://www.bis.org/statistics/rpfx22_fx.htm'>Bank of International Settlements’ Triennial Survey, </a>conducted most recently in April 2022.</p>
                        </div>
                        <h3 className='pt-4 pb-4  '>What is a currency pair?



                        </h3>

                        <div className="col-lg-12">
                            In the global forex market, the price of a given currency is always relative to other international currencies. As such, currency prices are quoted in currency pairs. Each currency in a given pair is represented by its international currency, typically separated by a slash.
                            <br />
                            <br />
                            For example, the exchange rate for the euro against the U.S. dollar (remember, all forex pricing is relative) is represented as the EUR/USD pair.
                            <br />
                            <br />
                            Currency pairs are important because they allow forex traders to compare the value of two distinct international currencies. Forex traders use currency pairs as a pricing guide for the forex market.

                        </div>
                        <h3 className='pt-4 pb-4  '>How to read a currency pair



                        </h3>
                        <div className="col-lg-12">
                            Currency pairs (i.e., the EUR/USD) should be read from left to right, the same way you are reading this sentence.
                            <br />
                            <br />
                            The first currency code within a currency pair (appearing on the left) is the base currency. The base currency represents the currency you are buying or selling. The currency code that comes after the slash (on the right) is the quote currency (or, counter currency), which represents the price at which the base currency is being bought or sold.
                            <br />
                            <br />
                            Say you wanted to buy the EUR/USD currency pair. In this case, you’d be purchasing euros (the base currency) and paying with U.S. dollars (the counter currency).
                            <br />
                            <br />
                            Look at it another way: If you saw that the current exchange rate for the EUR/USD is 1.1920, that means you’d be paying $1.1920 U.S. dollars for each euro.


                        </div>
                        <h3 className='pt-4 pb-4  '>What are major currency pairs?




                        </h3>
                        <div className="col-lg-12">
                            The U.S. dollar is the most-traded currency in the global forex market. According to the latest Triennial survey from the Bank of International Settlements, the U.S. dollar was on one side of 88% of all forex trades.

                            <br />
                            <br />
                            Any currency pair that includes the U.S. dollar is typically considered a major pair. Below you’ll find a list of major currency pairs (excluding emerging market currencies):
                            <br />
                            <br />
                            <ul>
                                <li>Euro/U.S. dollar: <b>EUR/USD</b></li>
                                <li>British pound/U.S. dollar: <b>GBP/USD</b></li>
                                <li>U.S. dollar/Japanese yen: <b>USD/JPY</b></li>
                                <li>U.S. dollar/Swiss franc: <b>USD/CHF</b></li>
                                <li>Australian dollar/U.S. dollar: <b>AUD/USD</b></li>
                                <li>New Zealand dollar/U.S. dollar: <b>NZD/USD</b></li>
                                <li>Canadian dollar/U.S. dollar: <b>CAD/USD</b></li>
                                <li>U.S. dollar/Chinese yuan: <b>USD/CNY</b></li>
                                <li>U.S. dollar/Singapore dollar: <b>USD/SGD</b></li>
                                <li>U.S. dollar/Hong Kong dollar: <b>USD/HKD</b></li>
                                <li>U.S. dollar/Indian Rupee: <b>USD/INR</b></li>
                                <li>U.S. dollar/Korean won: <b>USD/KRW</b></li>
                                <li>U.S. dollar/Taiwanese dollar: <b>USD/TWD</b></li>
                                <li>U.S. dollar/Swedish krona: <b>USD/SEK</b></li>
                            </ul>








                        </div>
                        <h3 className='pt-4 pb-4  '>What are minor currency pairs?</h3>
                        <div className="col-lg-12">
                            Minor currency pairs are generally less popular than their major counterparts and can include cross-currency pairs (cross-currency pairs are just pairs that exclude the U.S. dollar).
                            <br />
                            <br />

                            Here are some examples of minor currency pairs (this list is not meant to be exhaustive):

                            <br />
                            <br />
                            <ul>
                                <li>Euro/U.S. dollar – <b>EUR/USD</b></li>
                                <li>British pound/U.S. dollar – <b>GBP/USD</b></li>
                                <li>U.S. dollar/Japanese yen – <b>USD/JPY</b></li>
                                <li>U.S. dollar/Swiss franc – <b>USD/CHF</b></li>
                                <li>Australian dollar/U.S. dollar – <b>AUD/USD</b></li>
                                <li>New Zealand dollar/U.S. dollar – <b>NZD/USD</b></li>
                                <li>Canadian dollar/U.S. dollar – <b>CAD/USD</b></li>
                                <li>U.S. dollar/Chinese yuan – <b>USD/CNY</b></li>
                                <li>U.S. dollar/Singapore dollar – <b>USD/SGD</b></li>
                                <li>U.S. dollar/Hong Kong dollar – <b>USD/HKD</b></li>
                                <li>U.S. dollar/Indian Rupee – <b>USD/INR</b></li>
                                <li>U.S. dollar/Korean won – <b>USD/KRW</b></li>
                                <li>U.S. dollar/Taiwanese dollar – <b>USD/TWD</b></li>
                                <li>U.S. dollar/Swedish krona – <b>USD/SEK</b></li>
                                <li>Euro/British Pound – <b>EUR/GBP</b></li>
                                <li>Euro/Australian dollar – <b>EUR/AUD</b></li>
                                <li>Euro/Canadian dollar – <b>EUR/CAD</b></li>
                                <li>British pound/Australian dollar – <b>GBP/AUD</b></li>
                                <li>British pound/Canadian dollar – <b>GBP/CAD</b></li>
                                <li>British pound/Japanese yen – <b>GBP/JPY</b></li>
                                <li>Australian dollar/Canadian dollar – <b>AUD/CAD</b></li>
                                <li>Australian dollar/Japanese yen – <b>AUD/JPY</b></li>
                                <li>Canadian dollar/Japanese yen – <b>CAD/JPY</b></li>
                                <li>Swiss franc/Japanese yen – <b>CHF/JPY</b></li>
                            </ul>




                        </div>

                        <h3 className='pt-4 pb-4  '>What are exotic currency pairs?</h3>

                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>Exotic currency pairs include currencies that are traded infrequently or have low overall volume. “Exotic” currencies might originate from countries under sanction or that self-impose restrictions on international trade.
                                <br />
                                <br />
                                Here are some examples of exotic currency pairs (this list is not meant to be exhaustive):
                                <br />
                                <br />
                                These exotic currency pairs are even rarer; note the absence of major currencies:
                            </p>
                            <ul>
                                <li>U.S. dollar/Brazilian real – <b>USD/BRL</b></li>
                                <li>Euro/Indonesian rupiah – <b>EUR/IDR</b></li>
                                <li>British pound/Israeli shekel – <b>GBP/ILS</b></li>
                                <li>U.S dollar/Polish zloty – <b>USD/PLN</b></li>
                                <li>Japanese yen/Bulgarian lev – <b>JPY/BGN</b></li>
                                <li>Australian dollar/Thai bhat – <b>AUD/THB</b></li>
                                <li>Canadian dollar/Mexican peso – <b>CAD/MXN</b></li>
                                <li>Swiss franc/Turkish lira – <b>CHF/TRY</b></li>
                                <li>Euro/Russian rouble – <b>EUR/RUB</b></li>
                                <li>U.S. dollar/Philippine peso – <b>USD/PHP</b></li>
                            </ul>
                            <br /><br />
                            These exotic currency pairs are even rarer; note the absence of major currencies:
                            <ul>
                                <li>Brazilian real/Indonesian rupiah – <b>BRL/IDR</b></li>
                                <li>Israeli shekel/Polish zloty – <b>ILS/PLN</b></li>
                                <li>Bulgarian lev/Thai bhat – <b>BGN/THB</b></li>
                                <li>Mexican peso/Turkish lira – <b>MXN/TRY</b></li>
                                <li>Russian rouble/Philippine peso – <b>RUB/PHP</b></li>
                            </ul>
                        </div>
                        <h3 className='pt-4 pb-4  '>

                            How currency pairs move against one another
                        </h3>

                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>A wide variety of economic and geopolitical forces can affect the supply and demand of currencies and, as a result, forex market prices.

                            </p>
                        </div>
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>When favorable economic data is released for a particular country, such as interest rate increases, decreases in unemployment rates, or increases in GDP, the country’s national currency may gain value in the forex market.

                            </p>
                        </div>
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>Any resulting value increase would be reflected in exchange rates for that national currency, relative to other currencies traded in the forex market.

                            </p>
                        </div>

                        <h3 className='pt-4 pb-4  '>Factors that can affect the price of a currency</h3>
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>Here is a (non-exhaustive) list of fundamental data points that can affect currency market prices. Real-time information for these real-world factors can typically be found within economic calendars provided by forex brokers.</p>
                            <ul className='lh-lg'>

                                <li>Central Bank monetary policy</li>
                                <li>Interest rate changes</li>
                                <li>Quantitative easing news</li>
                                <li>Economic data releases</li>
                                <li>Non-farm payroll</li>
                                <li>Industrial output</li>
                                <li>GDP</li>
                                <li>Miscellaneous geopolitical news</li>
                                <li>Supply and demand factors</li>
                                <li>Capital inflows and outflows (repatriation)</li>
                                <li>Expiring options and futures contracts</li>
                                <li>Forwards and forex swaps</li>
                            </ul>

                        </div>



                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Whatiscurency 