/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import cmc from '../assets/img/brokerlogoclient/CMC Markets Logo.png'
import { TiTick } from "react-icons/ti";
import { LuExternalLink } from "react-icons/lu";

const Cmc_markets = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const minRating = 0.1;
    const maxRating = 5.0;
    return (
        <>
            <Header />
            <div className="container py-4 px-4">
                <div className='col-lg-10 col-md-9 offset-md-1'>
                    <h3 className='pt-4 pb-4  broker-name'>CMC Markets Review</h3>
                    <div className="row  pt-5 pb-5 text-center">
                        <div

                            className="col-lg-6 col-md-8 col-sm-12 offset-md-3  pricing-block style-two animated fadeInUp "
                            data-wow-delay="200ms"
                            data-wow-duration="1200ms"
                        >
                            <div
                                className="inner-box1 py-4 px-4"

                            >

                                <h4 >
                                    <a href='' target="_blank">
                                        <img
                                            src={cmc}
                                            width={"77"}
                                            height={"77"}
                                            alt="Picture not Found"
                                            srcset=""
                                            className="mb-2 rounded-2"
                                            style={{
                                                boxShadow: "5px 5px 10px rgba(0,0,0,.23)"
                                              }}
                                        />
                                    </a>
                                </h4>
                                <div className="d-flex py-1  justify-content-center  align-items-center  flex-reverse ">

                                    <div className=" d-flex justify-content-center">
                                        <div class="ratings">
                                            <div class="empty-stars"></div>
                                            <div
                                                className="full-stars"
                                                style={{
                                                    width: `${((5 - minRating) /
                                                        (maxRating - minRating)) *
                                                        89.7959
                                                        }%`,
                                                }}
                                            ></div>
                                        </div>
                                        &nbsp;&nbsp;
                                        <ul className="list-unstyled ptusa"><li> 4.5</li></ul>
                                    </div>
                                </div>

                                <div>
                                    <div
                                        className={`py-0`}>
                                        <div className="grid grid-cols-12 text-sm">
                                            <div className="text-left lh-lg">
                                             <ul className="list-unstyled">
                                             <li>
                                                    <TiTick />
                                                    <strong> Min. Deposit: </strong> $0
                                                </li>

                                                <li className='fs-6'> <TiTick /><strong>Max. Leverage: </strong>30:1</li>
                                                <li className='fs-6'> <TiTick /><strong>Headquarter: </strong> London, UK</li>
                                                <li className='fs-6'> <TiTick /><strong>Products (CFD):</strong>   Forex, Indices, Cryptocurrencies, Commodities, Shares
                                                </li>
                                             </ul>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="py-1  my-1 btn-style-two1">
                                    <a href='' target="_blank" className=" w-100 text-align-center text-light">

                                        <span className="">Visit CMC {' '}</span>
                                        <LuExternalLink color='#fff' size='25' style={{ paddingBottom: '5px' }} />
                                    </a>
                                </div>

                                <div className="hint">

                                    <p className="tradepara1 py-2">
                                        69% of retail CFD accounts lose money
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="row lg-pt-5 lg-px-5">

                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>CMC Markets (CMC), founded in 1989, is a well-established, publicly traded, and highly regarded U.K. forex broker that has successfully adapted to the ever-changing online brokerage landscape. The company is listed on the London Stock Exchange (LSE) under the ticker symbol, CMCX. Like many forex brokers, CMC does not accept U.S. traders.
                                <br /><br />
                                CMC’s flagship platform is a feature-rich, web-based offering aptly named “Next Generation.” This, coupled with the ubiquitous MT4 (downloadable) platform and a functional mobile application, comprises CMC’s entire online offering to the consumer. CMC provides traders access to an extensive range of offerings, both CFDs and spread betting, across several different asset classes. Aside from the greater-than-usual complement of forex, commodities, indices, and individual shares, a comprehensive government bond listing and 14 cryptocurrency offerings (12 cryptos and two indices) fill out the product roster, providing an overwhelming number of potential trading opportunities for all types of traders. Additionally, the broker offers competitive spreads that are dynamic in that they widen or contract with trade sizes, and clients’ accounts are protected from going into a negative balance condition.
                                <br /><br />
                                Dedicated sites for professionals and institutions highlight CMC’s added commitment to skilled traders, but higher-level perks, such as rebates and a free API interface offered to those that qualify as “professional” traders, are not available to the retail trader. Even so, all account holders gain access to guaranteed stop losses (GSLO), free bank wire withdrawals, and other useful features. Investopedia’s ranking algorithm factored in this broker’s extensive product offerings and its top-tier placing in several review categories in recognizing CMC Markets as the Best Overall Forex Broker for 2020.
                            </p>
                        </div>
                        <h2 className='pt-4 pb-4 font-bold'>Who CMC Markets Is for
                        </h2>
                        <p>
                            CMC Markets is for all types of traders, from the novice retail trader looking to dip their toes into the online trading arenas of forex, CFDs, and spread betting, to the experienced veteran seeking exposure to a broad array of products. The firm’s fees are competitive within the industry and it ranks high on several of our lists. The company is ranked as the winner for Investopedia’s Best Overall Forex Broker for 2020 as well as Best Forex Broker for Range of Offerings.</p>
                        <div className="col-lg-12 py-5">
                            <div className='row'>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <h5 className='pb-1 font-bold'>Pros</h5>
                                    <ul className='lh-lg' style={{ listStyle: "none" }}>
                                        <li>✅ Low forex fees</li>
                                        <li>✅ Great web and mobile platforms</li>
                                        <li>✅ Advanced research and educational tools</li>
                                    </ul>


                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <h5 className='pb-1 font-bold'>Cons</h5>
                                    <ul className='lh-lg' style={{ listStyle: "none" }}>
                                        <li>❌High CFD spreads for certain indices</li>
                                        <li>❌Only CFDs are available</li>
                                        <li>❌ No back-testing or automated trading capabilities</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <h2 className='pt-4 pb-4 font-bold'>Cost </h2>
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>
                                Most of the trading costs at CMC Markets are charged through the spread which is competitive within the industry. The spread is also the way that CMC makes money, specifically through customer trades that “cross” the bid / ask spread. Guaranteed stop losses (GSLO) are available only on Next Generation at an added premium that is charged to your account. This cost is refunded In the event that the GSLO is not triggered. There is a commission charge that comes into play when one is trading individual shares and ETFs. This may be charged to your account directly upon order execution or added to the either side of the spread, depending on whether it’s classified as a CFD or spread betting account.
                                <br /><br />
                                Disclosure notes indicate that larger positions incur wider spreads but details are platform ­based, obscuring transparency. Advertised minimum spreads on widely traded major FX pairs range between 0.7 pips (EUR/USD, USD/JPY, AUD/USD) to 0.9 (GBP/USD) to 1.0 (USD/CHF), but the fine print reveals minor holding cost variations that could impact bottom lines. Legal disclosures also admit the two platforms can display different spreads for identical instruments. Margin and overnight holding costs are average to competitive.
                                <br /><br />
                                A much higher-than-average five­-point S&P ­500 spread for CFDs and spread bets stood out like a sore thumb on the index cost disclosures. That spread is charged through all hours of the trading day, including local time in the U.S., making it an unappealing trading instrument for most account holders. In contrast, the Nasdaq spread at 1.0 ­point and DJIA spread at 1.6­ points are closer to industry standard.
                                <br /><br />
                                CMC does not have a minimum deposit requirement for customers wishing to open a live account, but logic dictates that this amount will be subject to the margin requirements of the smallest trade size that the customer wishes to place. Clients with high account balances are eligible for premium services, such as higher trading leverage, a personal account manager, perks (rebates and rewards), priority access to new products, and segregated accounts.
                                <br /><br />
                                Aside from an inactivity fee of £10 (or its equivalent in the account’s base currency) per month for each live account with no trading activity in 12 months, and a market data fee for certain CFD shares, CMC does not charge any other non-trading-related fees.



                            </p>
                        </div>


                        <h2 className='pt-4 pb-4 font-bold'>Trade Experience
                        </h2>
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>The proprietary Next Generation trading platform for web, tablet, and mobile will please technically ­oriented clients wanting to upgrade from MetaTrader 4, which is also offered. However, navigation is not as intuitive as some other platforms, which may be a function of all the features that are packed into this interface. Cryptocurrency CFD trading and spread betting is seamless, requiring no special interface or exchange, but relatively high average spreads could reduce client interest.
                                <br /><br />
                                The platform features advanced charting with 115 indicators/overlays, 12 chart types, and 35 drawing tools; highly customizable watchlists; client sentiment; and a streaming Reuters feed. Charts can be popped ­out to build and optimize complex desktop layouts. Clients who want to study long-­term trends will find price histories going back 20 years on major instruments. The platform also provides price projection and pattern recognition tools as well as a built­-in connection to the client­-based trader’s forum.
                                <br /><br />
                                Next Generation has three basic order types with the option of attaching “take profit” and/or “stop loss” orders to them. Additionally, the trader can set an initial stop loss at the time of execution for market orders and then amend it to a guaranteed stop loss after execution. For pending orders, the trader can choose both guaranteed and/or trailing options when filling out the order ticket prior to execution.





                            </p>
                            <ul>
                                <li className='mt-3'><b>Market – </b>The simplest order where a trader signals that their trade request should be executed at the prevailing market rate.</li>

                                <li className='mt-3'><b>Limit  – </b>A pending order where the entry is at a predetermined point below or above the prevailing market rate depending on whether it’s a buy or sell. The trader also has the option of selecting the expiration time of this order.</li>

                                <li className='mt-3'><b>Stop  – </b>A pending order where the entry is at a predetermined point above or below the prevailing market rate depending on whether it’s a buy or sell. The trader also has the option of selecting the expiration time of this order.</li>

                            </ul>
                            <p>
                                Clients can choose between Next Generation and MetaTrader 4 for iOS and Android when picking a mobile platform. The home­grown app outshines MT4 in nearly all aspects but includes fewer features than the web version. More importantly, chart optimization has been emphasized. Clients can build complex orders and generate customized alerts through push notification or email. The apps provide fingerprint ID entry, but this useful feature isn’t part of a two-­tier authentication process. MetaTrader 4 is offered as an add­-on or standalone account option but is limited to CFDs and has no share capacity.
                                <br /><br />
                                Next Generation does not have back testing capabilities, forcing some account holders to use MetaTrader 4, which is inferior to the home­grown platform in other ways. CMC doesn’t offer direct VPS service, either. The same holds true for an API interface that permits the use of more sophisticated trading platforms that support algorithmic and automatic trading routines. Client positioning data embedded within the proprietary software is an excellent feature that could have been enhanced if social or copy trading was also available.
                            </p>
                            <h2 className='pt-4 pb-4 font-bold'>Markets And Products
                            </h2>
                            <p>
                                CMC Markets offers clients one of the largest product catalogs available in the online forex brokerage community. As advertised on the company website, clients can trade up to 10,000 instruments, which opens up many new opportunities, especially with longer-­term portfolio managers. These include:
                            </p>
                            <ul>
                                <li className='mt-1'>330+ FX pairs
                                </li>
                                <li className='mt-1'>90+ Indices
                                </li>
                                <li className='mt-1'>12 Cryptocurrencies
                                </li>
                                <li className='mt-1'>9000+ Shares and ETFs
                                </li>
                                <li className='mt-1'>110+ Commodities
                                </li>
                                <li className='mt-1'>50+ Treasuries
                                </li>
                            </ul>
                            <p>
                                Spreads are competitively low and all financial instruments, with the exception of individual shares, have specified margin percentages. CMC’s margin rates on shares “start from 20% on U.K. and U.S. shares. Different margin rates apply for large position sizes on shares, depending on which tier the position size falls under.”
                            </p>


                        </div>


                        <h2 className='pt-4 pb-4 font-bold'>Safety
                        </h2>
                        <div className="col-lg-12">
                            <p>CMC Markets is regulated through the Financial Conduct Authority (FCA) under registration number 173730. The broker also takes steps to ensure client funds are not co-mingled with corporate funds, in accordance with the U.K. FCA’s client money rules. This ensures that client assets are protected in the unlikely event that CMC becomes insolvent by holding those funds in segregated accounts at regulated banks. Furthermore, these segregated funds are held in a number of U.K. and European banks so as to disperse risk further.
                                <br /><br />
                                Clients also have additional asset protection through the Financial Services Compensation Scheme (FSCS), up to £85,000, but no layers of private insurance, which is an omission, given CMC’s large industry footprint. Negative balance protection is now mandated under ESMA rules that went into effect in 2018. The broker’s dealing desk acts as the initial counter-party on all CFD trades and spread bets but pricing is automated through the platforms, reducing conflict of interest potential.
                                <br /><br />
                                CMC’s software security is aligned with the best that the industry has to offer. In addition to two-factor and bio-metric authentication, there is a timer that will log the user out of the mobile application if it is not being used.

                            </p>


                        </div>

                        <h2 className='pt-4 pb-4 font-bold'>Customer Service
                        </h2>

                        <p>In this day and age, an online broker that does not place a high priority on addressing the needs of its customer in an efficient and satisfactory manner may be doomed to failure. CMC Markets is well aware of this. Contact options cover all the bases, with email for clients and prospective clients, 24/5 local phone support, and comprehensive FAQ and support libraries. Live chat can also be launched from within the Next Generation platform, which is very convenient. The FAQs are quite informative and well organized. Chat waiting times averaged 30 to 40 seconds during several contact attempts. The broker also runs 13 satellite offices on 4 continents to support licensed operations in other venues. X (formerly Twitter) and Meta accounts were filled with up­-to-­date comments and active client interactions.



                            .</p>
                        <h2 className='pt-4 pb-4 font-bold'>Education
                        </h2>
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>CMC’s educational tools rank in the top-tier of what’s offered by competitors. An extensive trader’s library will get lower­-skilled clients up to speed quickly, though the technical and fundamental tutorials are beginner level and lacking in advanced instruction. A large share of educational resources is devoted solely to platform and software instruction, which is quite useful given Next Generation’s abundance of features.
                                <br /><br />
                                Introductory guides to trading the wide array of products offered are also available along with a section comprised of videos devoted to trading strategies. A healthy roster of online webinars, offered through a partnership with Trade With Precision, fills the need for advanced topics, but there’s no archive of previous programs.
                                <br /><br />
                                Overall, educational resources are impressive but the lack of a search function makes it harder to find less popular topics and leaves one with the impression that this section is poorly organized. The glossary is adequate.
                            </p>



                            <h2 className='pt-4 pb-4 font-bold'> Research

                            </h2>
                            <p>
                                CMC Markets shines in this category, with extensive research and analysis from in-house analysts and third-party materials that include Morningstar quantitative equity research reports (live accounts only). Access to this feature alone might entice a serious trader to open a live account with CMC Markets. Many resources can be accessed directly through the website while news sources can be filtered by region and asset class. The site also features a standard economic calendar. If there is one criticism, it would have to be regarding the lack of a dedicated search function or well-organized archive, which makes it difficult to find many topics.
                                <br /><br />
                                Research and news integration between the website and trading platforms could be improved, with a few resources forcing the client to exit the platform and go to a web page. That said, Next Generation offers the client access to Reuters News (live accounts only), both fundamental (OPTO Magazine) and technical (pattern recognition software embedded into the platform and chart forum) market analysis, in addition to the highly useful CMC TV service which provides regular video market commentary via a platform link to YouTube.
                            </p>
                        </div>




                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Cmc_markets