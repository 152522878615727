/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import tickmill_logo from '../assets/img/brokerlogoclient/Tickmill logo.png'
import word_image from '../assets/img/word-image-20.png'
import word_image_22 from '../assets/img/word-image-22.png'
import Platform_Chart from '../assets/img/Platform_Chart-1.png'
import word_image_24 from '../assets/img/word-image-24.png'
import word_image_28 from '../assets/img/word-image-28.png'
import { TiTick } from "react-icons/ti";
import { LuExternalLink } from "react-icons/lu";

export default function Tickmill_review() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const minRating = 0.1;
  const maxRating = 5.0;
  return (
    <>
      <Header />
      <div className="container py-4 px-4">
        <div className='col-lg-10 col-md-9 offset-md-1'>
          <h3 className='pt-4 pb-4  broker-name'>Tickmill review</h3>
          <div className="row  pt-5 pb-5 text-center">
            <div

              className="col-lg-6 col-md-8 col-sm-12 offset-md-3  pricing-block style-two animated fadeInUp "
              data-wow-delay="200ms"
              data-wow-duration="1200ms"
            >
              <div
                className="inner-box1 py-4 px-4 "

              >

                <h4 >
                  <a href='' target="_blank">
                    <img
                      src={tickmill_logo}
                      width={"77"}
                      height={"77"}
                      alt=""
                      srcset=""
                      className="mb-2 rounded-2 shadow1"
                    />
                  </a>
                </h4>
                <div className="d-flex py-1  justify-content-center  align-items-center  flex-reverse ">

                  <div className=" d-flex justify-content-center">
                    <div class="ratings">
                      <div class="empty-stars"></div>
                      <div
                        className="full-stars"
                        style={{
                          width: `${((5 - minRating) /
                            (maxRating - minRating)) *
                            87.7551
                            }%`,
                        }}
                      ></div>
                    </div>
                    &nbsp;&nbsp;
                                        <ul className="list-unstyled ptusa"><li> 4.4</li></ul>
                  </div>
                </div>

                <div>
                  <div
                    className={`py-0`}>
                    <div className="grid grid-cols-12 text-sm">
                      <div className="text-left lh-lg">
                      <ul className='list-unstyled'>
                      {/* <li>
                          <TiTick />
                          <strong> Rating </strong> – 4.4
                        </li> */}
                        <li>
                          <TiTick />
                          <strong> Min. Deposit: </strong> $100
                        </li>

                        <li className='fs-6'> <TiTick /><strong>Max. Leverage: </strong> 500:1</li>
                        <li className='fs-6'> <TiTick /><strong>Headquarter: </strong> London, UK</li>
                        <li className='fs-6'> <TiTick /><strong>Products (CFD):</strong> Forex, Stock Indices, Commodities, Bonds, Stocks</li>
                      </ul>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="py-1  my-1 btn-style-two1">
                  <a href='' target="_blank" className=" w-100 text-align-center text-light">

                    <span className="">Visit Tickmill {' '}</span>
                    <LuExternalLink color='#fff' size='25' style={{ paddingBottom: '5px' }} />
                  </a>
                </div>

                <div className="hint">

                  <p className="tradepara1 py-2">
                    72% of retail CFD accounts lose money
                  </p>
                </div>
              </div>
            </div>
          </div>



          <div className="row lg-pt-5 lg-px-5">
            <div className="col-lg-12">
              <p className='lh-base fs-6'>Tickmill is not publicly traded and does not operate a bank. Tickmill is authorised by two Tier-1 regulators (Highly Trusted), one Tier-2 regulator (Trusted), zero Tier-3 regulators (Average Risk), and one Tier-4 regulator (High Risk). Tickmill is authorised by the following Tier-1 regulators: <a href=""> Financial Conduct Authority (FCA), </a> the <a href="">Cyprus Securities and Exchange Commission (CySEC)</a>,  and regulated in the European Union via the MiFID passporting system.</p>
            </div>
            <div className="col-lg-12">
              <p className='lh-base fs-6'>Founded in 2014 and based in the United Kingdom, Tickmill has rapidly grown from a newcomer in trading into a well-established brand in the financial industry in less than a decade.</p>
            </div>
            <div className="col-lg-12">
              <p className='lh-base fs-6'>I was impressed by Tickmill’s diverse assortment of CFDs, Options, and Futures and the variety of trading platforms, including MetaTrader 4 and 5. The broker also stands out for its competitive fees, ultra-fast execution speeds, integration with Autochartist, and market sentiment tools.</p>
            </div>
            <div className="col-lg-12">
              <p className='lh-base fs-6'>I found Tickmill’s offering to be one of the most well-calibrated for high-volume and high-frequency traders.</p>
            </div>
            <h2 className='pt-4 pb-4 font-bold'>Tickmill Pros and Cons</h2>
            {/* <div className="col-lg-12">
              Plus500 is for the individual that seeks access to a wide range of financial markets with low trading costs and a simple, functional platform on which to place their trades. Plus500 offers over 2,800+ CFDs on its trading platform with competitive spreads and no commissions. This should entice the experienced trader who prefers to transact manually and finds the lower cost worth the lack of added functionality that some competitors offer.
            </div> */}
            <div className="col-lg-12 py-5">
              <div className='row'>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <h5 className='pb-1 font-bold'>Pros</h5>
                  <ul className='lh-lg' style={{ listStyle: "none" }}>
                    <li>✅ Average execution speed of 20 milliseconds</li>
                    <li>✅ Below-average trading fees</li>
                    <li>✅ Selection of platforms</li>
                    <li>✅ Choice of trading instruments</li>
                    <li>✅ Robust supporting content and tools</li>
                  </ul>


                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <h5 className='pb-1 font-bold'>Cons</h5>
                  <ul className='lh-lg' style={{ listStyle: "none" }}>
                    <li>❌ No proprietary platform</li>
                    <li>❌ Less competitive fees on Classic account</li>
                    <li>❌ Basic news feed</li>
                  </ul>
                </div>
              </div>
            </div>
            <h2 className='pt-4 pb-4 font-bold'>Tickmill Regulations</h2>
            <div className="col-lg-12">
              <p className='lh-base fs-6'>In our reviews, we examine the licenses and regulations of each entity operated by a broker. This allows us to compare their different levels of protection. We rank licenses by various regulatory bodies on a three-tier system, where Tier-1 licensing indicates the highest level of regulation.</p>
            </div>
            <div className="col-lg-12">
              <p className='lh-base fs-6'>This is what I discovered about Tickmill:</p>
            </div>
            <div className="col-lg-12">
              <ul className='lh-lg'>
                <li><strong>Tickmill UK Ltd</strong> is authorized and regulated by the Financial Conduct Authority (<a href="">FCA</a>) in the UK under license number 717270. It is also authorized by the Dubai Financial Services Authority (<a href="">DFSA</a>) in the United Arab Emirates as a representative office, with reference number F007663. The FCA ranks as a Tier-1 regulator on our system, while DFSA ranks as a Tier-2 regulator on our system.</li>

                <li><strong>Tickmill Europe Ltd</strong   > is authorized and regulated by the Cyprus Securities and Exchange Commission (<a href="">CySEC</a>) under license number 278/15. CySEC ranks as a Tier-1 regulator on our system.</li>

                <li><strong>Tickmill South Africa (Pty) Ltd</strong> is licensed and regulated by the Financial Sector Conduct Authority (<a href="">FSCA</a>) in South Africa with license number FSP49464. The FSCA ranks as a Tier-2 regulator on our system.</li>

                <li><strong>Tickmill Asia Ltd</strong>  is authorized and regulated by the Financial Services Authority (<a href="">FSA</a>) of Labuan Malaysia under license number MB/18/0028. The FSA ranks as a Tier-2 regulator on our system.</li>
                <li><strong>Tickmill Ltd</strong> is regulated and licensed by the Financial Services Authority (<a href="">FSA</a>) of Seychelles under license number SD008. The FSA ranks as a Tier-3 regulator on our system.</li>
              </ul>
            </div>

            <h2 className='pt-4 pb-2 font-bold'>Tickmill’s Trading Fees</h2>
            <h5 className='pt-2 pb-4 font-bold'>Tickmill’s Spreads</h5>
            <div className="col-lg-12">
              <p className='lh-base fs-6'>I tested Tickmill’s spreads during the most actively traded times – the London open at 8:00 a.m. GMT and just after the U.S. open at 2:45 p.m. GMT. The test was conducted on 7 November 2023. The results are shown in the table below:</p>
            </div>
            <div className="col-lg-12">
              <table className='table w-100'>
                <tbody >
                  <tr>
                    <td className='w-100 border'>
                      <strong>Instrument</strong>
                    </td>
                    <td className='w-100 border'>
                      <strong>Live Spread AM</strong>
                    </td>
                    <td className='w-100 border'>
                      <strong>Live Spread PM</strong>
                    </td>
                  </tr>
                  <tr>
                    <td className='w-100 border '>EURUSD</td>
                    <td className='w-100 border '>0.1 pips</td>
                    <td className='w-100 border '>0.3 pips</td>
                  </tr>
                  <tr>
                    <td className='w-100 border '>GBPJPY</td>
                    <td className='w-100 border '>0.8 pips</td>
                    <td className='w-100 border '>0.6 pips</td>
                  </tr>
                  <tr>
                    <td className='w-100 border '>Gold (XAUUSD)</td>
                    <td className='w-100 border '>11 pips</td>
                    <td className='w-100 border '>7 pips</td>
                  </tr>
                  <tr>
                    <td className='w-100 border '>Crude Oil</td>
                    <td className='w-100 border '>0.03 pips</td>
                    <td className='w-100 border '>0.02 pips</td>
                  </tr>
                  <tr>
                    <td className='w-100 border '>Apple</td>
                    <td className='w-100 border '>NA</td>
                    <td className='w-100 border '>0.06 points</td>
                  </tr>
                  <tr>
                    <td className='w-100 border '>Tesla</td>
                    <td className='w-100 border '>NA</td>
                    <td className='w-100 border '>0.13 points</td>
                  </tr>
                  <tr>
                    <td className='w-100 border '>Dow Jones 30</td>
                    <td className='w-100 border '>2.2 basis points</td>
                    <td className='w-100 border '>1.4 basis points</td>
                  </tr>
                  <tr>
                    <td className='w-100 border '>Germany 40</td>
                    <td className='w-100 border '>0.8 basis points</td>
                    <td className='w-100 border '>0.8 basis points</td>
                  </tr>
                  <tr>
                    <td className='w-100 border '>Bitcoin</td>
                    <td className='w-100 border '>No instrument</td>
                    <td className='w-100 border '>No instrument</td>
                  </tr>
                </tbody>
              </table>

            </div>
            <div className="col-lg-12">
              <p className='lh-base fs-6'>Broken down by asset class and compared to the broader industry, Tickmill charges below-average spreads on instruments across all major asset classes. I was especially impressed by the low spreads I measured on gold and the two stock indices.</p>
            </div>
            <h5 className='pt-4 pb-4 font-bold'>Tickmill’s Commissions</h5>
            <div className="col-lg-12">
              <p className='lh-base fs-6'>Traders who open a Pro account with Tickmill have to pay a $2 per side ($4 round-turn) commission for executing a full-sized position (100,000 units). This commission rate is below the industry average of $3 per side and is suitable for high-frequency trading. The classic account is a commission-free account while the commission for the VIP account is set at $1 per standard lot.</p>
            </div>
            <h5 className='pt-4 pb-4 font-bold'>Tickmill’s Non-Trading Fees</h5>

            <div className="col-lg-12">
              <p className='lh-base fs-6'>Tickmill does not charge a handling fee on deposits or withdrawals, though third-party banking fees may apply. It, however, has an inactivity fee of £10 charged on accounts on which no activity has been registered for one calendar year. The fee is charged monthly until activity resumes or the account balance drops to £0.</p>
            </div>
            <h5 className='pt-4 pb-4 font-bold'>Are Tickmill’s Fees Competitive?</h5>

            <div className="col-lg-12">
              <p className='lh-base fs-6'>Tickmill’s fees are indeed very competitive. I found them suitable for the implementation of a wide variety of trading strategies. The combination of low spreads, especially on commodities and indices, and low fixed commissions is especially suitable for high-frequency and high-volume trading, which require frequent getting in and out of large-scale positions (scalping).</p>
            </div>
            <h5 className='pt-4 pb-4 font-bold'>Accounts Comparison</h5>

            <div className="col-lg-12">
              <p className='lh-base fs-6'>I have compiled the table below to help you better understand the difference between the trading fees on Tickmill’s three CFDs accounts. It illustrates the spreads and commissions I have recorded on the EUR/USD pair on the Classic, Pro, and VIP accounts. The table shows how much you will have to pay to trade 1 full lot (100,000 units) on the EUR/USD with a pip value of $10.</p>
            </div>
            <div className="col-lg-12">
              <p className='lh-base fs-6'>To calculate the cost of such a full-sized trade, I used this formula:</p>
            </div>

            <div className="col-lg-12">
              <p className='lh-base fs-6'><strong>Spread x pip value+commission</strong></p>
            </div>

            <div className="col-lg-12 overflow-x-scroll">
              <table className='table w-100'>
                <tbody >
                  <tr>
                    <td className='w-100 border'>
                      <strong>Account Type*</strong>
                    </td>
                    <td className='w-100 border'>
                      <strong>Spread</strong>
                    </td>
                    <td className='w-100 border'>
                      <strong>Commission**</strong>
                    </td>
                    <td className='w-100 border'>
                      <strong>Net Cost</strong>
                    </td>
                  </tr>
                  <tr>
                    <td className='w-100 border '>Classic Account</td>
                    <td className='w-100 border '>1.7 pips</td>
                    <td className='w-100 border '>$0</td>
                    <td className='w-100 border '>$17</td>
                  </tr>
                  <tr>
                    <td className='w-100 border '>Pro Account</td>
                    <td className='w-100 border '>0.2 pips</td>
                    <td className='w-100 border '>$4</td>
                    <td className='w-100 border '>$6</td>
                  </tr>
                  <tr>
                    <td className='w-100 border '>VIP Account</td>
                    <td className='w-100 border '>0.2 pips</td>
                    <td className='w-100 border '>$2</td>
                    <td className='w-100 border '>$4</td>
                  </tr>
                </tbody>
              </table>

            </div>
            <div className="col-lg-12">

            <p className='lh-base fs-6'>*The numbers in this chart are only illustrative and subject to change over time</p>

            <p className='lh-base fs-6'>**Round-turn commission</p>

            <p className='lh-base fs-6'>Given that the industry average net cost fluctuates around $10, my tests indicate that Tickmill’s Pro and VIP accounts have very favorable trading fees. The main difference is their accessibility. The Pro account has a minimum deposit requirement of $100, making it quite affordable for all kinds of retail traders. In contrast, the VIP account has a minimum balance requirement of $50,000, rendering it available only to more affluent traders.</p>

            <h2 className='pt-4 pb-4 font-bold'>Platforms and Tools</h2>
            <p className='lh-base fs-6'>In this review, I evaluated the MetaTrader 5 platform, notable for its extensive features, including a remarkable selection of 21 timeframes. This aspect especially distinguishes it from other retail trading platforms. It also has an intuitive strategy tester tool, making it possible for algo traders to automate their strategies. Tickmill is especially suitable for algorithmic trading due to its fast execution speed (around 20 milliseconds). I, however, have some reservations about the platform, primarily stemming from its rugged design.</p>

            <img className='w-75' src={word_image} alt="" />

            <p className='lh-base fs-6'>MetaTrader 5’s depth of market feature</p>
            <p className='lh-base fs-6'>When you right-click on MT5’s chart screen, you get a dropdown menu with some of its key features. As shown above, the platform supports one-click trading, which is essential for high-frequency trading strategies. The platform also has other highly practical features, such as the possibility to set alarms and the depth of the market tool.</p>
            <p className='lh-base fs-6'>Tickmill also offers multiple MT4&5 plugins and toolkits for enhanced trading, such as a correlation matrix, market sentiment tool, session map, and more.</p>

            <h2 className='pt-4 pb-4 font-bold'>Tickmill’s MetaTrader 5 Desktop Platform</h2>
            <img className='w-75' src={word_image_22} alt="" />
            <p className='lh-base fs-6'>The general outlook of MetaTrader 5</p>
            <h3 className='pt-4 pb-4 font-bold'>General Ease of Use</h3>
            <p className='lh-base fs-6'>The platform has a very familiar layout. The watch list and screen navigator tools are positioned on the left-hand side, the charts screen is on the right-hand side, the strategy tester and other account info panes are at the bottom, and a panel of available settings can be found at the top. The arrows on the image above indicate where you can access all the available technical indicators and drawing tools.</p>
            <h3 className='pt-4 pb-4 font-bold'>Charts</h3>
            <p className='lh-base fs-6'>I consider the chart screen a platform’s most important feature. It aids technical analysis by providing an overview of price action behavior and allows traders access to a range of analytical tools and chart configuration possibilities. A good chart should afford easy scaling up and down of price action.</p>
            <img className='w-75' src={Platform_Chart} alt="" />

            <p className='lh-base fs-6'>I have broken down the available analytical tools and chart configurations below:</p>


            </div>
           
            <div className="col-lg-12">
              <ul className='lh-lg'>
                <li><strong>38 technical indicators</strong>. The platform supports trend-based, volume-based indicators, oscillators, and more. These can be applied to study price action behavior and determine the underlying market sentiment. In general, technical indicators are used to gauge where the market is likely to head next.</li>

                <li><strong>24 drawing tools</strong>. Drawing tools, such as Fibonacci retracement levels and Elliott waves, are used to study repeatable price patterns. Additionally, they can be applied to determine key support and resistance levels and potential breakout or breakdown levels.</li>

                <li><strong>21 timeframes</strong>. The platform affords multi-timeframe analysis of price action behavior. The greater the number of timeframes, the more intricate examinations that can be carried out across the short-term and long-term.</li>
                <li><strong>3 chart types.</strong>. MT5 makes it possible to represent price action as a line, area, or using candlesticks. This diversity makes it possible to examine potential trading opportunities from different angles.</li>
              </ul>
            </div>
            <div className="col-lg-12">
            <h3 className='pt-4 pb-4 font-bold'>Orders</h3>
            <img className='w-75' src={word_image_24} alt="" />
              <p className='lh-base fs-6'>An order placing window on MetaTrader 5</p>
              <p className='lh-base fs-6'>I have broken down the available order types on the MetaTrader 5 platform below:</p>
              <ul className='lh-lg'>
                <li><strong>Market orders</strong>. Market orders are used for immediate entry at the best possible price. If triggered, they guarantee volume filling, though there could be a discrepancy</li>
                <li><strong>Limit orders</strong>. Unlike market orders, limit orders guarantee exact price execution. However, a limit order will not be filled if the price action does not reach the pre-determined execution price.</li>
                <li><strong>Stop orders</strong>. They are used to support open positions by limiting the maximum loss that can be incurred if the market turns in the opposite direction. A stop-loss order is placed at a fixed price below or above the spot price. If the market does indeed turn and triggers the stop-loss, it will transform into a market order and get filled at the best possible price.</li>
            <h3 className='pt-4 pb-4 font-bold'>Tradable Instruments</h3>
            <p className='lh-base fs-6'>AI have broken down the available instruments with Tickmill below:</p>


                <li><strong>62 Currency Pairs</strong></li>
                <p className='lh-base fs-6'>Major, Minor, Exotic</p>

                <li><strong>11 Commodities</strong></li>
                <p className='lh-base fs-6'>Metals and Energy</p>

                <li><strong>494 Share CFDs</strong></li>              
                <p className='lh-base fs-6'>Finance, Tech, Retail, Other</p>

                <li><strong>23 ETFs</strong></li>
                <p className='lh-base fs-6'>Europe, U.S., Other</p>
                <li><strong>20 Indices</strong></li>
                <p className='lh-base fs-6'>UK, Europe, U.S., Asia, Other</p>
                <li><strong>9 Cryptocurrencies*</strong></li>
                <p className='lh-base fs-6'>Majors and Minors</p>
                <li><strong>4 Bonds</strong></li>
                <p className='lh-base fs-6'>Europe</p>
                <li><strong>124 Futures and Options</strong></li>
                <p className='lh-base fs-6'>Indices, Interest Rates, Metals, Energy, Other</p>
                </ul>
            </div>


            <div className="col-lg-12">
              <p className='lh-base fs-6'>*For Tickmill UK Ltd crypto trading is available to Professional Clients only</p>
              <p className='lh-base fs-6'>Compared to the broader industry, Tickmill offers an average amount of FX pairs and commodities and a high amount of share CFDs and indices.</p>
              <p className='lh-base fs-6'>Compared to the broader industry, Tickmill offers an average amount of FX pairs and commodities and a high amount of share CFDs and indices.</p>
            </div>
            <h3 className='pt-4 pb-4 font-bold'>Customer Support</h3>
            <div className="col-lg-12">
              <p className='lh-base fs-6'>The live chat option is available from the bottom-right corner of the main page. Before you get in touch with an agent, you have to fill in your name and email address and choose a preferred language. Alternatively, you can use one of the other available contact channels listed <a href="">here</a>.</p>
              <img className='w-75' src={word_image_28} alt="" />
              <p className='lh-base fs-6'>Tickmill’s live chat option</p>

            </div>
            <div className="col-lg-12">
            <h3 className='pt-4 pb-4 font-bold'>Tickmill Customer Support Test</h3>
              <p className='lh-base fs-6'>When we test a broker’s customer support team, we evaluate the agent’s knowledge of their own website, how long it takes them to respond to questions, and how detailed their answers are.</p>
              <p className='lh-base fs-6'>I conducted my test on the 8 November at around 10:57 am CET via live chat. An agent connected almost instantaneously after I submitted my query. Initially, I asked about Tickmill’s execution model and received a straightforward answer shortly afterward.</p>
              <p className='lh-base fs-6'>The agent was polite and well-informed on a range of topics. He did not take long to respond to my several queries. I had a very positive overall impression of Tickmill’s customer support.</p>
            </div>
            <h3 className='pt-4 pb-4 font-bold'>Deposit and Withdrawal</h3>
            <h5 className='pt-4 pb-4 font-bold'>Tickmill’s Deposit Methods</h5>

            <div className="col-lg-12 overflow-x-scroll">
              <table className='table w-100'>
                <tbody >
                  <tr>
                    <td className='w-100 border '>Method</td>
                    <td className='w-100 border '>Currency</td>
                    <td className='w-100 border '>Min. Transaction</td>
                    <td className='w-100 border '>Fee</td>                   
                     <td className='w-100 border '>Processing Time</td>
                  </tr>
                  <tr>
                    <td className='w-100 border '>Credit/Debit Card</td>
                    <td className='w-100 border '>USD, EUR, GBP, PLN</td>
                    <td className='w-100 border '>100</td>
                    <td className='w-100 border '>$0</td>                    
                    <td className='w-100 border '>Instant</td>
                  </tr>
                  <tr>
                    <td className='w-100 border '>Wire Transfer</td>
                    <td className='w-100 border '>USD, EUR, GBP, PLN, CHF, ZAR</td>
                    <td className='w-100 border '>100</td>
                    <td className='w-100 border '>$0*</td>                   
                     <td className='w-100 border '>Within 1 business day</td>
                  </tr>

                  <tr>
                    <td className='w-100 border '>Skrill</td>
                    <td className='w-100 border '>USD, EUR, GBP, PLN</td>
                    <td className='w-100 border '>100</td>
                    <td className='w-100 border '>$0</td>                    
                    <td className='w-100 border '>Instant</td>
                  </tr>
                  <tr>
                    <td className='w-100 border '>Neteller</td>
                    <td className='w-100 border '>USD, EUR, GBP, PLN, CHF</td>
                    <td className='w-100 border '>100</td>
                    <td className='w-100 border '>$0</td>                    
                    <td className='w-100 border '>Instant</td>
                  </tr>
                  <tr>
                    <td className='w-100 border '>Przelewy24</td>
                    <td className='w-100 border '>PLN</td>
                    <td className='w-100 border '>100</td>
                    <td className='w-100 border '>$0</td>                    
                    <td className='w-100 border '>Instant</td>
                  </tr>
                  <tr>
                    <td className='w-100 border '>Sofort</td>
                    <td className='w-100 border '>EUR, GBP</td>
                    <td className='w-100 border '>100</td>
                    <td className='w-100 border '>$0</td>                    
                    <td className='w-100 border '>Instant</td>
                  </tr>
                  <tr>
                    <td className='w-100 border '>Rapid</td>
                    <td className='w-100 border '>EUR, PLN, GBP, USD</td>
                    <td className='w-100 border '>100</td>
                    <td className='w-100 border '>$0</td>                    
                    <td className='w-100 border '>Instant</td>
                  </tr>
                  <tr>
                    <td className='w-100 border '>PayPal</td>
                    <td className='w-100 border '>USD, EUR, GBP, PLN, CHF</td>
                    <td className='w-100 border '>100</td>
                    <td className='w-100 border '>$0</td>                    
                    <td className='w-100 border '>Instant</td>
                  </tr>
                </tbody>
              </table>

            </div>
 {/* end.  */}
            <div className="col-lg-12">
              <p className='lh-base fs-6'>*$0 Tickmill covers banking fees on wire transfers above $5,000</p>
              <h3 className='pt-4 pb-4 font-bold'>Tickmill’s Withdrawal Methods</h3>
            </div>

            <div className="col-lg-12 overflow-x-scroll">
              <table className='table w-100'>
                <tbody >
                  <tr>
                    <td className='w-100 border '>Method</td>
                    <td className='w-100 border '>Currency</td>
                    <td className='w-100 border '>Min. Transaction</td>
                    <td className='w-100 border '>Fee</td>                   
                     <td className='w-100 border '>Processing Time</td>                     
                     <td className='w-100 border '>Estimated Arrival</td>
                  </tr>
                  <tr>
                    <td className='w-100 border '>Credit/Debit Card</td>
                    <td className='w-100 border '>USD, EUR, GBP, PLN</td>
                    <td className='w-100 border '>25</td>
                    <td className='w-100 border '>$0</td>                    
                    <td className='w-100 border '>Within 1 working day</td>              
                    <td className='w-100 border '>Up to 8 business days</td>
                  </tr>
                  <tr>
                    <td className='w-100 border '>Wire Transfer</td>
                    <td className='w-100 border '>USD, EUR, GBP, PLN, CHF</td>
                    <td className='w-100 border '>25</td>
                    <td className='w-100 border '>$0</td>                   
                     <td className='w-100 border '>Within 1 working day</td>                     
                     <td className='w-100 border '>2-7 business days</td>
                  </tr>

                  <tr>
                    <td className='w-100 border '>Skrill</td>
                    <td className='w-100 border '>USD, EUR, GBP, PLN</td>
                    <td className='w-100 border '>25</td>
                    <td className='w-100 border '>$0</td>                    
                    <td className='w-100 border '>Within 1 working day</td>              
                          <td className='w-100 border '>Instant</td>
                  </tr>
                  <tr>
                    <td className='w-100 border '>Neteller</td>
                    <td className='w-100 border '>USD, EUR, GBP, PLN, CHF</td>
                    <td className='w-100 border '>25</td>
                    <td className='w-100 border '>$0</td>                    
                    <td className='w-100 border '>Within 1 working day</td>              
                          <td className='w-100 border '>Instant</td>
                  </tr>
                  <tr>
                    <td className='w-100 border '>Przelewy24</td>
                    <td className='w-100 border '>PLN</td>
                    <td className='w-100 border '>25</td>
                    <td className='w-100 border '>$0</td>                    
                    <td className='w-100 border '>Within 1 working day</td>              
                          <td className='w-100 border '>Within 1 business day</td>
                  </tr>
                  <tr>
                    <td className='w-100 border '>Sofort</td>
                    <td className='w-100 border '>EUR, GBP</td>
                    <td className='w-100 border '>25</td>
                    <td className='w-100 border '>$0</td>                    
                    <td className='w-100 border '>Within 1 working day</td>              
                          <td className='w-100 border '>Not specified</td>
                  </tr>
                  <tr>
                    <td className='w-100 border '>Rapid</td>
                    <td className='w-100 border '>EUR, PLN, GBP, USD</td>
                    <td className='w-100 border '>25</td>
                    <td className='w-100 border '>$0</td>                    
                    <td className='w-100 border '>Within 1 working day</td>              
                          <td className='w-100 border '>Not specified</td>
                  </tr>
                  <tr>
                    <td className='w-100 border '>PayPal</td>
                    <td className='w-100 border '>USD, EUR, GBP, PLN, CHF</td>
                    <td className='w-100 border '>25</td>
                    <td className='w-100 border '>$0</td>                    
                    <td className='w-100 border '>Within 1 working day</td>              
                          <td className='w-100 border '>Not specified</td>
                  </tr>
                </tbody>
              </table>

            </div>
            <div className="col-lg-12">
              <p className='lh-base fs-6'>Tickmill processes all payments (deposits and withdrawals) within a single business day. However, some transactions may take up to 8 business days to reflect in your bank account. Notably, Tickmill covers banking fees on deposits via bank wire above $5000.</p>
            </div>
      
            <div className="col-lg-12">
              <p className='lh-base fs-6'>Deposit and withdrawal methods vary depending on the entity.</p>
            </div>
          
        
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
