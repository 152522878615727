/* eslint-disable no-use-before-define */
/* eslint-disable no-sparse-arrays */
/* eslint-disable no-dupe-keys */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useEffect } from "react";
import { useState, useRef } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import Select from "react-select";
import { SlClose } from "react-icons/sl";

import "swiper/css/pagination";

import { AiOutlineArrowRight } from "react-icons/ai";

import { BsCheck2Circle } from "react-icons/bs";
import { LuExternalLink } from "react-icons/lu";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import Instance from "../utils/Interceptor";

const NewCompareBroker = () => {
  const countries = [
    { value: "AllCountry", label: "All Country" },
    { value: "Afghanistan", label: "Afghanistan" },
    { value: "Albania", label: "Albania" },
    { value: "Algeria", label: "Algeria" },
    { value: "American Samoa", label: "American Samoa" },
    { value: "Andorra", label: "Andorra" },
    { value: "Angola", label: "Angola" },
    { value: "Anguilla", label: "Anguilla" },
    { value: "Antigua & Barbuda", label: "Antigua & Barbuda" },
    { value: "Argentina", label: "Argentina" },
    { value: "Armenia", label: "Armenia" },
    { value: "Aruba", label: "Aruba" },
    { value: "Australia", label: "Australia" },
    { value: "Austria", label: "Austria" },
    { value: "Bahrain", label: "Bahrain" },
    { value: "Bangladesh", label: "Bangladesh" },
    { value: "Belgium", label: "Belgium" },
    { value: "Brazil", label: "Brazil" },
    { value: "China", label: "China" },
    { value: "Cyprus", label: "Cyprus" },
    { value: "Denmark", label: "Denmark" },
    { value: "Egypt", label: "Egypt" },
    { value: "Finland", label: "Finland" },
    { value: "France", label: "France" },
    { value: "Germany", label: "Germany" },
    { value: "Hong Kong", label: "Hong Kong" },
    { value: "Ireland", label: "Ireland" },
    { value: "Israel", label: "Israel" },
    { value: "Italy", label: "Italy" },
    { value: "Japan", label: "Japan" },
    { value: "Kuwait", label: "Kuwait" },
    { value: "Luxembourg", label: "Luxembourg" },
    { value: "Netherlands", label: "Netherlands" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "Oman", label: "Oman" },
    { value: "Poland", label: "Poland" },
    { value: "Qatar", label: "Qatar" },
    { value: "Saudi Arabia", label: "Saudi Arabia" },
    { value: "Singapore", label: "Singapore" },
    { value: "South Africa", label: "South Africa" },
    { value: "Spain", label: "Spain" },
    { value: "Sweden", label: "Sweden" },
    { value: "Switzerland", label: "Switzerland" },
    { value: "United Arab Emirates", label: "United Arab Emirates" },
    { value: "United Kingdom", label: "United Kingdom" },
  ];  

  const swiperRef = useRef(null);

  const [selectedBroker, setSelectedBroker] = useState([]);
  const [isfixed, setIsfixed] = useState(false);
  const [BrokerDatas, setBrokerDatas] = useState([]);
  const [Loader, setLoader] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [BrokerDatas]);

  const [SelectCountry, setSelectedCountry] = useState();

  // console.log("SelectCountry---->", SelectCountry);
  const countryCodes = {
    AF: "Afghanistan",
    AL: "Albania",
    AE: "United Arab Emirates",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    BH: "Bahrain",
    BD: "Bangladesh",
    BE: "Belgium",
    BR: "Brazil",
    CN: "China",
    CY: "Cyprus",
    DK: "Denmark",
    EG: "Egypt",
    FI: "Finland",
    FR: "France",
    DE: "Germany",
    HK: "Hong Kong",
    IE: "Ireland",
    IL: "Israel",
    IT: "Italy",
    JP: "Japan",
    KW: "Kuwait",
    LU: "Luxembourg",
    NL: "Netherlands",
    NZ: "New Zealand",
    OM: "Oman",
    PL: "Poland",
    QA: "Qatar",
    SA: "Saudi Arabia",
    SG: "Singapore",
    ZA: "South Africa",
    ES: "Spain",
    SE: "Sweden",
    CH: "Switzerland",
    UAE: "United Arab Emirates",
    GB: "United Kingdom",
    PK: "AllCountry",
    US: "AllCountry",
    RU: "AllCountry",
    TR: "AllCountry",
    IN: "AllCountry",
    CA: "AllCountry",
    MX: "AllCountry",
    NG: "AllCountry",
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      try {
        const response = await Instance.get("api/country-code");
        const country = response.data.country;
        const fullCountry = countryCodes[country];
        const condition =
          fullCountry && Object.values(countryCodes).includes(fullCountry)
            ? fullCountry
            : "AllCountry";
        setSelectedCountry(condition);
        setLoader(false);
      } catch (error) {
        console.error("Error fetching country code:", error);
      }

      try {
        setLoader(true);
        const response = await Instance.get("api/compare-broker");
        const brokerData = response.data.data.slice(0, 36);
        setBrokerDatas(brokerData);
        setLoader(false);
      } catch (error) {
        console.error("Error fetching broker data:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [activeSlidesData2, setActiveSlidesData2] = useState(null);
  const [filteredData, setfilteredData] = useState([]);
  const handleChange = (selectedOptions) => {
    setSelectedBroker(selectedOptions);
  };
  const handleCountry = (event) => {
    const selectedValue = event.value;
    setSelectedCountry(selectedValue);
    // console.log(selectedValue, "<====handleCountry");
  };
  // const selectedCountry = country;
  const selectedBrokers = BrokerDatas.filter((broker) =>
    broker.country?.includes(SelectCountry)
  );
  // console.log(selectedBrokers, "<===selectedBrokers");

  // console.log(filteredData);
  useEffect(() => {
    const selectedValues = selectedBroker.map((selected) =>
      selected.value.toLowerCase()
    );

    const filteredData = BrokerDatas.filter((broker) =>
      selectedValues.includes(broker.brokername.toLowerCase())
    );

    setfilteredData(filteredData);
    if (filteredData.length > 0) {
      setActiveSlidesData([]);
      setActiveSlidesData2(filteredData);
    } else {
      const selectedBrokers = BrokerDatas.filter((broker) =>
        broker.country?.includes(SelectCountry)
      );
      setActiveSlidesData2(null);
      console.log(selectedBrokers.slice(0, 3), "<--Yes");
      setActiveSlidesData(selectedBrokers.slice(0, 3));
    }
    // eslint-disable-next-line
  }, [selectedBroker]);
  // console.log(selectedBroker);
  const [activeSlidesData, setActiveSlidesData] = useState([]);
  const goNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleSlideChange = (swiper) => {
    const activeIndexes = [
      swiper.realIndex,
      swiper.realIndex + 1,
      swiper.realIndex + 2,
      swiper.realIndex + 3,
      // swiper.realIndex + 4,
    ];
    if (filteredData.length === 0) {
      const selectedBrokers = BrokerDatas.filter((broker) =>
        broker.country?.includes(SelectCountry)
      );

      setActiveSlidesData(
        selectedBrokers.slice(activeIndexes[0], activeIndexes[0] + 3)
      );
    } else {
      setActiveSlidesData(null);
      setActiveSlidesData2(
        filteredData.slice(
          activeIndexes[0],
          activeIndexes[0] + filteredData.length
        )
      );
    }
  };

  const options = BrokerDatas?.filter((broker) =>
    broker.country?.includes(SelectCountry)
  ).map((e) => {
    return { label: `${e?.brokername}`, value: e?.brokername };
  });
  useEffect(() => {
    const handleScroll = () => {
      const scrollAmount1 = 280;
      const scrollAmount2 = 1500;
      const scrollY = window.pageYOffset;

      if (scrollY >= scrollAmount1 && scrollY < scrollAmount2) {
        setIsfixed(true);
      } else {
        setIsfixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      width: "435px",

      "@media (max-width: 600px)": {
        width: "320px",
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      "@media (max-width: 600px)": {
        width: "320px",
      },
    }),
  };
  const minRating = 0.1;
  const maxRating = 5.0;
  return (
    <div>
      <Header />
      {Loader ? (
        <div className="Loader"></div>
      ) : (
        <div className="overflow-x-hidden">
          <section className="comparebroker container-fluid py-5">
            <div className="row">
              <div className="col-lg-4 col-md-6 mt-3 mb-3 posi">
                <p className="input-label fw-bold">Select Country</p>
                <Select
                  styles={selectStyles}
                  options={countries}
                  defaultValue={{ label: SelectCountry, value: SelectCountry }}
                  onChange={handleCountry}
                />{" "}
              </div>
            </div>
            <div className="d-flex w-100 justify-content-center pb-4">
              <div className="select-group">
                {/* <div className={isfixed ? "fixed-div" : "left-2"}> */}
                <div>
                  <h4 className="text-center">Select Your Broker to Compare</h4>
                  <Select
                    onChange={handleChange}
                    isMulti={true}
                    isSearchable={true}
                    value={selectedBroker}
                    styles={selectStyles}
                    options={options}
                  />
                </div>
              </div>
            </div>
            <div className="swiper-broker w-100">
              <div className="border-0 border-lg-bottom border-slate-200 py-2 flex mx-auto max-w-screen-2xl px-3">
                <div className="flex-1" />
                <div className="flex-1 d-lg-none" />
                <div className="d-flex align-items-center w-100 py-1">
                  <div className="d-flex justify-content-between mx-auto items-center w-100 gap-4 d-lg-gap-4 d-lg-justify-content-center d-lg-w-auto align-items-center">
                    <button
                      type="button"
                      className="h-11 w-11 d-lg-h-7 d-lg-w-7 d-flex justify-content-center align-items-center p-1"
                      style={{
                        background: "transparent",
                        outline: "none",
                        border: "none",
                      }}
                      onClick={goPrev}
                    >
                      <div className="h-7 w-7 rounded-circle border border-slate-300 d-flex justify-content-center align-items-center p-0">
                        <BiLeftArrowAlt
                          style={{
                            fontSize: "35px",
                            background: "aliceblue",
                            padding: "7px",
                            borderRadius: "20px",
                          }}
                        />
                      </div>
                    </button>
                    <div className="text-sm font-normal text-slate-600 d-lg-none">
                      16 - 20 of 32 items
                    </div>
                    <div className="d-lg-block text-sm font-normal text-white text-center">
                      <span className="font-weight-bold text-white">16</span>-
                      <span className="font-weight-bold text-white">20</span>{" "}
                      brokers (out of{" "}
                      <span className="font-weight-bold">
                        {selectedBrokers.length}
                      </span>{" "}
                      available in{" "}
                      <span className="text-capitalize">{SelectCountry}</span>)
                    </div>
                    <button
                      type="button"
                      className="p-2 "
                      style={{
                        background: "transparent",
                        outline: "none",
                        border: "none",
                      }}
                      onClick={goNext}
                    >
                      <div className="h-7 w-7 rounded-circle border border-slate-300 d-flex justify-content-center align-items-center p-0">
                        <BiRightArrowAlt
                          style={{
                            fontSize: "35px",
                            background: "aliceblue",
                            padding: "7px",
                            borderRadius: "20px",
                          }}
                        />
                      </div>
                    </button>
                  </div>
                  <div className="d-flex justify-content-center position-absolute bottom-1.5 d-lg-static d-lg-bottom-0">
                    <div
                      className="d-flex gap-2 align-items-center justify-content-center overflow-hidden"
                      style={{ maxWidth: 60 }}
                    >
                      <div className="rounded-circle bg-secondary shrink-0 opacity-30 w-1.5 h-1.5" />
                      <div className="rounded-circle bg-secondary shrink-0 transition-height duration-200 opacity-30 w-1.5 h-1.5" />
                      <div className="rounded-circle bg-secondary shrink-0 transition-height duration-200 opacity-30 w-2 h-2" />
                      <div className="rounded-circle bg-secondary shrink-0 transition-height duration-200 opacity-100 w-2 h-2" />
                      <div className="rounded-circle bg-secondary shrink-0 transition-height duration-200 opacity-30 w-2 h-2" />
                      <div className="rounded-circle bg-secondary shrink-0 transition-height duration-200 opacity-30 w-1.5 h-1.5" />
                      <div className="rounded-circle bg-secondary shrink-0 opacity-30 w-1.5 h-1.5" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-1 d-flex justify-content-end align-items-center gap-4" />

              <div className="row py-5 mx-2">
                <Swiper
                  ref={swiperRef}
                  slidesPerView={3}
                  spaceBetween={10}
                  onSlideChange={(swiper) => handleSlideChange(swiper)}
                  breakpoints={{
                    300: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 100,
                    },
                  }}
                  navigation={true}
                  allowTouchMove={false}
                  className="mySwiper "
                >
                  {filteredData.length === 0
                    ? selectedBrokers.map((e, i) => (
                        <SwiperSlide key={i}>
                          <div className="slide-wrap">
                            <div className="d-flex flex-column align-items-center">
                              <span
                                onClick={() =>
                                  window.open(`${e?.url}`, "_blank")
                                }
                                className="cursor-pointer"
                              >
                                <img
                                  src={e?.img}
                                  width={"60"}
                                  height={"60"}
                                  className="rounded-2 shadow-lg"
                                  alt=""
                                />
                              </span>
                              <h6 className="text-center py-3">
                                {e?.brokername}
                              </h6>

                              <div className="link-btn">
                                <span
                                  onClick={() =>
                                    window.open(`${e?.url}`, "_blank")
                                  }
                                  className="theme-btn btn-style-two cursor-pointer"
                                >
                                  <span className="btn-title Mobilebtn">
                                    VISIT BROKER{" "}
                                    <LuExternalLink
                                      className="mb-none"
                                      size="22"
                                      style={{ paddingBottom: "5px" }}
                                    />
                                  </span>
                                </span>
                              </div>
                              <div className="hint">
                                <p className="tradepara py-1 my-3 ">
                                  {`${e?.lose}% of retail CFD accounts lose money `}
                                </p>
                              </div>
                            </div>
                            <div className="slider-content">
                              <div className="d-flex flex-column align-items-center justify-content-center">
                                <div>
                                  <h6 className="font-h6">Overall Score</h6>
                                </div>

                                <div className="d-flex mainbar justify-content-center gap-2">
                                  <div className="progress-bar mb-none">
                                    <div
                                      className="progress-fill"
                                      style={{
                                        width: `${
                                          (e?.tradingfees.reduce((acc, fee) => {
                                            const score = parseFloat(
                                              fee?.OverallScore
                                            );
                                            if (!isNaN(score)) {
                                              return acc + (score - minRating);
                                            } else {
                                              return acc;
                                            }
                                          }, 0) /
                                            (maxRating - minRating)) *
                                          100
                                        }%`,
                                      }}
                                    ></div>
                                  </div>
                                  <div class="ratings ld-none">
                                    <div class="empty-stars"></div>
                                    <div
                                      className="full-stars"
                                      style={{
                                        width: `${
                                          (e?.tradingfees.reduce((acc, fee) => {
                                            const score = parseFloat(
                                              fee?.OverallScore
                                            );
                                            if (!isNaN(score)) {
                                              return acc + (score - minRating);
                                            } else {
                                              return acc;
                                            }
                                          }, 0) /
                                            (maxRating - minRating)) *
                                          100
                                        }%`,
                                      }}
                                    ></div>
                                  </div>

                                  <p>
                                    {e?.tradingfees.map((e) => e?.OverallScore)}
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex flex-column align-items-center justify-content-center ">
                                <div>
                                  <h6 className="font-h6">Fee Score</h6>
                                </div>

                                <div className="d-flex mainbar justify-content-center gap-2">
                                  <div className="progress-bar mb-none">
                                    <div
                                      className="progress-fill"
                                      style={{
                                        width: `${
                                          (e?.tradingfees.reduce((acc, fee) => {
                                            const score = parseFloat(
                                              fee?.FeeScore
                                            );
                                            if (!isNaN(score)) {
                                              return acc + (score - minRating);
                                            } else {
                                              return acc;
                                            }
                                          }, 0) /
                                            (maxRating - minRating)) *
                                          100
                                        }%`,
                                        backgroundColor: "#08bb68",
                                      }}
                                    ></div>
                                  </div>
                                  <div class="ratings ld-none">
                                    <div class="empty-stars"></div>
                                    <div
                                      className="full-stars"
                                      style={{
                                        width: `${
                                          (e?.tradingfees.reduce((acc, fee) => {
                                            const score = parseFloat(
                                              fee?.FeeScore
                                            );
                                            if (!isNaN(score)) {
                                              return acc + (score - minRating);
                                            } else {
                                              return acc;
                                            }
                                          }, 0) /
                                            (maxRating - minRating)) *
                                          100
                                        }%`,
                                      }}
                                    ></div>
                                  </div>

                                  <p>
                                    {e?.tradingfees.map((e) => e?.FeeScore)}
                                  </p>
                                </div>
                              </div>
                              <div className="slider-content">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                  <div>
                                    <h6 className="text-center font-h6">
                                      Educational Tools Score
                                    </h6>
                                  </div>

                                  <div className="d-flex mainbar justify-content-center gap-2">
                                    <div className="progress-bar mb-none">
                                      <div
                                        className="progress-fill"
                                        style={{
                                          width: `${
                                            (e?.tradingfees.reduce(
                                              (acc, fee) => {
                                                const score = parseFloat(
                                                  fee?.EducationalToolScore
                                                );
                                                if (!isNaN(score)) {
                                                  return (
                                                    acc + (score - minRating)
                                                  );
                                                } else {
                                                  return acc;
                                                }
                                              },
                                              0
                                            ) /
                                              (maxRating - minRating)) *
                                            100
                                          }%`,
                                          backgroundColor: "#08bb68",
                                        }}
                                      ></div>
                                    </div>
                                    <div class="ratings ld-none">
                                      <div class="empty-stars"></div>
                                      <div
                                        className="full-stars"
                                        style={{
                                          width: `${
                                            (e?.tradingfees.reduce(
                                              (acc, fee) => {
                                                const score = parseFloat(
                                                  fee?.EducationalToolScore
                                                );
                                                if (!isNaN(score)) {
                                                  return (
                                                    acc + (score - minRating)
                                                  );
                                                } else {
                                                  return acc;
                                                }
                                              },
                                              0
                                            ) /
                                              (maxRating - minRating)) *
                                            100
                                          }%`,
                                        }}
                                      ></div>
                                    </div>
                                    <p>
                                      {e?.tradingfees.map(
                                        (e) => e?.EducationalToolScore
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="slider-content">
                                <h6 className="text-center font-h6">
                                  Account Processing Time
                                </h6>

                                <p className="text-center  option-para">
                                  {e?.nontradingfees.map((e) => e?.DepositFee)}
                                </p>
                              </div>
                              {/* <div className="slider-content">
                <h6 className="text-center">Withdrawl Fee</h6>
                <p className="text-center  option-para">
                  {e?.nontradingfees.map((e) => e?.WithdrawlFee)}
                </p>
              </div> */}

                              <div className="slider-content">
                                <h6 className="text-center">Regulations</h6>
                                <p className="text-center  option-para">
                                  {e?.safety.map((e) => e?.Toptierregulators)}
                                </p>
                              </div>
                              <div className="slider-content">
                                <h6 className="text-center font-h6">
                                  Funding Methods
                                </h6>
                                <p className="text-center  option-para">
                                  {e?.safety.map((e) => e?.Funding_Methods)}
                                </p>
                              </div>
                              <div className="slider-content">
                                <h6 className="text-center font-h6">
                                  Minimium Deposit
                                </h6>
                                <p className="text-center  option-para option-para">
                                  {e?.depositandwithdrawal.map(
                                    (e) => e?.Minimumdeposit
                                  )}
                                </p>
                              </div>
                              <div className="slider-content">
                                <h6 className="text-center font-h6">
                                  Withdrawal Fee
                                </h6>
                                <p className="text-center  option-para">
                                  {e?.nontradingfees.map(
                                    (e) => e?.WithdrawlFee
                                  )}
                                </p>
                              </div>
                              <div className="slider-content">
                                <h6 className="text-center font-h6">
                                  Maximum Leverage
                                </h6>
                                <p className="text-center  option-para">
                                  {e?.depositandwithdrawal.map(
                                    (e) => e?.MaximumLeverage
                                  )}
                                </p>
                              </div>
                              {/* <div className="slider-content">
                            <h6 className="text-center">Investor Protection</h6>
                            <p className="text-center  option-para">
                              {e?.safety.map((e) =>
                                e?.Investorprotection === "yes" ? (
                                  <BsCheck2Circle className="font-32"
                                    style={{  color: "green" }}
                                  />
                                ) : (
                                  <SlClose className="font-32"
                                    style={{  color: "red" }}
                                  />
                                )
                              )}
                            </p>
                          </div> */}
                              <div className="slider-content">
                                <h6 className="text-center font-h6">
                                  Inactivity Fee
                                </h6>
                                <p className="text-center  option-para">
                                  {e?.nontradingfees.map((e) =>
                                    e?.InactiveFee > 0 ? (
                                      <BsCheck2Circle
                                        className="font-32"
                                        style={{ color: "red" }}
                                      />
                                    ) : (
                                      <SlClose
                                        className="font-32"
                                        style={{ color: "green" }}
                                      />
                                    )
                                  )}
                                </p>
                              </div>
                              <div className="slider-content">
                                <h6 className="text-center font-h6">
                                  Islamic Account
                                </h6>
                                <p className="text-center  option-para">
                                  {e?.nontradingfees.map((e) =>
                                    e?.IslamicAccount === "yes" ? (
                                      <BsCheck2Circle
                                        className="font-32"
                                        style={{ color: "green" }}
                                      />
                                    ) : (
                                      <SlClose
                                        className="font-32"
                                        style={{ color: "red" }}
                                      />
                                    )
                                  )}
                                </p>
                              </div>
                              <div className="slider-content">
                                <h6 className="text-center font-h6">
                                  MT4 Avaiable
                                </h6>
                                <p className="text-center  option-para">
                                  {e?.nontradingfees.map((e) =>
                                    e?.MT4 === "yes" ? (
                                      <BsCheck2Circle
                                        className="font-32"
                                        style={{ color: "green" }}
                                      />
                                    ) : (
                                      <SlClose
                                        className="font-32"
                                        style={{ color: "red" }}
                                      />
                                    )
                                  )}
                                </p>
                              </div>
                              <div className="slider-content">
                                <h6 className="text-center font-h6">
                                  MT5 Avaiable
                                </h6>
                                <p className="text-center  option-para">
                                  {e?.nontradingfees.map((e) =>
                                    e?.MT5 === "yes" ? (
                                      <BsCheck2Circle
                                        className="font-32"
                                        style={{ color: "green" }}
                                      />
                                    ) : (
                                      <SlClose
                                        className="font-32"
                                        style={{ color: "red" }}
                                      />
                                    )
                                  )}
                                </p>
                              </div>
                              <div className="slider-content">
                                <h6 className="text-center font-h6">
                                  TradingView Available
                                </h6>
                                <p className="text-center  option-para">
                                  {e?.nontradingfees.map((e) =>
                                    e?.TradingView === "yes" ? (
                                      <BsCheck2Circle
                                        className="font-32"
                                        style={{ color: "green" }}
                                      />
                                    ) : (
                                      <SlClose
                                        className="font-32"
                                        style={{ color: "red" }}
                                      />
                                    )
                                  )}
                                </p>
                              </div>
                              <div className="slider-content">
                                <h6 className="text-center font-h6">
                                  cTrader Available
                                </h6>
                                <p className="text-center  option-para">
                                  {e?.nontradingfees.map((e) =>
                                    e?.cTrader === "yes" ? (
                                      <BsCheck2Circle
                                        className="font-32"
                                        style={{ color: "green" }}
                                      />
                                    ) : (
                                      <SlClose
                                        className="font-32"
                                        style={{ color: "red" }}
                                      />
                                    )
                                  )}
                                </p>
                              </div>
                              <div className="slider-content">
                                <h6 className="text-center font-h6">
                                  Capitalise.ai Available
                                </h6>
                                <p className="text-center  option-para">
                                  {e?.nontradingfees.map((e) =>
                                    e?.capitaliseaiAvailable === "yes" ? (
                                      <BsCheck2Circle
                                        className="font-32"
                                        style={{ color: "green" }}
                                      />
                                    ) : (
                                      <SlClose
                                        className="font-32"
                                        style={{ color: "red" }}
                                      />
                                    )
                                  )}
                                </p>
                              </div>
                              <div className="slider-content">
                                <h6 className="text-center font-h6">
                                  Zulu Trade Available
                                </h6>
                                <p className="text-center  option-para">
                                  {e?.nontradingfees.map((e) =>
                                    e?.zuluTradeAvaiable === "yes" ? (
                                      <BsCheck2Circle
                                        className="font-32"
                                        style={{ color: "green" }}
                                      />
                                    ) : (
                                      <SlClose
                                        className="font-32"
                                        style={{ color: "red" }}
                                      />
                                    )
                                  )}
                                </p>
                              </div>
                              <div className="slider-content">
                                <h6 className="text-center font-h6">
                                  Copy Trading Available
                                </h6>
                                <p className="text-center  option-para">
                                  {e?.nontradingfees.map((e) =>
                                    e?.copyTradingAvailable === "yes" ? (
                                      <BsCheck2Circle
                                        className="font-32"
                                        style={{ color: "green" }}
                                      />
                                    ) : (
                                      <SlClose
                                        className="font-32"
                                        style={{ color: "red" }}
                                      />
                                    )
                                  )}
                                </p>
                              </div>
                              <div className="slider-content">
                                <h6 className="text-center font-h6">
                                  Autochartist Access
                                </h6>
                                <p className="text-center  option-para">
                                  {e?.nontradingfees.map((e) =>
                                    e?.AutochartistAccess === "yes" ? (
                                      <BsCheck2Circle
                                        className="font-32"
                                        style={{ color: "green" }}
                                      />
                                    ) : (
                                      <SlClose
                                        className="font-32"
                                        style={{ color: "red" }}
                                      />
                                    )
                                  )}
                                </p>
                              </div>
                              <div className="slider-content">
                                <h6 className="text-center font-h6">
                                  {" "}
                                  Trading Centeral Access
                                </h6>
                                <p className="text-center  option-para">
                                  {e?.nontradingfees.map((e) =>
                                    e?.TradingCenteralAccess === "yes" ? (
                                      <BsCheck2Circle
                                        className="font-32"
                                        style={{ color: "green" }}
                                      />
                                    ) : (
                                      <SlClose
                                        className="font-32"
                                        style={{ color: "red" }}
                                      />
                                    )
                                  )}
                                </p>
                                <div
                                  className="hint"
                                  style={{ textAlign: "center" }}
                                >
                                  <a href="#" className="forhover">
                                    {" "}
                                    <span
                                      style={{
                                        borderBottom: "1px solid #0a2cd4",
                                        textAlign: "center",
                                      }}
                                    >
                                      Read review
                                    </span>{" "}
                                  </a>
                                  <div className="link-btn py-3">
                                    <span
                                      onClick={() => window.open("#")}
                                      className="theme-btn btn-style-two cursor-pointer"
                                    >
                                      <span className="btn-title Mobilebtn">
                                        VISIT BROKER{" "}
                                        <LuExternalLink
                                          className="mb-none"
                                          size="22"
                                          style={{ paddingBottom: "5px" }}
                                        />
                                      </span>
                                    </span>
                                  </div>
                                  <p
                                    className="tradepara py-1 mt-2 mb-0"
                                    style={{ textAlign: "center" }}
                                  >
                                    {`${e?.lose}% of retail CFD accounts lose money`}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))
                    : null}
                  {filteredData.map((e, i) => (
                    <SwiperSlide key={i}>
                      <div className="slide-wrap">
                        <div className="d-flex flex-column align-items-center">
                          <span
                            className="cursor-pointer"
                            onClick={() => window.open(`${e?.url}`, "_blank")}
                          >
                            <img
                              src={e?.img}
                              width={"60"}
                              height={"60"}
                              className="rounded-2 shadow-lg"
                              alt=""
                            />
                          </span>
                          <h6 className="text-center py-3">{e?.brokername}</h6>

                          <div></div>
                          <div className="link-btn">
                            <span
                              onClick={() => window.open(`${e?.url}`, "_blank")}
                              className="theme-btn btn-style-two cursor-pointer"
                            >
                              <span className="btn-title Mobilebtn">
                                VISIT BROKER{" "}
                                <LuExternalLink
                                  className="mb-none"
                                  size="22"
                                  style={{ paddingBottom: "5px" }}
                                />
                              </span>
                            </span>
                          </div>
                          <div className="hint">
                            <p className="tradepara py-3 my-3">
                              {`${e?.lose}% of retail CFD accounts lose money`}
                            </p>
                          </div>
                        </div>
                        <div className="slider-content">
                          <div className="d-flex flex-column align-items-center justify-content-center">
                            <div>
                              <h6 className="font-h6">Overall Score</h6>
                            </div>

                            <div className="d-flex mainbar justify-content-center gap-2">
                              <div className="progress-bar mb-none">
                                <div
                                  className="progress-fill"
                                  style={{
                                    width: `${
                                      (e?.tradingfees.reduce((acc, fee) => {
                                        const score = parseFloat(
                                          fee?.OverallScore
                                        );
                                        if (!isNaN(score)) {
                                          return acc + (score - minRating);
                                        } else {
                                          return acc;
                                        }
                                      }, 0) /
                                        (maxRating - minRating)) *
                                      100
                                    }%`,
                                  }}
                                ></div>
                              </div>
                              <div class="ratings ld-none">
                                <div class="empty-stars"></div>
                                <div
                                  className="full-stars"
                                  style={{
                                    width: `${
                                      ((e?.ratting - minRating) /
                                        (maxRating - minRating)) *
                                      100
                                    }%`,
                                  }}
                                ></div>
                              </div>

                              <p>
                                {e?.tradingfees.map((e) => e?.OverallScore)}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex flex-column align-items-center justify-content-center ">
                            <div>
                              <h6 className="font-h6">Fee Score</h6>
                            </div>

                            <div className="d-flex mainbar justify-content-center gap-2">
                              <div className="progress-bar mb-none">
                                <div
                                  className="progress-fill"
                                  style={{
                                    width: `${
                                      (e?.tradingfees.reduce((acc, fee) => {
                                        const score = parseFloat(fee?.FeeScore);
                                        if (!isNaN(score)) {
                                          return acc + (score - minRating);
                                        } else {
                                          return acc;
                                        }
                                      }, 0) /
                                        (maxRating - minRating)) *
                                      100
                                    }%`,
                                    backgroundColor: "#08bb68",
                                  }}
                                ></div>
                              </div>
                              <div class="ratings ld-none">
                                <div class="empty-stars"></div>
                                <div
                                  className="full-stars"
                                  style={{
                                    width: `${
                                      (e?.tradingfees.reduce((acc, fee) => {
                                        const score = parseFloat(fee?.FeeScore);
                                        if (!isNaN(score)) {
                                          return acc + (score - minRating);
                                        } else {
                                          return acc;
                                        }
                                      }, 0) /
                                        (maxRating - minRating)) *
                                      100
                                    }%`,
                                  }}
                                ></div>
                              </div>

                              <p>{e?.tradingfees.map((e) => e?.FeeScore)}</p>
                            </div>
                          </div>
                          <div className="slider-content">
                            <div className="d-flex flex-column align-items-center justify-content-center">
                              <div>
                                <h6 className="text-center">
                                  Educational Tools Score
                                </h6>
                              </div>

                              <div className="d-flex mainbar justify-content-center gap-2">
                                <div className="progress-bar mb-none">
                                  <div
                                    className="progress-fill"
                                    style={{
                                      width: `${
                                        (e?.tradingfees.reduce(
                                          (acc, fee) => {
                                            const score = parseFloat(
                                              fee?.EducationalToolScore
                                            );
                                            if (!isNaN(score)) {
                                              return acc + (score - minRating);
                                            } else {
                                              return acc;
                                            }
                                          },
                                          0
                                        ) /
                                          (maxRating - minRating)) *
                                        100
                                      }%`,
                                      backgroundColor: "#08bb68",
                                    }}
                                  ></div>
                                </div>
                                <div class="ratings ld-none">
                                  <div class="empty-stars"></div>
                                  <div
                                    className="full-stars"
                                    style={{
                                      width: `${
                                        (e?.tradingfees.reduce(
                                          (acc, fee) => {
                                            const score = parseFloat(
                                              fee?.EducationalToolScore
                                            );
                                            if (!isNaN(score)) {
                                              return acc + (score - minRating);
                                            } else {
                                              return acc;
                                            }
                                          },
                                          0
                                        ) /
                                          (maxRating - minRating)) *
                                        100
                                      }%`,
                                    }}
                                  ></div>
                                </div>
                                <p>
                                      {e?.tradingfees.map(
                                        (e) => e?.EducationalToolScore
                                      )}
                                    </p>
                              </div>
                            </div>
                          </div>
                          <div className="slider-content">
                            <h6 className="text-center">
                              Account Processing Time
                            </h6>

                            <p className="text-center  option-para">
                              {e?.nontradingfees.map((e) => e?.DepositFee)}
                            </p>
                          </div>
                          <div className="slider-content">
                            <h6 className="text-center">Withdrawl Fee</h6>
                            <p className="text-center  option-para">
                              {e?.nontradingfees.map((e) => e?.WithdrawlFee)}
                            </p>
                          </div>

                          <div className="slider-content">
                            <h6 className="text-center font-h6">Regulations</h6>
                            <p className="text-center  option-para">
                              {e?.safety.map((e) => e?.Toptierregulators)}
                            </p>
                          </div>
                          <div className="slider-content">
                            <h6 className="text-center">Funding Methods</h6>
                            <p className="text-center  option-para">
                              {e?.safety.map((e) => e?.Funding_Methods)}
                            </p>
                          </div>
                          <div className="slider-content">
                            <h6 className="text-center">Minimium Deposit</h6>
                            <p className="text-center  option-para">
                              {e?.depositandwithdrawal.map(
                                (e) => e?.Minimumdeposit
                              )}
                            </p>
                          </div>
                          <div className="slider-content">
                            <h6 className="text-center">Withdrawl Fee</h6>
                            <p className="text-center  option-para">
                              {e?.nontradingfees.map((e) => e?.WithdrawlFee)}
                            </p>
                          </div>
                          <div className="slider-content">
                            <h6 className="text-center">Maximum Leverage</h6>
                            <p className="text-center  option-para">
                              {e?.depositandwithdrawal.map(
                                (e) => e?.MaximumLeverage
                              )}
                            </p>
                          </div>
                          {/* <div className="slider-content">
                        <h6 className="text-center">Investor Protection</h6>
                        <p className="text-center  option-para">
                          {e?.safety.map((e) =>
                            e?.Investorprotection === "yes" ? (
                              <BsCheck2Circle className="font-32"
                                style={{  color: "green" }}
                              />
                            ) : (
                              <SlClose className="font-32"
                                style={{  color: "red" }}
                              />
                            )
                          )}
                        </p>
                      </div> */}
                          <div className="slider-content">
                            <h6 className="text-center">Inactivity Fee</h6>
                            <p className="text-center  option-para">
                              {e?.nontradingfees.map((e) =>
                                e?.InactiveFee > 0 ? (
                                  <BsCheck2Circle
                                    className="font-32"
                                    style={{ color: "red" }}
                                  />
                                ) : (
                                  <SlClose
                                    className="font-32"
                                    style={{ color: "green" }}
                                  />
                                )
                              )}
                            </p>
                          </div>
                          <div className="slider-content">
                            <h6 className="text-center">Islamic Account</h6>
                            <p className="text-center  option-para">
                              {e?.nontradingfees.map((e) =>
                                e?.IslamicAccount === "yes" ? (
                                  <BsCheck2Circle
                                    className="font-32"
                                    style={{ color: "green" }}
                                  />
                                ) : (
                                  <SlClose
                                    className="font-32"
                                    style={{ color: "red" }}
                                  />
                                )
                              )}
                            </p>
                          </div>
                          <div className="slider-content">
                            <h6 className="text-center">MT4 Avaiable</h6>
                            <p className="text-center  option-para">
                              {e?.nontradingfees.map((e) =>
                                e?.MT4 === "yes" ? (
                                  <BsCheck2Circle
                                    className="font-32"
                                    style={{ color: "green" }}
                                  />
                                ) : (
                                  <SlClose
                                    className="font-32"
                                    style={{ color: "red" }}
                                  />
                                )
                              )}
                            </p>
                          </div>
                          <div className="slider-content">
                            <h6 className="text-center">MT5 Avaiable</h6>
                            <p className="text-center  option-para">
                              {e?.nontradingfees.map((e) =>
                                e?.MT5 === "yes" ? (
                                  <BsCheck2Circle
                                    className="font-32"
                                    style={{ color: "green" }}
                                  />
                                ) : (
                                  <SlClose
                                    className="font-32"
                                    style={{ color: "red" }}
                                  />
                                )
                              )}
                            </p>
                          </div>
                          <div className="slider-content">
                            <h6 className="text-center">
                              TradingView Avaiable
                            </h6>
                            <p className="text-center  option-para">
                              {e?.nontradingfees.map((e) =>
                                e?.TradingView === "yes" ? (
                                  <BsCheck2Circle
                                    className="font-32"
                                    style={{ color: "green" }}
                                  />
                                ) : (
                                  <SlClose
                                    className="font-32"
                                    style={{ color: "red" }}
                                  />
                                )
                              )}
                            </p>
                          </div>
                          <div className="slider-content">
                            <h6 className="text-center">cTrader</h6>
                            <p className="text-center  option-para">
                              {e?.nontradingfees.map((e) =>
                                e?.cTrader === "yes" ? (
                                  <BsCheck2Circle
                                    className="font-32"
                                    style={{ color: "green" }}
                                  />
                                ) : (
                                  <SlClose
                                    className="font-32"
                                    style={{ color: "red" }}
                                  />
                                )
                              )}
                            </p>
                          </div>
                          <div className="slider-content">
                            <h6 className="text-center">
                              Capitalise.ai Available
                            </h6>
                            <p className="text-center  option-para">
                              {e?.nontradingfees.map((e) =>
                                e?.capitaliseaiAvailable === "yes" ? (
                                  <BsCheck2Circle
                                    className="font-32"
                                    style={{ color: "red" }}
                                  />
                                ) : (
                                  <SlClose
                                    className="font-32"
                                    style={{ color: "red" }}
                                  />
                                )
                              )}
                            </p>
                          </div>
                          <div className="slider-content">
                            <h6 className="text-center">
                              zulu Trade Available
                            </h6>
                            <p className="text-center  option-para">
                              {e?.nontradingfees.map((e) =>
                                e?.zuluTradeAvaiable === "yes" ? (
                                  <BsCheck2Circle
                                    className="font-32"
                                    style={{ color: "green" }}
                                  />
                                ) : (
                                  <SlClose
                                    className="font-32"
                                    style={{ color: "red" }}
                                  />
                                )
                              )}
                            </p>
                          </div>
                          <div className="slider-content">
                            <h6 className="text-center">
                              Copy Trading Available
                            </h6>
                            <p className="text-center  option-para">
                              {e?.nontradingfees.map((e) =>
                                e?.copyTradingAvailable === "yes" ? (
                                  <BsCheck2Circle
                                    className="font-32"
                                    style={{ color: "green" }}
                                  />
                                ) : (
                                  <SlClose
                                    className="font-32"
                                    style={{ color: "red" }}
                                  />
                                )
                              )}
                            </p>
                          </div>
                          <div className="slider-content">
                            <h6 className="text-center">Autochartist Access</h6>
                            <p className="text-center  option-para">
                              {e?.nontradingfees.map((e) =>
                                e?.AutochartistAccess === "yes" ? (
                                  <BsCheck2Circle
                                    className="font-32"
                                    style={{ color: "green" }}
                                  />
                                ) : (
                                  <SlClose
                                    className="font-32"
                                    style={{ color: "red" }}
                                  />
                                )
                              )}
                            </p>
                          </div>
                          <div className="slider-content">
                            <h6 className="text-center">
                              {" "}
                              Trading Centeral Access
                            </h6>
                            <p className="text-center  option-para">
                              {e?.nontradingfees.map((e) =>
                                e?.TradingCenteralAccess === "yes" ? (
                                  <BsCheck2Circle
                                    className="font-32"
                                    style={{ color: "green" }}
                                  />
                                ) : (
                                  <SlClose
                                    className="font-32"
                                    style={{ color: "red" }}
                                  />
                                )
                              )}
                            </p>
                            <div
                              className="hint"
                              style={{ textAlign: "center" }}
                            >
                              <a href="#" className="forhover">
                                {" "}
                                <span
                                  style={{
                                    borderBottom: "1px solid #0a2cd4",
                                    textAlign: "center",
                                  }}
                                >
                                  Read review
                                </span>{" "}
                              </a>
                              <div className="link-btn py-3">
                                <a href="#" className="theme-btn btn-style-two">
                                  <span className="btn-title Mobilebtn">
                                    VISIT BROKER
                                  </span>
                                </a>
                              </div>
                              <p
                                className="tradepara py-1 mt-2 mb-0"
                                style={{ textAlign: "center" }}
                              >
                                {`${e?.lose}% of retail CFD accounts lose money`}
                              </p>
                            </div>
                          </div>
                          {/*    <div className='d-flex flex-column align-items-center justify-content-center'>
            <h6 className="font-h6">EURUSDspread</h6>

            <p className="text-center  option-para">
        

              {data?.tradingfees.map((e) => e?.EURUSDspread)}
            </p>
            </div>
            <div className='d-flex flex-column align-items-center justify-content-center'>
            <h6 className="font-h6">USstockindexoptions</h6>
            <p className="text-center  option-para">
              {data?.tradingfees.map((e) => e?.USstockindexoptions)}
            </p>
            </div> */}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </section>
          <div className={`${isfixed ? "position-fixed" : "makeitinvisible"}`}>
            <Swiper
              // ref={swiperRef2}
              slidesPerView={3}
              spaceBetween={10}
              breakpoints={{
                300: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
              }}
              navigation={true}
              allowTouchMove={false}
              className="mySwiper "
            >
              {console.log("activeSlidesData----->", activeSlidesData)}
              {activeSlidesData?.length === 0 && activeSlidesData2 === null
                ? selectedBrokers.slice(0, 3)?.map((e, i) => (
                    <SwiperSlide key={i}>
                      <div className="slide-wrap">
                        <div className="d-flex flex-column align-items-center">
                          <span
                            onClick={() => window.open(`${e?.url}`, "_blank")}
                            className="cursor-pointer"
                          >
                            <img
                              src={e?.img}
                              width={"40"}
                              height={"40"}
                              className="rounded-2 shadow-lg"
                              alt=""
                            />
                          </span>
                          <h6 className="text-center py-1">{e?.brokername}</h6>

                          <div></div>
                          <div className="link-btn">
                            <span
                              onClick={() => window.open(`${e?.url}`, "_blank")}
                              className="theme-btn btn-style-two cursor-pointer"
                            >
                              <span className="btn-title Mobilebtn">
                                VISIT BROKER{" "}
                                <LuExternalLink
                                  size="22"
                                  className="mb-none"
                                  style={{ paddingBottom: "5px" }}
                                />
                              </span>
                            </span>
                          </div>
                          <div className="hint">
                            <p className="tradepara py-1 mt-2 mb-0">
                              {`${e?.lose}% of retail CFD accounts lose money `}
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))
                : activeSlidesData2 === null
                ? activeSlidesData?.map((e, i) => (
                    <SwiperSlide key={i}>
                      <div className="slide-wrap">
                        <div className="d-flex flex-column align-items-center">
                          <span
                            onClick={() => window.open(`${e?.url}`, "_blank")}
                            className="cursor-pointer"
                          >
                            <img
                              src={e?.img}
                              width={"40"}
                              height={"40"}
                              className="rounded-2 shadow-lg"
                              alt=""
                            />
                          </span>
                          <h6 className="text-center py-1">{e?.brokername}</h6>

                          <div></div>
                          <div className="link-btn">
                            <span
                              onClick={() => window.open(`${e?.url}`, "_blank")}
                              className="theme-btn btn-style-two cursor-pointer"
                            >
                              <span className="btn-title Mobilebtn">
                                VISIT BROKER{" "}
                                <LuExternalLink
                                  size="22"
                                  className="mb-none"
                                  style={{ paddingBottom: "5px" }}
                                />
                              </span>
                            </span>
                          </div>
                          <div className="hint">
                            <p className="tradepara py-1 mt-2 mb-0">
                              {`${e?.lose}% of retail CFD accounts lose money `}
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))
                : activeSlidesData2?.map((e, i) => (
                    <SwiperSlide key={i}>
                      <div className="slide-wrap">
                        <div className="d-flex flex-column align-items-center">
                          <a href={e?.url} target="_blank">
                            <img
                              src={e?.img}
                              width={"40"}
                              height={"40"}
                              className="rounded-2 shadow-lg"
                              alt=""
                            />
                          </a>
                          <h6 className="text-center py-1">{e?.brokername}</h6>

                          <div></div>
                          <div className="link-btn">
                            <a
                              href={e?.url}
                              target="_blank"
                              className="theme-btn btn-style-two"
                            >
                              <span
                                className="btn-title Mobilebtn"
                                style={{
                                  padding: "3px 7px",
                                }}
                              >
                                VISIT BROKER
                                <AiOutlineArrowRight className="mb-none" />
                              </span>
                            </a>
                          </div>
                          <div className="hint">
                            <p className="tradepara py-1 mt-2 mb-0">
                              {`${e?.lose}% of retail CFD accounts lose money second`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
            </Swiper>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default NewCompareBroker;
