import React, { useEffect } from 'react'
import Header from '../components/Header'
import Banner from '../components/Banner'
import HomeTools from '../components/HomeTools'
import HighestRatedBroker from '../components/HighestRatedBroker'
import Footer from '../components/Footer'
import BrokerTable from '../components/BrokerTable'
import Aboutus from '../components/Aboutus'

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Header />
      <Banner />
      <HomeTools />
      <HighestRatedBroker />
      <BrokerTable />
      <Aboutus />
      <Footer />
    </div>
  )
}

export default Home
