import React from "react";
import Header from "../components/Header";
import Center from "../components/Center";
import Centerone from "../components/Center_one";
import Footer from "../components/Footer";

function Newpage() {
  return (
    <>
      <Header />
      <Center />
      <Centerone
       

      />
      <Footer />
    </>
  );
}

export default Newpage;
