/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { LuExternalLink } from 'react-icons/lu'
import { TiTick } from 'react-icons/ti'
import active from '../assets/img/brokerlogoclient/xtb-review.png'
// import { TiTick } from "react-icons/ti";
// import { LuExternalLink } from "react-icons/lu";

const Xtb_review = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const minRating = 0.1;
    const maxRating = 5.0;
    return (
        <>
            <Header />
            <div className="container py-4 px-4">
                <div className='col-lg-10 col-md-9 offset-md-1'>
                    <h3 className='  broker-name'>XTB review</h3>
                    <div className="row  pt-5 pb-5 text-center">
                        <div

                            className="col-lg-6 col-md-8 col-sm-12 offset-md-3  pricing-block style-two animated fadeInUp "
                            data-wow-delay="200ms"
                            data-wow-duration="1200ms"
                        >
                            <div
                                className="inner-box1 py-4 px-4"

                            >

                                <h4 style={{ marginTop: "5px" }}>
                                    <a href='' target="_blank">
                                        <img
                                            src={active}
                                            width={"100"}
                                            height={"100"}
                                            alt=""
                                            srcset=""
                                            className="mb-2 rounded-4"
                                            style={{
                                                boxShadow: "5px 5px 10px rgba(0,0,0,.23)"
                                              }}
                                        />
                                    </a>
                                </h4>
                                <div className="d-flex py-1  justify-content-center  align-items-center  flex-reverse ">

                                    <div className=" d-flex justify-content-center">
                                        <div class="ratings">
                                            <div class="empty-stars"></div>
                                            <div
                                                className="full-stars"
                                                style={{
                                                    width: `${((5 - minRating) /
                                                        (maxRating - minRating)) *
                                                        100
                                                        }%`,
                                                }}
                                            ></div>
                                        </div>
                                        &nbsp;&nbsp;
                                        <ul className="list-unstyled ptusa"><li> 4.9</li></ul>
                                    </div>
                                </div>

                                <div>
                                    <div
                                        className={`py-0`}>
                                        <div className="grid grid-cols-12 text-sm">
                                            <div className="text-left lh-lg">
                                              <ul className='list-unstyled'>
                                              <li>
                                                    <TiTick />
                                                    <strong> Min. Deposit: </strong> $50
                                                </li>

                                                <li className='fs-6'> <TiTick /><strong>Max. Leverage: </strong>50:1</li>
                                                <li className='fs-6'> <TiTick /><strong>Headquarter: </strong> London, UK</li>
                                                <li className='fs-6'> <TiTick /><strong>Products (CFD):</strong> Forex, Indices, Shares, Commodities, Cryptocurrencies, Bonds, ETFs, Options </li>
                                              </ul>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="py-1  my-1 btn-style-two1">
                                    <a href='' target="_blank" className=" w-100 text-align-center text-light">

                                        <span className="">Visit IG {' '}</span>
                                        <LuExternalLink color='#fff' size='25' style={{ paddingBottom: '5px' }} />
                                    </a>
                                </div>

                                <div className="hint">

                                    <p className="tradepara1 py-2">
                                        76% of retail CFD accounts lose money
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row   text-center">
                        <div

                            className="col-lg-6 col-md-8 col-sm-12 offset-md-3  pricing-block style-two animated fadeInUp "
                            data-wow-delay="200ms"
                            data-wow-duration="1200ms"
                        >

                        </div>
                    </div>



                    <div className="row lg-pt-5 lg-px-5">
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>Founded in 2002 as Poland’s first leveraged foreign exchange brokerage house, X-Trade morphed into X-Trade Brokers in 2004 to comply with new Polish regulations. It then rebranded to its current incarnation, XTB Online Trading (XTB), in 2009 and went public, listing on the Warsaw Stock Exchange in 2016 under the ticker symbol XTB.</p>
                        </div>
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>XTB has been steadily making inroads into the highly competitive and ever-changing online brokerage landscape and currently provides access to a variety of markets such as forex, shares, indices, metals, commodities and even cryptocurrencies. The company is regulated in the U.K. and registered with the Financial Conduct Authority (FCA #522157) as well as the Polish Financial Supervision Authority (KNF). Like many forex brokers, XTB does not accept U.S. traders.</p>
                        </div>
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>XTB’s flagship platform is a web-based offering named “xStation 5.” While ancillary costs were on par with industry standards, XTB’s consistently lower spreads were hard to ignore as they clearly separated it from the competition. Investopedia’s ranking algorithm factored this in when recognizing XTB’s appeal to cost-conscious traders.</p>
                        </div>
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'><i> <strong>Disclaimer: </strong>(CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. <strong>Between 81% of retail investor accounts lose money when trading CFDs. </strong>You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money)</i></p>
                        </div>



                        <div className="col-lg-12 py-5">
                            <div className='row'>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <h5 className='pb-1 font-bold'>Pros</h5>
                                    <ul className='lh-lg' style={{ listStyle: "none" }}>
                                        <li>✅ Low FX spreads
                                        </li>
                                        <li>✅ Good customer service
                                        </li>
                                        <li>✅ Free and fast deposit and withdrawal
                                        </li>
                                    </ul>


                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <h5 className='pb-1 font-bold'>Cons</h5>
                                    <ul className='lh-lg' style={{ listStyle: "none" }}>
                                        <li>❌No guaranteed stop loss
                                        </li>
                                        <li>❌Inactivity fee charged
                                        </li>
                                        <li>❌Withdrawal fee

                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <h2 className='pt-4 pb-4 font-bold'>Costs</h2>
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>The totality of XTB’s fee and cost structure is competitive within the online forex brokerage arena. What sets XTB apart is its advertised minimum FX spreads for the different account structures. XTB’s “Standard” account quotes a minimum spread of 0.5. In retail FX parlance, it is generally understood that one standard lot is 100,000 units of the base currency, so this would translate to a minimum spread of 0.45, still among the best in the industry.

                            </p>
                        </div>
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>Standard account customers enjoyed average spread of 0.9 on EUR/USD for the last quarter, along with zero commissions on stock CFD transactions. XTB does, however, charge an inactivity fee of €10 if there is “no opening or closing of the position on the Customer’s account within the last 365 days and no cash deposit within the last 90 days.” There is also a 12£ fee for client withdrawals of less than 60£ from their cash account to a bank account. At the same time, though, there are no costs for incoming or outgoing wire transfers or for closing an account.</p>
                        </div>
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>XTB does not have a minimum deposit requirement for customers wishing to open a live account, but logic dictates that this amount will be subject to the margin requirements of the smallest trade size that the customer wishes to place. International and professional U.K. clients with high account balances are eligible for cashback rebates and welcome bonuses.</p>
                        </div>


                        <h2 className='pt-4 pb-4 font-bold'>Trade Experience</h2>
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>xStation 5, XTB’s custom trading application, is a streamlined trading platform that is stable and easy to access from any browser (Chrome, Firefox, Safari, or Internet Explorer). User interface is fairly standard with a simplicity that enhances its appeal. It isn’t the most feature-rich platform, but its ease of use somehow makes it appear more functional than it actually is..</p>
                        </div>
                        <div className="col-lg-12">

                            <p className='lh-base fs-6'>This is the company’s flagship offering and provides an updated look and feel, one-click trading, and full integration across desktop and mobile platforms that should serve traders well. Entering trades, building watchlists and modifying charts all seem simple and intuitive. FAQs and educational resources can also be launched from within the trading application. Traders receive notifications directly on the platform or mobile application. That being said, there were downsides. The ability to create custom indicators was not an option.</p>
                        </div>
                        <div className="col-lg-12">

                            <p className='lh-base fs-6'>xStation 5 has three basic order types with the option of attaching “take profit” and/or “stop loss” orders to them. Additionally, the trader can choose to make the regular stop loss a trailing stop loss prior to, or after, execution of a trade.</p>
                        </div>
                       
                       <div className="col-lg-12">
                       <ul>
                            <li><strong>Market </strong>– The simplest order where a trader signals that their trade request should be executed at the prevailing market rate.</li>
                            <li><strong>Limit </strong>– A pending order where the entry is at a predetermined point below or above the prevailing market rate depending on whether it’s a buy or sell. The trader also has the option of selecting the expiration time of this order.</li>
                            <li><strong>Stop </strong>– A pending order where the entry is at a predetermined point above or below the prevailing market rate depending on whether it’s a buy or sell. The trader also has the option of selecting the expiration time of this order.
                            </li>
                        </ul>
                       </div>

                        <h2 className='pt-4 pb-4 font-bold'>Markets and products</h2>
                        <div className="col-lg-12"><p>XTB provides access to a broad selection of financial instruments across a wide range of different asset classes. The product catalog includes:</p>

                            <ul className='lh-lg'>
                                <li>48 currency pairs
                                </li>
                                <li>Over 30 global indexes
                                </li>
                                <li>Major commodities
                                </li>
                                <li>More than 1,850 global stock CFDs
                                </li>
                                <li>155 ETF and cryptocurrency CFDs
                                </li>

                            </ul>
                            <div className="col-lg-12">

                                <p className='lh-base fs-6'>Where the range of offerings does fall short is the lack of spread betting instruments. XTB offers maximum leverage of up to 500:1 for non-U.K and UAE accounts while U.K and UAE accounts get up to 30:1 leverage.</p>
                            </div>
                            <h2 className='pt-4 pb-4 font-bold'>Customer Service
                            </h2>
                            <div className="col-lg-12">

                                <p className='lh-base fs-6'>In this day and age, an online broker that does not place a high priority on addressing the needs of its customer in an efficient and satisfactory manner may be doomed to failure. XTB is well aware of this. Contact options cover all the bases, with email for clients and prospective clients, 24/5 local phone support, and available 24/7 live chat. Additionally, clients are assigned an account manager to assist them with relevant account-related matters.</p>
                            </div>
                            <div className="col-lg-12">

                                <p className='lh-base fs-6'>Phone support is responsive but the chat experience is a bit more erratic. It isn’t uncommon, for example, for live chat requests to be redirected to an email form instead. In the end, though, functional support is rendered. A phone number is easily available on xStation 5 and chat support can be launched from the trading platform, which is very convenient. Online customer support is available in several languages, which enhances XTB’s appeal to a broader client base. The broker also runs satellite offices to support licensed operations in several regions.</p>
                            </div>
                        </div>





                        <h2 className='pt-4 pb-4 font-bold'>Research
                        </h2>
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>XTB’s research amenities are above industry standard. Aside from a standard economic calendar that is intuitive and filterable, XTB offers a functional news feed that can be filtered by “instrument,” “trading signal,” “economic reports” (fundamental), and “technical analysis.”</p>
                        </div>
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>Additionally, there is a market analysis tab that is comprised of filterable “market sentiment,” “top movers,” “stock scanner,” and “heat map” sections. This is, by far, the most impressive aspect of XTB’s analytical tool offerings. Each section is well designed and includes some actionable, or at the very least interesting, information that can add value for traders.</p>
                        </div>
                        <h2 className='pt-4 pb-4 font-bold'>Security
                        </h2>
                        <div className="col-lg-12">

                            <ul>
                                <li>XTB Limited is authorised and regulated by the Financial Conduct Authority in United Kingdom
                                </li>
                                <li>XTB Sucursal is authorised and regulated by the Comisión Nacional del Mercado de Valores in Spain
                                </li>
                                <li>XTB S.A. is authorised and regulated by the Komisja Nadzoru Finansowego (KNF) in Poland
                                </li>
                                <li>XTB MENA Limited (“XTB”) is a company incorporated in the Dubai International Financial Centre (“DIFC”), Unit 1204, Index Tower, Happiness Street, DIFC, Dubai, United Arab Emirates and regulated by the Dubai Financial Services Authority (“DFSA”) under reference No. F006316. </li>
                               
                            </ul>

                        </div>

                        <h2 className='pt-4 pb-4 font-bold'>Final Thoughts</h2>

                        <p className='lh-base fs-6'>XTB is a great CFD broker. It is regulated by several financial authorities globally, including the UK’s FCA. The company is listed on the Warsaw Stock Exchange. On the plus side, many European clients can enjoy commission-free stock and ETF trading up to a €100,000 monthly trade volume. We also liked that the deposit and withdrawal process is fast and mostly free. XTB offers interest for uninvested cash. The account opening process is seamless, hassle-free and quick. XTB has some drawbacks, though. The product range is mainly limited to CFDs, although real stock and ETF trading is available in some European countries. Lastly, there is an inactivity fee charged. We recommend XTB for clients who prefer forex or CFD trading and also want to enjoy great deposit and withdrawal options along with fast account opening. Feel free to test it using a demo account.
                        </p>


                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Xtb_review 