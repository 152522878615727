/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import cfd from "./../assets/img/cfd.jpg";
import leverge from "./../assets/img/leverage.jpg";
import forextrading from "./../assets/img/forextrading.jpg";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";

const Aboutus = () => {
  // const navigate = useNavigate();
  useEffect(()=>{
    window.scrollTo(0,0)
      },[])
  return (
    <section className="about">
      <div className="container py-5">
        <div className="row world" >
          <div className="col-md-4  d-flex flex-column gap-2 mt-3">
            <div className="bg-box ">
              <div className="image-container d-flex justify-content-center py-2">
                <img
                  src={forextrading}
                  width={"250px"}
                  height={"200px"}
                  alt=""
                />
              </div>
              <h4 className="text-center py-2">What is forex trading?</h4>
              <p className="text-center py-2" style={{
    fontSize: "15px",
    fontWeight: "300",
    color:"#000",
    lineHeight: "29px",
}}>
                Foreign exchange, or forex, traders speculate on changing
                exchange rates by converting large sums of money from currency
                to currency, much like stock traders buy and sell different
                stocks. Forex traders essentially attempt to buy low and sell
                high for a profit, but the asset they are trading is currency.{" "}
              </p>
              <div className="link-btn d-flex justify-content-center rounded-0">
                <Link to='/whatisforex' className="theme-btn faiz rounded-0">
                  <span className="faiz1">
                    LEARN MORE
                    <AiOutlineArrowRight />
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4  d-flex flex-column gap-2 mt-3">
            <div className="bg-box">
              <div className="image-container d-flex justify-content-center py-2">
                <img src={cfd} width={"250px"} height={"200px"} alt="" />
              </div>
              <h4 className="text-center py-2">What is CFD?</h4>
              <p className="text-center py-2" style={{
    fontSize: "15px",
    fontWeight: "300",
    color:"#000",
    lineHeight: "29px",
}}>
                A contract for difference (CFD) allows traders to speculate on
                the future market movements of an underlying asset, without
                actually owning or taking physical delivery of the underlying
                asset. CFDs are available for a range of underlying assets, such
                as shares, commodities, and foreign exchange.{" "}
              </p>
              <div className="link-btn d-flex justify-content-center rounded-0">
                <Link to="/what-is-cdf" className="theme-btn faiz rounded-0">
                  <span className="faiz1">
                    LEARN MORE
                    <AiOutlineArrowRight />
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4  d-flex flex-column gap-2 mt-3">
            <div className="bg-box">
              <div className="image-container d-flex justify-content-center py-2">
                <img src={leverge} width={"250px"} height={"200px"} alt="" />
              </div>
              <h4 className="text-center py-2">What is leverage?</h4>
              <p className="text-center py-2" style={{
    fontSize: "15px",
    fontWeight: "300",
    color:"#000",
    lineHeight: "29px",
}}>
                Major players in this market tend to be financial institutions
                like commercial banks, central banks, money managers and hedge
                funds. Global corporations use forex markets to hedge currency
                risk from foreign transactions.{" "}
              </p>
              <div className="link-btn d-flex justify-content-center pt-4 rounded-0">
                <Link to="/what-is-leverage" className="theme-btn faiz rounded-0">
                  <span className="faiz1" >
                    LEARN MORE
                    <AiOutlineArrowRight />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Aboutus;
