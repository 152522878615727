/* eslint-disable */
import React, { useEffect, useState } from "react";
import { LuExternalLink } from "react-icons/lu";
import plus500 from "./../assets/img/Plus500.svg";
import IG from "./../assets/img/IG.png";
import Pepperstone from "./../assets/img/Pepperstone.png";
import City from "./../assets/img/City Index.png";
import { Link } from "react-router-dom";
import Instance from "../utils/Interceptor";

function CenteroneUk() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchBrokerData = async () => {
    // setLoading(true);
    try {
      setLoading(true);
      const response = await Instance.get("api/uk-broker-point");
      // const Fee = response.data.data.slice(0, 41);
      setData(response?.data?.data);
      if (response.data.status === true) {
        setLoading(false);
        window.scrollTo(0, 0);
      } else {
        setLoading(true);
      }
    } catch (error) {
      console.error("Error fetching broker data:", error);
      setLoading(true);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchBrokerData();
    // scrollTo(0,0)
  }, []);
  return (
    <div className="">
      {/* <h1 className=" fw-bold pt-2 py-3 text-center">
      Best Forex Brokers United Arab Emirates
      </h1> */}
      <div className="container" style={{ paddingLeft: "115px" }}>
        <p className="text-justify container">
          To find the{" "}
          {/* <a href="https://www.forexbrokers.com/guides/forex-trading"> */}{" "}
          Best forex brokers {/* </a>{" "} */}
          in the UK, we created a list of all FCA authorised brokers, then
          ranked brokers by their Overall ranking.
        </p>
      </div>
      <div className="container" style={{ paddingLeft: "115px" }}>
        <p className="text-justify container">
          Here is our list of the best forex brokers in the United Arab
          Emirates:
        </p>
      </div>
      {data?.map((e, i) => {
        return (
          <div className="d-flex flex-col justify-content-center cus-cone">
            <div className="d-flex align-items-center justify-content-between calculate-broker  my-3 row col-lg-12 ">
              <div className="col-lg-2 col-md-12 col-sm-12">
                <div className="broker-profile d-flex align-items-start justify-content-start p-5 resFee">
                  <img
                    className=""
                    src={e?.image}
                    alt=""
                    width="77"
                    height="77"
                    style={{
                      borderRadius: "10px",
                      cursor: "pointer",
                      boxShadow: "5px 5px 10px rgba(0,0,0,.23)",
                    }}
                  />
                  <div className="px-3 m">
                    <h6 className="broker-profile-name-1 center fw-bold fs-6">
                      {""}
                    </h6>
                    <h6 className="broker-profile-name-2 center fw-bold fs-6 para-color type">
                      {""}
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-12 ">
                <div className="broker-spread text-start new">
                  <p className="para-color p-0 m-2 bottum">General Info</p>
                  {e?.levels.slice(0, 4)?.map((e, i) => {
                    return <li>{e?.point}</li>;
                  })}
                  {/* <li>Min Deposit: $100</li>
                  <li>Max Leverage: 30:1</li>
                  <li>Platforms: Plus500 app, Webtrader</li>
                  <li>Inactivity Fee: Yes</li> */}
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-12">
                <div className="broker-comission text-start new1">
                  <p className="para-color p-0 m-2 bottum1">Average Spread</p>
                  {e?.levels.slice(4, 8)?.map((e, i) => {
                    return <li>{e?.point}</li>;
                  })}
                  {/* <li>EURUSD: 1.3</li>
                  <li>USDJPY: 1.7</li>
                  <li>GBPUSD: 2.0</li>
                  <li>GOLD: 0.3</li> */}
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-center">
                <div className="broker-percentage text-center p-2">
                  <div className="link-btn pb-3">
                    <div className="theme-btn btn-style-two">
                      <span
                        className="btn-title"
                        onClick={() => window.open(e?.url, "_blank")}
                        style={{
                          padding: "3px 7px",
                          cursor: "pointer",
                        }}
                      >
                        VISIT BROKER{" "}
                        <LuExternalLink
                          size="22"
                          style={{ paddingBottom: "5px" }}
                        />{" "}
                      </span>
                    </div>
                  </div>
                  <div className="hint ">
                    <Link to={e?.path} className="forhover ">
                      {" "}
                      <span style={{ borderBottom: "1px solid #0a2cd4" }}>
                        Read review
                      </span>{" "}
                    </Link>
                    {/* <p className="tradepara py-1 mt-2 mb-0">
                      {"79"}% of retail CFD accounts lose money
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div className="container" style={{ paddingLeft: "115px" }}>
        <h1 className=" fw-bold pt-2 py-3 text-center">
          How can I trade forex in the U.K.?
        </h1>
        <p className="text-justify container">
          First, select a trustworthy U.K. forex broker regulated by the
          <a href="https://www.fca.org.uk/">
            {" "}
            Financial Conduct Authority (FCA).
          </a>{" "}
          Next, open and fund your new brokerage account, and fill out the order
          ticket to place a trade. Most online brokers offer demo accounts so
          you can practice with virtual currency and become familiar with how
          the trading platform works before funding your account with real
          money.
        </p>
        <p className="text-justify container">
          <b>More details:</b> Choosing an FCA-regulated broker will ensure that
          you are entitled to any applicable legal protections. To be
          FCA-regulated in the U.K., brokers must comply with a strict{" "}
          <a href="https://www.fca.org.uk/about/how-we-authorise">
            list of requirements.
          </a>
        </p>
        <h1 className=" fw-bold pt-2 py-3 text-center">
          How to verify FCA Authorisation
        </h1>
        <p className="text-justify container">
          To verify if a forex broker is licensed to operate in the United
          Kingdom (U.K.), the first step is to identify the register number from
          the disclosure text at the bottom of the broker’s U.K. homepage. For
          example, here’s the key disclosure text from IG’s website:
        </p>
        <p className="text-justify container">
          <i>
            Both IG Markets Ltd (Register number 195355) and IG Index Ltd
            (Register number 114059) are authorised and regulated by the
            Financial Conduct Authority.”
          </i>
        </p>
        <p className="text-justify container">
          Next, look up the firm on the FCA website to validate the register
          number is, in fact, legitimate. Here is the official FCA page for{" "}
          <a href="https://register.fca.org.uk/ShPo_FirmDetailsPage?id=001b000000MfIiSAAV">
            IG Markets Limited.
          </a>
        </p>
        <h1 className=" fw-bold pt-2 py-3 text-justify container">
          Why is the Financial Conduct Authority (FCA) important for forex
          traders in the UK?
        </h1>
        <p className="text-justify container">
          The{" "}
          <a href="https://www.fca.org.uk/">
            {" "}
            Financial Conduct Authority (FCA)
          </a>{" "}
          regulates U.K.-based brokers that legally offer forex and other
          derivatives to retail and professional traders. According to the
          regulator’s official website, the FCA regulates the conduct of 50,000
          firms across the U.K. and prudentially supervises 48,000 firms.
        </p>
        <p className="text-justify container">
          The FCA aims to enforce compliance and ensure that firms are following
          the rules and regulations set forth in the{" "}
          <a href="https://www.handbook.fca.org.uk/"> FCA handbook. </a> In
          extraordinary circumstances, the FCA can take emergency action and
          prevent brokers from opening new trades for customers, or suspend (or
          even revoke) a broker’s license if there have been substantial
          compliance failures and/or violations.
        </p>
        <h1 className=" fw-bold pt-2 py-3 text-center">
          Is forex trading legal in the UK?
        </h1>
        <p className="text-justify container">
          Yes, forex trading is legal in the U.K. and regulated by the{" "}
          <a href="https://www.fca.org.uk/">
            {" "}
            Financial Conduct Authority (FCA).{" "}
          </a>{" "}
          The FCA requires that brokers obtain proper licensing and
          authorization in order to legally offer forex trading to clients.
        </p>
        <p className="text-justify container">
          <b>FCA-regulated brokers FCA-regulated brokers:</b> Per the{" "}
          <a href="https://www.handbook.fca.org.uk/handbook/PERG/2/7.html">
            {" "}
            FCA’s handbook,{" "}
          </a>{" "}
          forex is a regulated activity. As such, brokers that handle customer
          deposits and arrange and/or deal in investments – whether acting as
          the principal or agent – must hold proper authorization from the FCA.
        </p>
        <p className="text-justify container">
          <b> Deposit insurance: </b> FCA-regulated forex brokers also provide
          certain compensation rights to retail customers in the extraordinary
          event of insolvency or bankruptcy. The UK’s{" "}
          <a href="https://www.fscs.org.uk/what-we-cover/?gclid=Cj0KCQiAq5meBhCyARIsAJrtdr6iTruNjl_NW1C85UHeFnxbYuJ_df4rizhzsHEYNPOysVc0aj_XnGIaAhs-EALw_wcB">
            {" "}
            Financial Services Compensation Scheme{" "}
          </a>{" "}
          (FSCS) provides up to 85,000 GBP of protection per eligible person.
        </p>
        <p className="text-justify container">
          <b> Professional clients: </b> It’s important to note that U.K.
          clients that have been designated as professional traders do not
          receive any such protections from the FSCS.
        </p>
      </div>
    </div>
  );
}

export default CenteroneUk;
