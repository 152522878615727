/* eslint-disable */
import React, { useEffect, useState } from "react";
import { LuExternalLink } from "react-icons/lu";
import plus500 from "./../assets/img/Plus500.svg";
import IG from "./../assets/img/IG.png";
import Pepperstone from "./../assets/img/Pepperstone.png";
import City from "./../assets/img/City Index.png";
import { Link } from "react-router-dom";
import Instance from "../utils/Interceptor";

function CenteroneUae() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchBrokerData = async () => {
    // setLoading(true);
    try {
      setLoading(true);
      const response = await Instance.get("api/uae-broker-point");
      // const Fee = response.data.data.slice(0, 41);
      setData(response?.data?.data);
      if (response.data.status === true) {
        setLoading(false);
        window.scrollTo(0, 0);
      } else {
        setLoading(true);
      }
    } catch (error) {
      console.error("Error fetching broker data:", error);
      setLoading(true);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchBrokerData();
    // scrollTo(0,0)
  }, []);
  return (
    <div className="">
      <h1 className=" fw-bold pt-2 py-3 px-5 text-center">
        Best Forex Brokers United Arab Emirates
      </h1>
      <div className="container" style={{paddingLeft:"80px"}}>

      <p className="text-justify container px-5">
        To find the best forex brokers in the United Arab Emirates, we created a
        list of all brokers that accept residents of the United Arab Emirates as
        new clients. We then ranked brokers by their Overall ranking.
      </p>
      </div>
      <div className="container" style={{paddingLeft:"115px"}}>
      <p className="text-justify container">
        Here is our list of the best forex brokers in the United Arab Emirates:
      </p>
      </div>

      {data?.map((e, i) => {
        return (
          <div className="d-flex flex-col justify-content-center cus-cone">
            <div className="d-flex align-items-center justify-content-between calculate-broker  my-3 row col-lg-12 ">
              <div className="col-lg-2 col-md-12 col-sm-12">
                <div className="broker-profile d-flex align-items-start justify-content-start p-5 resFee">
                  <img
                    className=""
                    src={e?.image}
                    alt=""
                    width="77"
                    height="77"
                    style={{ borderRadius: "10px", cursor: "pointer",boxShadow:"5px 5px 10px rgba(0,0,0,.23)" }}
                  />
                  <div className="px-3 m">
                    <h6 className="broker-profile-name-1 center fw-bold fs-6">
                      {""}
                    </h6>
                    <h6 className="broker-profile-name-2 center fw-bold fs-6 para-color type">
                      {""}
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-12 ">
                <div className="broker-spread text-start new">
                  <p className="para-color p-0 m-2 bottum">General Info</p>
                  {e?.levels.slice(0, 4)?.map((e, i) => {
                    return <li>{e?.point}</li>;
                  })}
                  {/* <li>Min Deposit: $100</li>
                  <li>Max Leverage: 30:1</li>
                  <li>Platforms: Plus500 app, Webtrader</li>
                  <li>Inactivity Fee: Yes</li> */}
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-12">
                <div className="broker-comission text-start new1">
                  <p className="para-color p-0 m-2 bottum1">Average Spread</p>
                  {e?.levels.slice(4, 8)?.map((e, i) => {
                    return <li>{e?.point}</li>;
                  })}
                  {/* <li>EURUSD: 1.3</li>
                  <li>USDJPY: 1.7</li>
                  <li>GBPUSD: 2.0</li>
                  <li>GOLD: 0.3</li> */}
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-center">
                <div className="broker-percentage text-center p-2">
                  <div className="link-btn pb-3">
                    <div className="theme-btn btn-style-two">
                      <span
                        className="btn-title"
                        onClick={() => window.open(e?.url, "_blank")}
                        style={{
                          padding: "3px 7px",
                          cursor: "pointer",
                        }}
                      >
                        VISIT BROKER{" "}
                        <LuExternalLink
                          size="22"
                          style={{ paddingBottom: "5px" }}
                        />{" "}
                      </span>
                    </div>
                  </div>
                  <div className="hint ">
                    <Link to={e?.path} className="forhover ">
                      {" "}
                      <span style={{ borderBottom: "1px solid #0a2cd4" }}>
                        Read review
                      </span>{" "}
                    </Link>
                    {/* <p className="tradepara py-1 mt-2 mb-0">
                      {"79"}% of retail CFD accounts lose money
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div className="container" style={{paddingLeft:"80px"}}>
        <h1 className=" fw-bold pt-2 py-3 text-center">
          What protection does the SCA provide forex traders?
        </h1>
        <p className="text-justify container">
          Forex traders in the UAE benefit from a variety of{" "}
          <a href="https://www.sca.gov.ae/en/home.aspx">
            {" "}
            Securities and Commodities Authority (SCA){" "}
          </a>{" "}
          protections. The SCA has put out numerous{" "}
          <a href="https://www.sca.gov.ae/en/open-data/awareness-campaign-for-combating-unlicensed-financial-activities.aspx">
            warnings for investors{" "}
          </a>{" "}
          to avoid dealing with brokers that aren’t licensed in the UAE, and has
          taken action against regulated brokers in the case of{" "}
          <a href="https://www.sca.gov.ae/en/open-data/violations-and-violators/violations-of-companies-licensed-by-sca.aspx">
            {" "}
            violations.
          </a>{" "}
          The SCA also provides a{" "}
          <a href="https://www.sca.gov.ae/en/services/services-catalogue/submit-complain-related-to-capital-market-transactions.aspx">
            {" "}
            complaints submission
          </a>{" "}
          mechanism for the public in cases where issues and/or disputes can’t
          be resolved directly with an SCA-regulated broker.
        </p>
        <p className="text-justify container">
          The UAE operates under a tri-peak regulatory structure. The SCA (the
          first peak) regulates the financial markets in the United Arab
          Emirates (UAE) that exist outside of the{" "}
          <a href="https://www.difc.ae/">
            {" "}
            Dubai International Financial Centre (DIFC).
          </a>{" "}
          The DIFC is a financial hub in Dubai that is regulated by the{" "}
          <a href="https://www.dfsa.ae/">
            {" "}
            Dubai Financial Services Authority (DFSA)
          </a>{" "}
          (the second peak). The third peak is the{" "}
          <a href="https://www.centralbank.ae/en/">
            {" "}
            Central Bank of the UAE (CBUAE),
          </a>{" "}
          which regulates UAE monetary policy and the banking industry.
        </p>
        <p className="text-justify container">
          The DFSA regulates 617 financial institutions, including forex
          brokers, as of June 2023. The SCA regulates 129 active financial
          institutions including forex brokers that are licensed in the UAE. The
          SCA has been active in its enforcement of compliance with periodic
          inspections of at least 125 financial institutions, including 14
          brokers – according to its{" "}
          <a href="https://www.sca.gov.ae/assets/download/2023/english-report-2021.pdf.aspx">
            {" "}
            latest annual report from 2021.
          </a>
        </p>

        <h1 className=" fw-bold pt-2 py-3 text-center">
          Is forex trading legal in Dubai?
        </h1>
        <p className="text-justify container">
          Yes, if you reside in Dubai, you can legally trade forex with brokers
          that are properly licensed and regulated by the DFSA (according to
          Regulatory Law 2004 as enacted by the ruler of Dubai). Forex brokers
          (and other financial institutions) that operate outside of the{" "}
          <a href="https://www.difc.ae/"> DIFC </a>(i.e. outside of Dubai) are
          regulated by the
          <a href="https://www.sca.gov.ae/en/home.aspx">
            {" "}
            Securities and Commodities Authority (SCA).
          </a>{" "}
          It’s also worth noting that the{" "}
          <a href="https://www.centralbank.ae/en/our-operations/supervision/#!#Payment%20System%20Supervision">
            {" "}
            Central Bank of the UAE
          </a>{" "}
          regulates the foreign exchange markets and supervises banks and
          payment providers. Brokers authorized by the CBUAE hold a{" "}
          <a href="https://www.centralbank.ae/en/financial-institutions/CBUAE%20Register%20and%20Licensed%20Banks%20and%20Other%20Financial%20Institutions%20Ownership">
            {" "}
            monetary intermediary license,{" "}
          </a>{" "}
          whereas exchange-traded forex products are regulated by the Securities
          & Commodities Authority (SCA)
        </p>
        <h1 className=" fw-bold pt-2 py-3 text-center">
          The UAE’S financial markets
        </h1>
        <p className="text-justify container">
          Forex traders may recognize the dirham (AED) as the national currency
          of the United Arab Emirates. Though comprised of seven separate
          emirates, the main financial centers of the UAE are undisputedly Abu
          Dhabi and Dubai. As such, the UAE features two primary financial
          securities exchanges: the{" "}
          <a href="https://www.adx.ae/English/Pages/default.aspx">
            {" "}
            Abu Dhabi Securities Exchange{" "}
          </a>{" "}
          (ADX), and the{" "}
          <a href="https://www.dfm.ae/investors/overview">
            {" "}
            Dubai Financial Market{" "}
          </a>{" "}
          (DFM).
        </p>
        <p className="text-justify container">
          The ADX, based in Abu Dhabi, was established in the year 2000 and
          operates as a market for trading securities while also being vested
          with certain supervisory powers. One of the largest markets in the
          Arab world, the ADX offers shares, bonds, exchange-traded funds
          (ETFs), and other SCA-approved financial instruments. The DFM, based
          in Dubai, functions as a secondary market for trading securities,
          bonds, mutual funds, as well as other local or foreign DFM-approved
          financial instruments.
        </p>
        <p className="text-justify container">
          Excluding the city of Dubai, the{" "}
          <a href="https://www.centralbank.ae/en">
            {" "}
            Central Bank of the UAE (CBUAE){" "}
          </a>{" "}
          is the primary national regulator for all financial services firms in
          the UAE, including banks and forex brokers. The{" "}
          <a href="https://www.sca.gov.ae/">
            {" "}
            Securities and Commodities Authority{" "}
          </a>
          (SCA) is the governmental agency that oversees the local stock and
          commodity exchanges in Dubai.
        </p>
        <p className="text-justify container">
          Within Dubai exists a unique special economic zone known as the{" "}
          <a href="https://www.dfsa.ae/">
            {" "}
            Dubai International Financial Center (DIFC).
          </a>{" "}
          The primary regulator for the financial services industry within the
          DIFC is the{" "}
          <a href="https://www.fca.org.uk/">
            {" "}
            Dubai Financial Services Authority (DFSA),
          </a>{" "}
          which enforces licensing and membership compliance for forex brokers
          in Dubai, and regulates the trading of securities, commodities,
          investment funds, and derivatives.
        </p>
        <h1 className=" fw-bold pt-2 py-3 text-center container">
          How do you get started trading forex in the United Arab Emirates?
        </h1>
        <p className="text-justify container">
          If you want to start trading forex in the United Arab Emirates, it’s
          important to start by choosing a forex broker that is well-regulated
          and properly licensed in the UAE by the DFSA or SCA. While this list
          isn’t meant to be exhaustive (not every trader’s needs are the same),
          here are five general steps you can take to start on your forex
          trading journey as a resident of the UAE.
        </p>

        <div className="container">
          <ol>
            <li>
              {" "}
              <b>Choose a regulated forex broker </b>Make sure that your broker
              is regulated with either the SCA or DFSA – depending on whether
              you live within the DIFC or outside of Dubai. To help reduce your
              risk of falling victim to a forex scam, you should always make
              sure your broker is well-regulated, well-capitalized, and
              financially secure.
            </li>
            <li>
              {" "}
              <b>Use free demo account before trading with real money</b> Make
              sure to learn how your broker’s trading software works before
              placing real trades in your live account. A free demo account is a
              great way to experiment with managing open orders and placing
              trades – without risking real money.
            </li>
            <li>
              {" "}
              <b>
                Develop a trading plan that aligns with your goals and
                lifestyle.{" "}
              </b>{" "}
              How much do you want to make, and what amount can you risk per
              trade (or per day, or per week)? What is the ideal trade size and
              trade duration relative to your balance? These are just a few
              questions that traders must answer when designing their trading
              plans.
            </li>
            <li>
              {" "}
              <b>Learn to identify trading opportunities. </b>Whether you are
              looking at charts and conducting technical analysis, consuming
              market research or fundamental analysis, or using trading signals,
              it’s important to learn how to identify the right trading
              opportunities for your strategy.
            </li>
            <li>
              {" "}
              <b>Test your strategy with real money. </b> Start with an amount
              of capital that you can afford to lose and test your strategy on a
              small scale before scaling it to larger amounts of capital.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}

export default CenteroneUae;
