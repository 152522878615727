/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import active from '../assets/img/ActivTrades logo-review.png';
import { TiTick } from "react-icons/ti";
import { LuExternalLink } from "react-icons/lu";

const Activetrades = () => {
    useEffect(()=>{
        window.scrollTo(0,0);
          },[]);
    const minRating = 0.1;
    const maxRating = 5.0;
    return (
        <>
            <Header />
            <div className="container py-4 px-4">
                <div className='col-lg-10 col-md-9 offset-md-1'>
                    <h3 className='pt-4 pb-4  broker-name'>ActivTrades Review</h3>
                    <div className="row  pt-5 pb-5 text-center">
                        <div

                            className="col-lg-6 col-md-8 col-sm-12 offset-md-3  pricing-block style-two animated fadeInUp "
                            data-wow-delay="200ms"
                            data-wow-duration="1200ms"
                        >
                            <div
                                className="inner-box1 px-4 py-4 mobile-review"
                         
                                
                            >

                                <h4 >
                                    <a href='' target="_blank">
                                        <img
                                            src={active}
                                            width={"200"}
                                            height={"77"}
                                            alt=""
                                            srcset=""
                                            className="mb-3 rounded-2"
                                            style={{
                                                boxShadow: "5px 5px 10px rgba(0,0,0,.23)"
                                              }}
                                        />
                                    </a>
                                </h4>
                                <div className="d-flex py-1  justify-content-center  align-items-center  flex-reverse ">

                                    <div className=" d-flex justify-content-center">
                                        <div class="ratings">
                                            <div class="empty-stars"></div>
                                            <div
                                                className="full-stars"
                                                style={{
                                                    width: `${((5 - minRating) /
                                                            (maxRating - minRating)) *
                                                            95.9184
                                                        }%`,
                                                }}
                                            ></div>
                                        </div>
                                        &nbsp;&nbsp;
                                        <ul className="list-unstyled ptusa"><li> 4.8</li></ul>
                                    </div>
                                </div>

                                <div>
                                    <div
                                        className={`py-0`}>
                                        <div className="grid grid-cols-12 text-sm">
                                            <div className="text-left lh-lg">
                                              <ul className='list-unstyled'>
                                              <li>
                                                    <TiTick />
                                                    <strong> Min. Deposit: </strong> $0
                                                </li>

                                                <li className='fs-6'> <TiTick /><strong>Max. Leverage: </strong> 1:200</li>
                                                <li className='fs-6'> <TiTick /><strong>Headquarter: </strong> London, UK</li>
                                                <li className='fs-6'> <TiTick /><strong>Products (CFD):</strong> Forex, Indices, Shares, Commodities, Cryptocurrencies, Bonds, ETFs </li>
                                              </ul>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="py-1  my-1 btn-style-two1">
                                    <a href='' target="_blank" className=" w-100 text-align-center text-light">

                                        <span className="">Visit ActivTrades {' '}</span>
                                        <LuExternalLink color='#fff' size='25' style={{ paddingBottom: '5px' }} />
                                    </a>
                                </div>

                                <div className="hint">

                                    <p className="tradepara1 py-2">
                                        82% of retail CFD accounts lose money
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="row lg-pt-5 lg-px-5">
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>Founded in Switzerland, ActivTrades has been around since 2001, which means it has more than two decades of experience in the fintech industry. The independent brokerage house started as a small firm, but it is now a global retail and institutional broker. In 2005, ActivTrades moved its HQ to London, and it began growing rapidly through Europe.</p>
                        </div>
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>ActivTrades offers a lot of benefits to its clients, including educational tools, rewards programs, competitive spreads, and the use of MetaTrader 4 and MetaTrader 5, two industry-leading platforms. Here are some of the awards that this broker has won for its high quality:</p>
                        </div>

                        <div className="col-lg-12">
                        <ul className='lh-lg' >
                            <li> ADVFN International Financial Awards: “Best Online Trading Services”</li>
                            <li> Le Fonti: “Best Forex Broker”</li>
                            <li> Agence IAT Grand Prize Excellence: “Best Execution Broker”</li>
                            <li> Agence IAT Grand Prize Excellence: “Best Customer Support”</li>
                            <li> MENA Forex Show: “Most Trusted Forex Broker”</li>
                            <li> DKI & Euro am Sonntag: “Best Customer Service”</li>
                        </ul>
                        </div>

                        <div className="col-lg-12 py-5">
                            <div className='row'>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <h5 className='pb-1 font-bold'>Pros</h5>
                                    <ul className='lh-lg' style={{ listStyle: "none" }}>
                                        <li>✅ Low trading fees </li>
                                        <li>✅ Excellent account opening</li>
                                        <li>✅ Free and fast deposit and withdrawal</li>
                                    </ul>


                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <h5 className='pb-1 font-bold'>Cons</h5>
                                    <ul className='lh-lg' style={{ listStyle: "none" }}>
                                        <li>❌Conversion fee</li>
                                        <li>❌Poor customer support</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <h2 className='pt-4 pb-4 font-bold'>Costs</h2>
                        <div className="col-lg-12">
                            <h2 className="mt-4 mb-4">Account Minimum</h2>
                            <p className='lh-base fs-6'>ActivTrades welcomes traders with a minimum deposit requirement of $0, offering accessibility to a wide array of market participants.</p>
                        </div>
                        <div className="col-lg-12">
                            <h2 className="mt-4 mb-4">Trading Fees</h2>
                            <p className='lh-base fs-6'>Utilizing a spread-only model for certain accounts, ActivTrades allows traders to engage in forex and CFD trading without being burdened by additional trading fees.</p>
                        </div>
                        <div className="col-lg-12">
                            <h2 className="mt-4 mb-4">CFD Fees</h2>
                            <p className='lh-base fs-6'>CFD trading fees are embedded within the spread, facilitating a transparent and straightforward pricing structure.</p>
                        </div>
                        <div className="col-lg-12">
                            <h2 className="mt-4 mb-4">Forex Fees</h2>
                            <p className='lh-base fs-6'>Similar to CFDs, forex trading is executed on a spread basis, eliminating the necessity for separate commission fees.</p>
                        </div>
                        <div className="col-lg-12">
                            <h2 className="mt-4 mb-4">Account Fee</h2>
                            <p className='lh-base fs-6'>ActivTrades does not charge an account maintenance fee, ensuring that traders can manage their portfolios without unnecessary costs.</p>
                        </div>
                        <div className="col-lg-12">
                            <h2 className="mt-4 mb-4">Inactivity Fee</h2>
                            <p className='lh-base fs-6'>An inactivity fee is levied after an account has remained dormant for a stipulated period, encouraging active trading or account closure.</p>
                        </div>
                        <div className="col-lg-12">
                            <h2 className="mt-4 mb-4">Deposit Fee</h2>
                            <p className='lh-base fs-6'>A commitment to affordability is demonstrated through the absence of deposit fees.</p>
                        </div>
                        <div className="col-lg-12">
                            <h2 className="mt-4 mb-4">Withdrawal Fee</h2>
                            <p className='lh-base fs-6'>ActivTrades does not impose withdrawal fees, ensuring traders can access their funds without additional charges.</p>
                        </div>
                        <div className="col-lg-12">
                            <h2 className="mt-4 mb-4">Overnight Funding Fee</h2>
                            <p className='lh-base fs-6'>Information regarding overnight funding fees and guaranteed stop order fees may be subjected to specific account types and should be confirmed directly with ActivTrades</p>
                        </div>
                        <div className="col-lg-12">
                            <h2 className="mt-4 mb-4">Currency Conversion Fee</h2>
                            <p className='lh-base fs-6'>Specifics pertaining to currency conversion fees should be verified directly due to variations depending on account type and trading activity.</p>
                        </div>

                        <h2 className='pt-4 pb-4 font-bold'>Trading Platforms</h2>
                        <div className="col-lg-12">
                            <h2 className='mt-4 mb-4'>Comprehensive and Adaptive</h2>
                            <p className='lh-base fs-6'>ActivTrades caters to a wide range of traders by providing access to several popular trading platforms, including MetaTrader 4 (MT4), MetaTrader 5 (MT5), and TradingView. The platforms are renowned for their advanced charting tools, supportive of algorithmic (algo) trading, and accommodate both ease of use and flexibility in trading strategies, which is integral for both new and experienced traders alike. Additionally, ActivTrades offers its proprietary platform ActivTrader, further enhancing its diverse range of trading options for clients.</p>
                        </div>
                        <div className="col-lg-12">
                            <h2 className="mt-4 mb-4">Mobile Trading Apps
                            </h2>
                            <p className='lh-base fs-6'>WebTrader has a simple and easy-to-use interface that lets you create watchlists, analyze charts, as well as place and monitor trades. The technical analysis charts offer many technical indicators that you can apply to many different time frames, from tick charts to weekly charts. However, unlike many of its competitors, Plus500 does not offer the immensely popular MetaTrader 4 (MT4) platform, a platform alternative that would provide more functionality and customization options for clients.</p>
                        </div>
                        <div className="col-lg-12">
                            <h2 className="mt-4 mb-4">Web Trading Apps
                            </h2>
                            <p className='lh-base fs-6'>In an era of web-based solutions, ActivTrades allows traders to utilize their platform without the need for downloads, accessible directly via web browsers, ensuring a convenient and efficient trading experience.</p>
                        </div>
                        <div className="col-lg-12">
                            <h2 className="mt-4 mb-4">Deskstop Trading Apps
                            </h2>
                            <p className='lh-base fs-6'>Adapting to varied user preferences, ActivTrades provides its platforms in a format suitable for both Windows and Mac desktop users, offering a stable and comprehensive trading environment.</p>
                        </div>

                        <h2 className='pt-4 pb-4 font-bold'>Markets and products</h2>
                        <div className="col-lg-12"><p>ActivTrades is equipped to serve a wide audience of traders with its varied tradable instruments, aligning with both traditional and current market trends.</p>
                            <p className='lh-base fs-6'>The brokerage is prominently known for its forex trading capabilities, with a vast array of currency pairs available for trading, from major to exotic. This positions ActivTrades as a preferred destination for forex aficionados. In the realm of commodities, traders can capitalize on various products, including metals and energies, to diversify their portfolios.</p>
                            <p className='lh-base fs-6'>Equally enticing is ActivTrades’ exposure to indices, allowing traders to invest in collective market movements. For those with an eye on the future, the broker does provide opportunities to delve into cryptocurrency trading, embracing the digital currency evolution.</p>
                            <p className='lh-base fs-6'>Activtrades provides access to 1000+ CFDs including:</p>
                            <ul className='lh-lg'>
                                <li>Forex CFD – 49</li>
                                <li>Stock index CFD – 27</li>
                                <li>Stock CFD – 660</li>
                                <li>ETFs CFD – 500</li>
                                <li>Commodity CFD – 22</li>
                                <li>Bond CFD – 7</li>
                                <li>Crypto CFD – 16</li>
                            </ul>
                        </div>

                        <h2 className='pt-4 pb-4 font-bold'>Safety</h2>
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>ActivTrades is regulated by several financial authorities including the top-tier FCA, but it is not listed on any stock exchange and its financial information is not publicly available.</p>
                        </div>
                        <div className="col-lg-12">
                            <h2> ActivTrades regulation</h2>
                            <ul className='lh-lg'>

                                <li>ActivTrades Corp is authorised and regulated by The Securities Commission of the Bahamas. ActivTrades Corp is an international business company registered in the Commonwealth of the Bahamas, registration number 199667 B.</li>
                                <li>ActivTrades Corp is a subsidiary of ActivTrades PLC, authorised and regulated by the Financial Conduct Authority, registration number 434413. ActivTrades PLC is a company registered in England & Wales, registration number 05367727. ActivTrades PLC is responsible for card payment processing.</li>
                                <li>ActivMarkets is a Public Limited Company, authorised and regulated in Portugal by the Comissão do Mercado de Valores Mobiliários (CMVM) with the number 433, is a company registered in Portugal with the number 516 929 291. ActivMarkets is a subsidiary of ActivTrades PLC. and operates under the name ActivTrades.</li>

                            </ul>
                        </div>

                        <h2 className='pt-4 pb-4 font-bold'>Customer Support
                        </h2>
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>ActivTrades offers several customer service channels. The phone suport is slow, and we also missed 24/7 availability</p>
                            <p className='lh-base fs-6'>You can contact ActivTrades via:</p>
                            <ul>
                                <li>Live chat</li>
                                <li>Phone </li>
                                <li>Email</li>
                            </ul>

                        </div>

                        <h2 className='pt-4 pb-4 font-bold'>Final Thoughts</h2>

                        <p className='lh-base fs-6'>All in all, ActivTrades is a good CFD/forex broker.
                            ActivTrades has low trading fees. The account opening is excellent and the deposits and withdrawals are free.
                            <br />
                            On the downside, its product portfolio is limited to just a few products, as it offers mainly CFDs and forex instruments. Research tools are basic, and there are no trading ideas or fundamental data. There is an inactivity fee as well as a charge for currency conversion. Customer service also leaves a lot to be desired.
                            <br />
                            As trading fees are low and your account will be ready quickly, we encourage you to try ActivTrades.
                            </p>

                   
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Activetrades 