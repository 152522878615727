import React from 'react'

function CenterUk() {
  return (
    <section className="">
    <div className="py-5  ">
      <div className="col-lg-10 col-md-9 offset-md-1">
      <div className="container" style={{paddingLeft:"80px"}}>
        {/* <div className="d-flex align-items-center">
                  <p className="feeCalculator-new px-2 mx-2 fw-bold">NEW</p>
                </div> */}

       <h1
            className="feeCalculator-heading fw-bold pt-2 py-3"
                
            style={{ color: "black",marginLeft:"30px" }}
          >
Best UK Forex Brokers of 2024
          </h1> 
        </div>
        <div className="container" style={{paddingLeft:"80px"}}>
          {/* <p className="p-0 m-0">Calculate your FX trading fees.</p> */}
          <ul className=" lh-lg">
            <li className="mt-3 " style={{listStyle:"none"}}>
            Trading forex (currencies) in the United Kingdom (UK) is popular among residents. Before any fx broker can accept UK forex and CFD traders as clients, they must become authorised by the Financial Conduct Authority (FCA), which is the financial regulatory body in the UK. The FCA’s website is <a href='FCA.org.uk'> FCA.org.uk.</a> We recommend UK residents also follow the FCA on twitter, <a href='https://twitter.com/TheFCA'>@TheFCA.</a> 
            </li>
            <li className="mt-3" style={{listStyle:"none"}}>
            The FCA was formed out of the Financial Services Act of 2012, effectively replacing its predecessor, the Financial Services Authority (FSA). For a historical breakdown, here’s a link to Financial Conduct Authority webpage on Wikipedia.


            </li>
            {/* <li className="mt-3">
              BaFin was established in 2002 when three supervisory agencies
              merged to create one agency to oversee banks, credit
              institutions, insurance companies, financial service
              companies, brokers and stock exchanges. For a historical
              breakdown, here’s a link to the Federal Financial Supervisory
              Authority webpage on  <a href="https://en.wikipedia.org/wiki/Federal_Financial_Supervisory_Authority">
                Wikipedia.
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  </section>
  )
}

export default CenterUk