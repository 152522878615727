
import React from "react";
import Header from "../components/Header";
// import Center from "../components/Center";
// import Centerone from "../components/Center_one";
import Footer from "../components/Footer";
// import {CenterGermnay} from "../components/CenterGermnay";
// import CenteroneGermany from "../components/CenteroneGermany";
import CenteroneSignapore from "../components/CenteroneSignapore";
import CenterSingapore from "../components/CenterSingapore";

function Singapore() {
  return (
    <>
      <Header />
      <CenterSingapore />
      <CenteroneSignapore
       

      />
      <Footer />
    </>
  );
}

export default Singapore;
