/* eslint-disable */
import React, { useEffect, useState } from "react";
import { LuExternalLink } from "react-icons/lu";
import plus500 from "./../assets/img/Plus500.svg";
import IG from "./../assets/img/IG.png";
import Pepperstone from "./../assets/img/Pepperstone.png";
import City from "./../assets/img/City Index.png";
import { Link } from "react-router-dom";
import Instance from "../utils/Interceptor";

function CenteroneGermany() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchBrokerData = async () => {
    // setLoading(true);
    try {
      setLoading(true);
      const response = await Instance.get("api/germany-broker-point");
      // const Fee = response.data.data.slice(0, 41);
      setData(response?.data?.data);
      if (response.data.status === true) {
        setLoading(false);
        window.scrollTo(0, 0);
      } else {
        setLoading(true);
      }
    } catch (error) {
      console.error("Error fetching broker data:", error);
      setLoading(true);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchBrokerData();
    // scrollTo(0,0)
  }, []);
  return (
    <div className="">
      <h1 className=" fw-bold pt-2 py-3 text-center">
        Best Forex Brokers For Germany
      </h1>
      <div className="container" style={{ paddingLeft: "100px" }}>
        <p className="text-justify container">
          To find the best forex brokers in Germany, we created a list of all
          brokers that list Germany as a country they accept new customers from.
          We then ranked brokers by their Overall ranking.
        </p>
      </div>
      <div className="container" style={{ paddingLeft: "100px" }}>
        <p className="text-justify container">
          Here is our list of the best forex brokers for Germany:
        </p>
      </div>
      {data?.map((e, i) => {
        return (
          <div className="d-flex flex-col justify-content-center cus-con">
            <div className="d-flex align-items-center justify-content-between calculate-broker  my-3 row col-lg-12 ">
              <div className="col-lg-2 col-md-12 col-sm-12">
                <div className="broker-profile d-flex align-items-start justify-content-start p-5 resFee">
                  <img
                    className=""
                    src={e?.image}
                    alt=""
                    width="77"
                    height="77"
                    style={{
                      borderRadius: "10px",
                      cursor: "pointer",
                      boxShadow: "5px 5px 10px rgba(0,0,0,.23)",
                    }}
                  />
                  <div className="px-3 m">
                    <h6 className="broker-profile-name-1 center fw-bold fs-6">
                      {""}
                    </h6>
                    <h6 className="broker-profile-name-2 center fw-bold fs-6 para-color type">
                      {""}
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-12 ">
                <div className="broker-spread text-start new">
                  <p className="para-color p-0 m-2 bottum">General Info</p>
                  {e?.levels.slice(0, 4)?.map((e, i) => {
                    return <li>{e?.point}</li>;
                  })}
                  {/* <li>Min Deposit: $100</li>
          <li>Max Leverage: 30:1</li>
          <li>Platforms: Plus500 app, Webtrader</li>
          <li>Inactivity Fee: Yes</li> */}
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-12">
                <div className="broker-comission text-start new1">
                  <p className="para-color p-0 m-2 bottum1">Average Spread</p>
                  {e?.levels.slice(4, 8)?.map((e, i) => {
                    return <li>{e?.point}</li>;
                  })}
                  {/* <li>EURUSD: 1.3</li>
          <li>USDJPY: 1.7</li>
          <li>GBPUSD: 2.0</li>
          <li>GOLD: 0.3</li> */}
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-center">
                <div className="broker-percentage text-center p-2">
                  <div className="link-btn pb-3">
                    <div className="theme-btn btn-style-two">
                      <span
                        className="btn-title"
                        onClick={() => window.open(e?.url, "_blank")}
                        style={{
                          padding: "3px 7px",
                          cursor: "pointer",
                        }}
                      >
                        VISIT BROKER{" "}
                        <LuExternalLink
                          size="22"
                          style={{ paddingBottom: "5px" }}
                        />{" "}
                      </span>
                    </div>
                  </div>
                  <div className="hint ">
                    <Link to={e?.path} className="forhover ">
                      {" "}
                      <span style={{ borderBottom: "1px solid #0a2cd4" }}>
                        Read review
                      </span>{" "}
                    </Link>
                    {/* <p className="tradepara py-1 mt-2 mb-0">
              {"79"}% of retail CFD accounts lose money
            </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
          <div className="container" style={{paddingLeft:"100px"}}>

      <h1 className=" fw-bold pt-2 py-3 text-center">
        How to Verify BaFin Authorisation
      </h1>
      <p className="text-justify container">
        To identify if a forex broker is authorised by BaFin, the first step is
        to identify the BaFin registration number from the disclosure text at
        the bottom of the broker’s homepage. For example, here’s the key
        disclosure text from IG’s website,
      </p>
      <p className="text-justify container">
        IG Markets Ltd. is authorized and regulated by the Financial Conduct
        Authority (FCA Registry Number 195355). The IG Europe GmbH is supervised
        by the Federal Financial Supervisory Authority (BaFin registration
        number 148759) and the German Central Bank. IG is a brand name of IG
        Markets Ltd.
      </p>
      <p className="text-justify container">
        Next, look up the registration number on the{" "}
        <a href="https://portal.mvp.bafin.de/database/InstInfo/">
          {" "}
          financial register{" "}
        </a>{" "}
        available on BaFin’s website to validate that the broker is currently
        authorized in Germany
      </p>
      </div>
    </div>
  );
}

export default CenteroneGermany;
