import React from "react";
import Header from "../components/Header";
// import Center from "../components/Center";
// import Centerone from "../components/Center_one";
import Footer from "../components/Footer";
import {CenterGermnay} from "../components/CenterGermnay";
import CenteroneGermany from "../components/CenteroneGermany";

function Germany() {
  return (
    <>
      <Header />
      <CenterGermnay />
      <CenteroneGermany
       

      />
      <Footer />
    </>
  );
}

export default Germany;
